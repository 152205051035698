import { useState } from 'react';

import Header from '../../../../../../components/page/Header/Component';
import Footer from '../../../../../../components/page/Footer/Component';
import Title from '../../../../../../components/page/Title/Component';

import Material from '../../../../../../components/Material/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../../lib/network';
import { INVALID_DATA } from '../../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../../lib/rest';
import { CircularProgress, TextField } from '@mui/material';

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { buildOutputLinkByQualifiedName } from '../../../../../../lib/util';
import createOutput, { OUTPUT_ALREADYCREATED, OUTPUT_CREATED } from '../../../../../../lib/api/mrp/output/api';
import isOutputQuantityValid from '../../../../../../lib/api/mrp/output/validation';
import { FRONTPAGE, REDIRECT_PROFILE } from '../../../../../../App';

const OUTPUT_FORM = 'OUTPUT_FORM';

const Component = () => {

    let { businessQualifiedName, materialName, materialToken } = useParams();

    const [dueDate, setDuedate] = useState(new Date());
    const [quantity, setQuantity] = useState('');
    const [output, setOutput] = useState(null);
    const [createdToken, setCreatedToken] = useState(null);

    const [op, setOp] = useState(null);

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isOutputQuantityValid(quantity)

            )

        ) {

            setOp(INVALID_DATA);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await createOutput(

            quantity,
            dueDate.toISOString().substring(0, 10),
            materialToken

        );

        let localOutput = null;

        switch (response.status) {

            case 200:

                localOutput = response.body;

                setCreatedToken(

                    localOutput.token

                );

                setOp(REDIRECT_PROFILE);

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    if (

                        (m[i] === 'quantity')

                        || (m[i] === 'dueDate')

                    ) {

                        setOp(INVALID_DATA);

                    }

                    if (m[i] === OUTPUT_ALREADYCREATED) {

                        setOp(OUTPUT_ALREADYCREATED);

                    }

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const handleOutputQuantityChange = (e) => {

        setQuantity(e.target.value);

    }

    const handleDuedateChange = (

        pickedDate

    ) => {

        setDuedate(pickedDate);

    }

    const frontPage = (props) => {

        return (

            <div>

                <Header />

                <Title title="Cadastrar Saída" />

                <Material materialToken={materialToken} />

                <br />

                <div className="content">

                    <form onSubmit={handleSubmit}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Quantidade</th>
                                    <td><input type="text" value={quantity} onChange={handleOutputQuantityChange} /></td>
                                </tr>

                                <tr>
                                    <th className="verticalHeader">Data de Saída</th>
                                    <td>

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>

                                            <MobileDatePicker

                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) => <TextField {...params} />}
                                                onChange={handleDuedateChange}
                                                value={dueDate}

                                            />

                                        </LocalizationProvider>

                                    </td>
                                </tr>

                                <tr>

                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>

                                </tr>

                            </tbody>

                        </table>

                    </form>

                </div>

                <br />

                <Footer />

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(OUTPUT_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Informe a quantidade</p>
                    <p style={{ color: 'black' }}>Informe uma data igual ou maior que hoje</p>

                </Alert>

            </div>
        )

    }

    if (op === OUTPUT_ALREADYCREATED) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(OUTPUT_FORM) }}>

                    <p style={{ color: 'black' }}>Data inválida</p>
                    <p style={{ color: 'black' }}>Já existe uma saída para esse material nessa data</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_PROFILE) {

        return (

            <Navigate to={

                buildOutputLinkByQualifiedName(

                    businessQualifiedName +
                    '/' + materialName +
                    '/' + createdToken

                )

            } />
        )

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
