import accountsHost from '../../../../config/api';
import { post, get, rget } from '../../../rest';
import { buildBusinessLink, buildBusinessQualifiedName, buildMaterialLink } from '../../../util';

export const updatePersonName = (

    firstName,
    lastName,
    callBack

) => {

    post(

        accountsHost + '/person/updatePersonName',
        {},
        {

            firstName: firstName,
            lastName: lastName

        },
        callBack

    )
}

export const updateBusinessName = (

    name, // business name
    token, // business account token
    callBack

) => {

    post(

        accountsHost + '/business/updateBusinessName',
        {},
        {

            name: name,
            token: token

        },
        callBack

    )
}

const businessRegister = (

    businessName,
    accountEmail,
    callBack

) => {

    post(

        accountsHost + '/business/registerBusiness',
        {},
        {

            businessName: businessName,
            accountEmail: accountEmail,
            emailSubject: 'Confirme sua conta no ProdCalc',
            emailBody: 'Volte para o ProdCalc e use o código abaixo para confirmar sua conta.'

        },
        callBack

    )
}

export const businessProfileByQualifiedName = (

    businessQualifiedName,
    callBack

) => {

    get(

        accountsHost + '/business/businessProfileByQualifiedName' +
        '/' + businessQualifiedName,
        {},
        callBack

    )
}

export const businessProfileByAccountToken = (

    accountToken,
    callBack

) => {

    get(

        accountsHost + '/business/businessProfileByAccountToken' +
        '/' + accountToken,
        {},
        callBack

    )
}

export const businessSearch = (

    queryString,
    callBack

) => {

    get(

        accountsHost + '/business/businessSearch' + queryString,

        {},
        callBack

    )
}

export const businessDetailByAccountToken = (

    accountTokenList,
    callBack

) => {

    get(

        accountsHost + '/business/businessDetailByAccountToken' +

        '?accountTokenList=' + accountTokenList,

        {},
        callBack

    )
}

export const rbusinessDetailByAccountToken = async (

    accountTokenList,

) => {

    return await rget(

        accountsHost + '/business/businessDetailByAccountToken' +

        '?accountTokenList=' + accountTokenList,

        {}

    )
}

export const buildBusinessDetailFromMaterialList = async (

    materialList

) => {

    let accountTokenList = [];

    materialList.map(material => {

        accountTokenList[

            accountTokenList.length

        ] = material.accountToken;

    });

    let response = await rbusinessDetailByAccountToken(

        accountTokenList

    );

    if (response.status === 200) {

        let businessList = response.body;

        businessList.map(business => {

            business.businessQualifiedName = buildBusinessQualifiedName(business);

            business.link = buildBusinessLink(business);

            materialList.map(material => {

                if (business.account.token === material.accountToken) {

                    material.business = business;

                    material.link = buildMaterialLink(

                        business,
                        material

                    );

                }

            });

        })

        response.body = null;

    }

    return response;

}

export default businessRegister;