import { mrpHost } from '../../../../../config/api';
import { post, get } from '../../../../rest';

export const updateBatchCode = (

    batchCode,
    batchToken,
    callBack

) => {

    post(

        mrpHost + '/batch/updateBatchCode',
        {},
        {

            batchCode: batchCode,
            batchToken: batchToken

        },
        callBack

    )
}

const createMovement = (

    quantity,
    batchToken,
    callBack

) => {

    post(

        mrpHost + '/batch/movement/createMovement',
        {},
        {

            quantity: quantity,
            batchToken: batchToken

        },
        callBack

    )
}

export const batchProfileByBatchCode = (

    accountToken,
    materialToken,
    callBack

) => {

    get(

        mrpHost + '/material/materialProfileByToken' +
        '/' + accountToken +
        '/' + materialToken,
        {},
        callBack

    )
}

export const batchSearch = (

    queryString,
    callBack,

) => {

    get(

        mrpHost + '/batch/batchSearch' + queryString,

        {},
        callBack

    )
}

export const batchProfileByToken = (

    batchToken,
    callBack,

) => {

    get(

        mrpHost + '/batch/batchProfileByToken' +

        '/' + batchToken,

        {},
        callBack

    )
}

export default createMovement;