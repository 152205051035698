import React from 'react';

import Presentation from './Presentation/Component';
import EmailLogin from './EmailLogin/Component';
import PersonRegister from './PersonRegister/Component';
import BusinessRegister from './BusinessRegister/Component';
import ConfirmAccount from './ConfirmAccount/Component';
import Instructions from './Instructions/Component';

import Header from '../../../components/page/Header/Component';
import Footer from '../../../components/page/Footer/Component';

import { hasSessionId } from '../../../lib/session';
import { Navigate } from 'react-router-dom';

const Component = () => {

    if (hasSessionId()) {

        return <Navigate to="/pages/private/menu" />

    }


    return (

        <div>

            <Header />

            {/* <br />

            <div className="content" style={{ backgroundColor: "yellow" }}>

                <p style={{ color: "black" }}>Este é o servidor de teste do prodcalc.</p>

                <p style={{ color: "black" }}>Se você quiser o servidor de produção, ou seja, o servidor para valer, acesse:</p>

                <p style={{ color: "black" }}><a href="https://prodcalc.com" style={{ color: "blue" }}>https://prodcalc.com</a></p>

            </div> */}

            <br />

            <Presentation />

            <br />

            <div className="content">

                <iframe

                    style={{ width: "100%" }}

                    height="400"

                    src="https://www.youtube.com/embed/Ua95Hkoxczo"

                    title="YouTube video player"

                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"

                    allowfullscreen></iframe>

            </div>

            <br />

            <div className="content">

                <iframe

                    style={{ width: "100%" }}

                    height="400"

                    src="https://www.youtube.com/embed/FPU-nhinbGo"

                    title="YouTube video player"

                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"

                    allowfullscreen></iframe>

            </div>

            <br />

            <Instructions />

            <br />

            <PersonRegister />

            <br />

            <BusinessRegister />

            <br />

            <ConfirmAccount />

            <br />

            <EmailLogin />

            <br />

            <Footer />

        </div >

    );

}

export default Component;