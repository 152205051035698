import { buildBusinessQualifiedName, buildPersonQualifiedName } from "./util";

export const SESSION_ID = 'Phpsessionid';
export const SESSION_DATA = 'COMPRODCALC_SESSION_DATA';

export const HOME_LINK = 'pages/public/home'
export const MENU_LINK = 'pages/menu'

export const removeItem = (key) => {

    localStorage.removeItem(key);

}

export const setItem = (key, value) => {

    localStorage.setItem(key, value);

}

export const getItem = (key) => {

    return localStorage.getItem(key);

}

export const removeSessionId = () => {

    localStorage.removeItem(SESSION_ID);

}

export const removeSessionData = () => {

    localStorage.removeItem(SESSION_DATA);

}

export const setSessionId = (sessionId) => {

    localStorage.setItem(

        SESSION_ID,
        sessionId

    );

}

export const getSessionId = () => {

    let id = localStorage.getItem(SESSION_ID);

    return localStorage.getItem(SESSION_ID);

}

export const hasSessionId = () => {

    return (getSessionId() != null);

}

export const setSessionData = (json) => {

    localStorage.setItem(

        SESSION_DATA,

        JSON.stringify(json)

    );

}

export const getSessionData = () => {

    if (hasSessionId()) {

        return JSON.parse(

            localStorage.getItem(SESSION_DATA)

        );

    }

}

export const isPerson = () => {

    return (

        hasSessionId() && (getSessionData().person != null)

    );

}

export const isBusiness = () => {

    return (

        hasSessionId() && (getSessionData().business != null)

    );

}

export const getAccount = () => {

    return getSessionData()['account'];

}

export const getPerson = () => {

    return getSessionData()['person'];

}

export const getBusiness = () => {

    return getSessionData()['business'];

}

export const getPersonQualifiedName = () => {

    return buildPersonQualifiedName(getPerson());

}

export const getBusinessQualifiedName = () => {

    return buildBusinessQualifiedName(getBusiness());

}

export const isLoggedPerson = (personQualifiedName) => {

    return (personQualifiedName.toLowerCase() === getPersonQualifiedName());

}

export const isLoggedBusiness = (businessQualifiedName) => {

    return (businessQualifiedName.toLowerCase() === getBusinessQualifiedName());

}

export default getSessionId;