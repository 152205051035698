import { useEffect, useState } from 'react';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../lib/network';
import confirmEmail from '../../../lib/api/accounts/account/api';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { REDIRECT_HOME } from '../../../App';

const EMAIL_TOKENNOTFOUND = 'EMAIL_TOKENNOTFOUND';
const EMAIL_ALREADYCONFIRMED = 'EMAIL_ALREADYCONFIRMED';

const Controller = () => {

    const [op, setOp] = useState(null);

    let { emailToken } = useParams();

    useEffect(

        () => handleSubmit(emailToken),
        []
    );

    const handleSubmit = async (emailToken) => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        confirmEmail(

            emailToken,

            (status, body) => {

                if (status === 200) {

                    setOp(null);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === EMAIL_ALREADYCONFIRMED) {

                            setOp(EMAIL_ALREADYCONFIRMED);

                            break;

                        }

                        if (m[i] === EMAIL_TOKENNOTFOUND) {

                            setOp(EMAIL_TOKENNOTFOUND);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const frontPage = (props) => {

        return (

            <div className="content">

                <h3>Confirmação de Conta</h3>

                <Alert severity="success" onClose={() => { setOp(REDIRECT_HOME) }}>

                    <p style={{ color: 'black' }}>Email confirmado!</p>

                </Alert>

            </div >

        );

    }

    if (op === EMAIL_ALREADYCONFIRMED) {

        return (

            <div className="content">

                <h3>Confirmação de Conta</h3>

                <Alert severity="error" onClose={() => { setOp(REDIRECT_HOME) }}>

                    <p style={{ color: 'black' }}>Email já cadastrado</p>

                </Alert>

            </div >
        );

    }

    if (op === EMAIL_TOKENNOTFOUND) {

        return (

            <div className="content">

                <h3>Confirmação de Conta</h3>

                <Alert severity="error" onClose={() => { setOp(REDIRECT_HOME) }}>

                    <p style={{ color: 'black' }}>Link inválido</p>

                </Alert>

            </div>
        )

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Confirmação de conta</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Confirmação de conta</h3>

                <Alert severity="error" onClose={() => { setOp(REDIRECT_HOME) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Confirmação de conta</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to="/" />

        );

    }

    return frontPage();

}

export default Controller;
