import { useState } from 'react';

import Header from '../../../../../../components/page/Header/Component';
import Footer from '../../../../../../components/page/Footer/Component';
import Title from '../../../../../../components/page/Title/Component';

import Material from '../../../../../../components/Material/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../../lib/network';
import { INVALID_DATA } from '../../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../../../../App';

//import Viewer from '../../../../components/PageEditor/Main';

import { buildOutputLinkByQualifiedName } from '../../../../../../lib/util';
import formatDateTime, { formatDate } from '../../../../../../lib/format';
import { outputSearchByMaterialToken } from '../../../../../../lib/api/mrp/output/api';

const PERSONNAMEFORM = 'PERSONNAMEFORM';
const INVALID_PERSONNAME = 'INVALID_PERSONNAME';

const Component = () => {

    let { businessQualifiedName, materialName, materialToken } = useParams();

    const [list, setList] = useState([]);
    const [isQuery, setIsQuery] = useState(false);
    const [realized, setRealized] = useState('');

    const [op, setOp] = useState('');

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        let response = await outputSearchByMaterialToken(

            '?materialToken=' + materialToken +

            ((realized === '')

                ? ''

                : '&realized=' + realized)

        );

        switch (response.status) {

            case 200:

                let outputList = response.body;

                outputList.map((output) => {

                    output.link = buildOutputLinkByQualifiedName(

                        businessQualifiedName +
                        '/' + materialName +
                        '/' + output.token

                    );

                });

                setIsQuery(true);

                setList(outputList)

                setOp(FRONTPAGE);

                break;

            case 400:

                console.log(response.body);

            default: setOp(INTERNAL_ERROR);

        }

    }

    const handleRealizedChange = (e) => {

        setRealized(e.target.value);

    }

    const frontPage = () => {

        return (

            <div>

                <Header />

                <Title title="Procurar Saídas" />

                <Material materialToken={materialToken} />

                <br />

                <div className="content">

                    <form onSubmit={handleSubmit}>

                        <table>

                            <tbody>

                                {/* <tr>
                                    <th className="verticalHeader">Realizado(%)</th>
                                    <td>

                                        <input type="text" value={realized} onChange={handleRealizedChange} />

                                    </td>
                                </tr> */}

                                <tr>
                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Procurar" />

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </form>

                    {(list.length > 0) &&

                        <div>

                            <br />

                            <table>

                                <tbody>

                                    <tr>
                                        <th>Previsão</th>
                                        {/* <th style={{ borderRightColor: "black" }}>%</th> */}
                                        <th style={{ borderRightColor: "black" }}>Quantidade</th>
                                    </tr>

                                    {list.map((output, index) => {

                                        return (

                                            <tr key={index}>

                                                <td style={{ padding: "5px", textAlign: "center" }}>

                                                    <a href={output.link}>{formatDate(output.dueDate)}</a>

                                                </td>

                                                <td style={{ padding: "5px", textAlign: "center" }}>

                                                    {output.quantity}

                                                </td>

                                                {/* <td style={{ padding: "5px" }}>

                                                    {output.realized}

                                                </td> */}

                                            </tr >

                                        )

                                    })}

                                </tbody>

                            </table >

                        </div>

                    }

                    {(isQuery && list.length === 0) &&

                        <div>

                            <br />

                            Sem resultados.

                        </div>

                    }

                </div>

                <br />

                <Footer />

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Nomes e email</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
