import { useEffect, useState } from 'react';

import Header from '../../../../components/page/Header/Component';
import Footer from '../../../../components/page/Footer/Component';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../lib/network';
import isValidName, { INVALID_DATA, isValidEmail } from '../../../../lib/validation';
import { personProfileByQualifiedName, personSearch, updatePersonName } from '../../../../lib/api/accounts/person/api';
import { updateProfilePic, updateProfileText } from '../../../../lib/api/accounts/account/api';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import ConfigImages from '../../../../config/images';
import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_MENU } from '../../../../App';


import { getPersonQualifiedName, isLoggedUser, setSessionData } from '../../../../lib/session';
import profilePicValidate, { profileTextValidate } from '../../../../lib/api/accounts/account/validation';
import sessionDetail from '../../../../lib/api/accounts/session/api';
import { buildPersonQualifiedName, useQuery } from '../../../../lib/util';
import { permissionListByBusiness, updatePermission } from '../../../../lib/api/accounts/permission/api';
import ConfigText from '../../../../config/text';
import UpdatePermission from './UpdatePermission';
import formatDateTime from '../../../../lib/format';

const ACCOUNT_CREATED = 'ACCOUNT_CREATED';
const EMAIL_ALREADYREGISTERED = 'EMAIL_ALREADYREGISTERED';
const RESENDEMAIL_FORM = 'RESEND_FORM';
const INVALID_RESENDEMAIL = 'INVALID_RESENDEMAIL';
const EMAIL_ALREADYCONFIRMED = 'EMAIL_ALREADYCONFIRMED';
const RESENDEMAIL_MSG = 'RESENDEMAIL_MSG';

const PROFILEPICFORM = 'PROFILEPICFORM';
const INVALID_PROFILEPIC = 'INVALID_PROFILEPIC';

const PROFILETEXTFORM = 'PROFILETEXTFORM';
const INVALID_PROFILETEXT = 'INVALID_PROFILETEXT';

const PERSONNAMEFORM = 'PERSONNAMEFORM';
const INVALID_PERSONNAME = 'INVALID_PERSONNAME';
const PERMISSION_FORM = 'PERMISSION_FORM';

const PERMISSION_LIST = ['SUP', 'PUB'/*, 'MGR', 'OPE'*/, 'NON'];

const Component = () => {

    const [list, setList] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [permission, setPermission] = useState(null);
    const [permissionCode, setPermissionCode] = useState(null);

    const [op, setOp] = useState(null);

    let { businessQualifiedName } = useParams();

    const [currentBusinessQualifiedName, setCurrentBusinessQualifiedName] = useState(businessQualifiedName);

    useEffect(

        () => initData(),
        []
    );

    const initData = async () => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        permissionListByBusiness(

            currentBusinessQualifiedName,

            (status, body, headers) => {

                if (status === 200) {

                    let localList = [];

                    body.map(permission => {

                        permission.personQualifiedName = buildPersonQualifiedName(permission.person);

                        localList[localList.length] = permission;

                    });

                    setList(localList);

                    setOp(FRONTPAGE);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        /* if (m[i] === 'businessQualifiedName') {

                            setOp(BUSINESS_NOTFOUND);

                            break;

                        } */

                        console.log(m[1]);

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const initPersonProfileData = (permission) => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (permission.person.account.profileText == null) {

            permission.person.account.profileText = ConfigText.profileText;

        }

        permission.person.personQualifiedName = buildPersonQualifiedName(permission.person);

        setPermission(permission);

        setPermissionCode(permission.permission);

        setOp(PERMISSION_FORM);

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        updatePermission(

            permissionCode,
            permission.person.personQualifiedName,

            (status, body, headers) => {

                if (status === 200) {

                    initData();

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        /*  if (m[i] === EMAIL_TOKENNOTFOUND) {
    
                             setOp(EMAIL_TOKENNOTFOUND);
    
                             break;
    
                         } */

                        console.log([i]);

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleChange = (e) => {

        setPermissionCode(e.target.value);

        setOp(PERMISSION_FORM);

    }

    const permissionForm = (permission) => {

        setPermission(permission);

        setOp(PERMISSION_FORM);

    }

    const frontPage = (props) => {

        return (

            <div>

                <Header />

                <br />

                <div className="content">

                    {(list.length > 0) &&

                        <div>

                            <br />

                            <table>

                                <tbody>

                                    <tr>
                                        <th>Nome</th>
                                        <th>Permissão</th>
                                        <th style={{ borderRightColor: "black" }}>Criado</th>
                                    </tr>

                                    {list.map((permission, index) => {

                                        let p = permission;
                                        let p1 = p.person;

                                        return (

                                            <tr key={index}>

                                                <td>

                                                    <button className="buttonLink" type="button"
                                                        onClick={() => permissionForm(permission)}>{p1.firstName} {p1.lastName}</button>

                                                </td>

                                                <td style={{ textAlign: "center" }}>

                                                    {p.code}

                                                </td>

                                                <td style={{ textAlign: "center" }}>

                                                    {formatDateTime(p.created)}

                                                </td>

                                            </tr >

                                        )

                                    })}

                                </tbody>

                            </table >

                        </div>

                    }

                    {(list.length == 0) &&

                        <div>

                            <br />

                            Sem permissões

                        </div>

                    }

                </div>

                <br />

                <Footer />

            </div>

        );

    }

    if (op === INVALID_PERSONNAME) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(PERSONNAMEFORM) }}>

                    <p style={{ color: 'black' }}>Nome inválido</p>
                    <p style={{ color: 'black' }}>Use de 3 a 45 caracteres alfanuméricos</p>

                </Alert>

            </div>
        )

    }

    if (op === INVALID_PROFILETEXT) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(PROFILETEXTFORM) }}>

                    <p style={{ color: 'black' }}>Texto inválido</p>
                    <p style={{ color: 'black' }}>Use 10 caracteres no mínimo</p>

                </Alert>

            </div>
        )

    }

    if (op === INVALID_PROFILEPIC) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(PROFILEPICFORM) }}>

                    <p style={{ color: 'black' }}>Url inválida</p>
                    <p style={{ color: 'black' }}>Coloque uma url válida do google drive para uma imagem</p>

                </Alert>

            </div>
        )

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Nomes e email</p>

                </Alert>

            </div>
        )

    }

    if (op === ACCOUNT_CREATED) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="success" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Conta criada</p>
                    <p style={{ color: 'black' }}>Um email de confirmação foi enviado para você</p>

                </Alert>

            </div >
        )

    }

    if (op === EMAIL_ALREADYREGISTERED) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Email já cadastrado</p>

                </Alert>

            </div >
        )

    }

    if (op === INVALID_RESENDEMAIL) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Email</p>

                </Alert>

            </div>
        )

    }

    if (op === RESENDEMAIL_MSG) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="success" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email enviado</p>
                    <p style={{ color: 'black' }}>Use o link no email para confirmar sua conta.</p>

                </Alert>

            </div>
        )

    }

    if (op === EMAIL_ALREADYCONFIRMED) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email já confirmado</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === PERMISSION_FORM) {

        return <UpdatePermission businessQualifiedName={currentBusinessQualifiedName} permission={permission} />;

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

export default Component;
