import { mrpHost } from '../../../../config/api';
import { post, get, rget } from '../../../rest';

export const MATERIAL_ALREADYCREATED = 'MATERIAL_ALREADYCREATED';
export const MATERIAL_CREATED = 'MATERIAL_CREATED';
export const MATERIAL_NOTFOUND = 'MATERIAL_NOTFOUND';
export const MATERIAL_INVALIDNAME = 'MATERIAL_INVALIDNAME';

export const updateMaterialProfileText = (

    profileText,
    materialToken,
    callBack

) => {

    post(

        mrpHost + '/material/updateMaterialProfileText',
        {},
        {

            profileText: profileText,
            materialToken: materialToken

        },
        callBack

    )
}

export const updateMaterialName = (

    materialName,
    materialToken,
    callBack

) => {

    post(

        mrpHost + '/material/updateMaterialName',
        {},
        {

            materialName: materialName,
            materialToken: materialToken

        },
        callBack

    )
}

export const updateMaterialYield = (

    materialYield,
    materialToken,
    callBack

) => {

    post(

        mrpHost + '/material/updateMaterialYield',
        {},
        {

            materialName: materialYield,
            materialToken: materialToken

        },
        callBack

    )
}

const createMaterial = (

    materialName,
    produced,
    accountToken,
    callBack

) => {

    post(

        mrpHost + '/material/createMaterial',
        {},
        {

            materialName: materialName,
            produced: produced,
            accountToken: accountToken

        },
        callBack

    )
}

export const materialProfileByToken = (

    materialToken,
    callBack

) => {

    get(

        mrpHost + '/material/materialProfileByToken' +
        '/' + materialToken,
        {},
        callBack

    )

}

export const rMaterialProfileByToken = async (

    materialToken

) => {

    return await rget(

        mrpHost + '/material/materialProfileByToken' +
        '/' + materialToken,
        {}

    );
}

export const materialProfileByQualifiedName = (

    materialQualifiedName,
    callBack

) => {

    get(

        mrpHost + '/material/materialProfileByQualifiedName' +
        '/' + materialQualifiedName,
        {},
        callBack

    )
}

/**@deprecated */
export const materialSearch = (

    queryString,
    callBack,

) => {

    get(

        mrpHost + '/material/materialSearch' + queryString,

        {},
        callBack

    )
}

export const rMaterialSearch = async (

    queryString

) => {

    return await rget(

        mrpHost + '/material/materialSearch' + queryString,
        {}

    );
}

export default createMaterial;