import { useState } from 'react';

import ConfigImages from '../../../config/images';
import { hasSessionId } from '../../../lib/session';

const Component = (props) => {

    const frontPage = () => {

        return (

            <div className="content">

                <div style={{ display: "block", backgroundColor: "var(--panel-bg-color)", padding: "5px", bordeRadius: "8px" }}>

                    <span>

                        <a href="/">

                            <img src={ConfigImages.headerLogo} style={{ height: "30%", width: "30%" }} alt="ProdCalc" />

                        </a>

                    </span>

                    <span style={{ float: "right" }}>

                        {(hasSessionId() && props.showLink) && <a href="/pages/private/menu">Menu</a>}

                    </span>

                </div>

            </div>

        );

    }

    return frontPage();

}

Component.defaultProps = {

    showLink: true

}

export default Component;
