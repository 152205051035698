import { useState } from 'react';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../lib/network';
import { INVALID_DATA, isValidEmail } from '../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { sendLoginEmail } from '../../../../lib/api/accounts/session/api';

const EMAIL_SENT = 'EMAIL_SENT';
const EMAIL_NOTCONFIRMED = 'EMAIL_NOTCONFIRMED';
const EMAIL_NOTREGISTERED = 'EMAIL_NOTREGISTERED';

const Component = () => {

    const [accountEmail, setAccountEmail] = useState('');
    const [op, setOp] = useState(null);

    const handleChange = (e) => {

        if (e.target.name === 'accountEmail') {

            setAccountEmail(e.target.value);

        }

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isValidEmail(accountEmail)

            )

        ) {

            setOp(INVALID_DATA);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        sendLoginEmail(

            accountEmail,

            (status, body) => {

                if (status === 200) {

                    setOp(EMAIL_SENT);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === EMAIL_NOTREGISTERED) {

                            setOp(EMAIL_NOTREGISTERED);

                            break;

                        }

                        if (m[i] === EMAIL_NOTCONFIRMED) {

                            setOp(EMAIL_NOTCONFIRMED);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const frontPage = (props) => {

        return (

            <div className="content">

                <h3>Já tem uma conta?</h3>

                <p>Informe um email confirmado para se autenticar</p>

                <form onSubmit={handleSubmit}>

                    <table>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Email</th>
                                <td><input type="email" name="accountEmail" value={accountEmail} onChange={handleChange} /></td>
                            </tr>

                            <tr>
                                <td colSpan="2" style={{ textAlign: "right" }}>

                                    <input type="submit" value="Enviar" />

                                </td>
                            </tr>

                        </tbody>

                    </table>

                </form>

            </div>

        );

    }

    if (op === EMAIL_SENT) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="success" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Email enviado</p>
                    <p style={{ color: 'black' }}>Um email de login foi enviado para você</p>

                </Alert>

            </div >
        )

    }

    if (op === EMAIL_NOTCONFIRMED) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Email não confirmado</p>

                </Alert>

            </div>
        )

    }

    if (op === EMAIL_NOTREGISTERED) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Email não cadastrado</p>

                </Alert>

            </div>
        )

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Email</p>

                </Alert>

            </div>
        )

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
