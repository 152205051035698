import { useEffect, useState } from 'react';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../lib/network';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../App';

import { businessProfileByAccountToken } from '../../../lib/api/accounts/business/api';
import { buildBusinessLink, buildBusinessQualifiedName } from '../../../lib/util';

const Component = (props) => {

    const [business, setBusiness] = useState(null);

    const [op, setOp] = useState(null);

    const [currentAccountToken, setCurrentAccountToken] = useState(props.accountToken);

    useEffect(

        () => initData(),
        []
    );

    const initData = async () => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        businessProfileByAccountToken(

            currentAccountToken,

            (status, body, headers) => {

                if (status === 200) {

                    body.currentBusinessQualifiedName = buildBusinessQualifiedName(

                        body

                    );

                    body.link =

                        buildBusinessLink(

                            body

                        );

                    setBusiness(body);

                    props.setBusiness(body);

                    setOp(FRONTPAGE);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        /* if (m[i] === 'currentBusinessQualifiedName') {

                            setOp(PERSON_NOTFOUND);

                            break;

                        } */

                        console.log(m[i]);

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const frontPage = () => {

        return (

            <div>

                <div className="content">

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Empresa</th>

                                <td><a href={business.link}>{business.businessName}</a></td>

                            </tr>

                            {/*  <tr>

                                <th className="verticalHeader">Criado</th>

                                <td>

                                    <IntlProvider
                                        messages={{
                                            myMessage: "{ts, date, ::dd/MM/yyyy H:m:s}",
                                        }}
                                        locale="pt">

                                        <FormattedMessage
                                            id="myMessage"
                                            values={{ ts: Date.parse(business.account.created) }}
                                        />

                                    </IntlProvider>

                                </td>
                            </tr>

                            <tr>
                                <th className="verticalHeader">Link</th>
                                <td><input type="text" defaultValue={business.link} style={{ width: "95%" }} /></td>
                            </tr> */}

                        </tbody>

                    </table>

                </div >

            </div>

        );

    }

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to="/" />

        );

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

Component.defaultProps = {

    setBusiness: (business) => { }

}

export default Component;
