import { useEffect, useState } from 'react';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../../lib/network';
import { INVALID_DATA } from '../../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

import { Navigate } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../../../../App';

//import Viewer from '../../../../components/PageEditor/Main';

import cloneObject from '../../../../../../lib/util';
import { formatDate } from '../../../../../../lib/format';
import { inventorySearchByToken } from '../../../../../../lib/api/mrp/input/api';
import { usedInventorySearchByToken } from '../../../../../../lib/api/mrp/order/api';

const Component = (

    props // ordertoken

) => {

    const [list, setList] = useState([]);

    const [op, setOp] = useState('');

    const [batchList, setBatchList] = useState([]);

    useEffect(

        () => initData(),

        []
    );

    const initData = async () => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await usedInventorySearchByToken(

            '?token=' + props.token // order token

        );

        let localList = null;

        switch (response.status) {

            case 200:

                localList = response.body;

                setList(localList); // inventory list

                setOp(FRONTPAGE);

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

    }

    const frontPage = () => {

        return (

            <div className="content">

                <p>

                    Legenda: O = Saída, I = Entrada

                </p>

                <table>

                    <tbody>

                        <tr>
                            <th>Type</th>
                            <th>Material</th>
                            <th>Quantity</th>
                            <th style={{ borderRightColor: "black" }}>Token</th>
                        </tr>

                        {
                            list.map((

                                e, // inventory

                                index) => {

                                return (

                                    <tr key={index}>

                                        <td style={{ textAlign: "center" }}>

                                            {e.type}

                                        </td>

                                        <td>

                                            {e.material.name}

                                        </td>

                                        <td style={{ textAlign: "right" }}>

                                            {e.quantity}

                                        </td>

                                        <td style={{ textAlign: "center" }}>

                                            {e.token}

                                        </td>

                                    </tr >

                                );

                            })

                        }

                    </tbody>

                </table>

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Nomes e email</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
