import { useEffect, useState } from 'react';

import Title from '../../page/Title/Component';

import Business from '../../Business/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../lib/network';
import { INVALID_DATA } from '../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import ConfigText from '../../../config/text';
import { Navigate } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../App';

//import Viewer from '../../../../components/PageEditor/Main';

import { buildBusinessLink, buildBusinessQualifiedName, buildMaterialLinkByName } from '../../../lib/util';
import { materialProfileByToken, MATERIAL_NOTFOUND } from '../../../lib/api/mrp/material/api';
import { businessProfileByAccountToken } from '../../../lib/api/accounts/business/api';
import { BUSINESS_NOTFOUND } from '../../../lib/api/accounts/business/validation';

const Component = (props) => {

    let materialToken = props.materialToken;

    const [material, setMaterial] = useState(null);
    const [business, setBusiness] = useState(null);

    const [op, setOp] = useState(null);

    useEffect(

        () => initData(),

        []
    );

    const initData = async () => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        materialProfileByToken(

            materialToken,

            (status1, body, headers1) => {

                if (status1 === 200) {

                    let localMaterial = body;

                    switch (

                    localMaterial.produced

                    ) {

                        case 'T':

                            localMaterial.type = 'P';
                            localMaterial.typeTxt = 'Produzido';

                            break;

                        case 'F':

                            localMaterial.type = 'C';
                            localMaterial.typeTxt = 'Comprado';

                            break;

                        case 'S':

                            localMaterial.type = 'F';
                            localMaterial.typeTxt = 'Fracionado';

                            break;

                        default: console.log('invalid material type in Material Component');

                    }

                    businessProfileByAccountToken(

                        localMaterial.business.accountToken,

                        (status, body1, headers) => {

                            if (status === 200) {

                                let localBusiness = body1;

                                localMaterial.link = buildMaterialLinkByName(

                                    buildBusinessQualifiedName(localBusiness) +

                                    '/' + localMaterial.materialName.toLowerCase(),

                                    false

                                );

                                localBusiness.link = buildBusinessLink(localBusiness, false);

                                setMaterial(localMaterial);

                                props.setMaterial(localMaterial);

                                setOp(FRONTPAGE);

                            } else if (status === 400) {

                                let m = body1.messages;

                                for (let i = 0; i < m.length; i++) {

                                    if (m[i] === 'accountToken') {

                                        setOp(BUSINESS_NOTFOUND);

                                        break;

                                    }

                                }

                            } else {

                                setOp(INTERNAL_ERROR);

                            }

                        }

                    );

                } else if (status1 === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === 'businessQualifiedName') {

                            setOp(MATERIAL_NOTFOUND);

                            break;

                        }

                    }

                    setOp(MATERIAL_NOTFOUND);

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const frontPage = () => {

        return (

            <div>

                <Business accountToken={material.business.accountToken} />

                <br />

                <div className="content">

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Nome</th>

                                <td><a href={material.link}>{material.materialName}</a></td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Tipo</th>

                                <td><a href={material.link}>{material.typeTxt}</a></td>

                            </tr>

                        </tbody>

                    </table>

                </div >

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="success" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Conta criada</p>
                    <p style={{ color: 'black' }}>Um email de confirmação foi enviado para você</p>

                </Alert>

            </div >
        )

    }

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to="/" />

        );

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

Component.defaultProps = {

    setBusiness: (business) => { },

    setMaterial: (material) => { }

}

export default Component;
