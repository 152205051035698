import { FRONTPAGE } from '../../../../App';
import accountsHost from '../../../../config/api';
import { INTERNAL_ERROR } from '../../../network';
import { post, get, rget } from '../../../rest';
import { getPersonQualifiedName } from '../../../session';
import { buildPersonQualifiedName } from '../../../util';

export const updatePermission = (

    permissionCode,
    personQualifiedName,
    callBack

) => {

    post(

        accountsHost + '/permission/updatePermission',
        {},
        {

            permissionCode: permissionCode,
            personQualifiedName: personQualifiedName

        },
        callBack

    )
}

const businessRegister = (

    businessName,
    accountEmail,
    callBack

) => {

    post(

        accountsHost + '/business/registerBusiness',
        {},
        {

            businessName: businessName,
            accountEmail: accountEmail,
            emailSubject: 'Confirme sua conta no ProdCalc',
            emailBody: 'Use o link abaixo para confirmar sua conta.'

        },
        callBack

    )
}

export const permissionListByBusiness = (

    businessQualifiedName,
    callBack

) => {

    get(

        accountsHost + '/permission/permissionListByBusiness/' + businessQualifiedName,
        {},
        callBack

    )
}

export const rpermissionListByBusiness = async (

    qualifiedName // business qualified name

) => {

    return await rget(

        accountsHost +

        '/permission/permissionListByBusiness/'

        + qualifiedName,

        {}

    )
}

export const permissionListByBusinessToken = (

    accountToken,
    callBack

) => {

    get(

        accountsHost + '/permission/permissionListByBusinessToken/' + accountToken,
        {},
        callBack

    )
}

export const rpermissionListByBusinessToken = async (

    accountToken

) => {

    return await rget(

        accountsHost +

        '/permission/permissionListByBusinessToken/'

        + accountToken,

        {}

    )
}

export const setHasPermission = (

    personPermissionList,
    accountToken,
    setHasPermissionToEdit,
    setOp

) => {

    permissionListByBusinessToken(

        accountToken,

        (status, body, headers) => {

            let localPermissionList = body;

            if (status === 200) {

                localPermissionList.map(permission => {

                    permission.personQualifiedName = buildPersonQualifiedName(permission.person);

                });

                let pqn = getPersonQualifiedName();

                for (

                    let i = 0;
                    i < localPermissionList.length;
                    i++) {

                    let p = localPermissionList[i];

                    if (pqn === p.personQualifiedName) {

                        setHasPermissionToEdit(

                            personPermissionList.includes(p.code)

                        );

                        break;

                    }

                }

                setOp(FRONTPAGE);

            } else if (status === 400) {

                let m = localPermissionList.messages;

                for (let i = 0; i < m.length; i++) {

                    /* if (m[i] === 'businessQualifiedName') {
 
                        setOp(BUSINESS_NOTFOUND);
 
                        break;
 
                    } */

                    console.log(m[1]);

                }

            } else {

                setOp(INTERNAL_ERROR);

            }

        }

    );

};

export const hasPermission = (

    permissionList,
    personPermissionList

) => {

    personPermissionList.map(permission => {

        permission.personQualifiedName = buildPersonQualifiedName(

            permission.person

        );

    });

    let pqn = getPersonQualifiedName();

    for (

        let i = 0;

        i < personPermissionList.length;

        i++

    ) {

        let p = personPermissionList[i];

        if (pqn === p.personQualifiedName) {

            return permissionList.includes(p.code);

        }

    }

};

export const permissionListByPerson = (

    personQualifiedName,
    callBack

) => {

    get(

        accountsHost + '/permission/permissionListByPerson/' + personQualifiedName,
        {},
        callBack

    )
}


export const personSearch = (

    firstName,
    lastName,
    businessQualifiedName,
    callBack

) => {

    get(

        accountsHost + '/permission/personSearch' +

        '?firstName=' + ((firstName == null) ? '' : firstName) +
        '&lastName=' + ((lastName == null) ? '' : lastName) +
        '&businessQualifiedName=' + businessQualifiedName,

        {},
        callBack

    )
}

export default businessRegister;