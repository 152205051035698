import { mrpHost } from '../../../../config/api';
import { rget, rpost } from '../../../rest';

export const STRUCTURE_ALREADYCREATED = 'STRUCTURE_ALREADYCREATED';
export const STRUCTURE_CREATED = 'STRUCTURE_CREATED';
export const STRUCTURE_NOTFOUND = 'STRUCTURE_NOTFOUND';
export const STRUCTURE_INVALIDQUANTITY = 'STRUCTURE_INVALIDQUANTITY';
export const STRUCTURE_INVALIDDUEDATE = 'STRUCTURE_INVALIDDUEDATE';
export const STRUCTURE_ZEROQUANTITY = 'STRUCTURE_ZEROQUANTITY';

export const deleteOutput = async (

    token

) => {

    return await rpost(

        mrpHost + '/output/deleteOutput',

        {},

        {

            token: token

        }

    )
}

export const deleteStructure = async (

    token

) => {

    return await rpost(

        mrpHost + '/structure/delete',

        {},

        {

            token: token

        }

    )
}

export const updateStructure = async (

    list,
    token

) => {

    return await rpost(

        mrpHost + '/structure/update',

        {},

        {

            list: list,
            token: token

        }

    )
}

export const updateFraction = async (

    list,
    token

) => {

    return await rpost(

        mrpHost + '/fraction/update',

        {},

        {

            list: list,
            token: token

        }

    )
}

export const updateStructureDuedate = async (

    dueDate,
    token

) => {

    return await rpost(

        mrpHost + '/structure/updateDuedate',

        {},

        {

            dueDate: dueDate,
            token: token

        }

    )
}

const createStructure = async (

    quantity,
    dueDate,
    materialToken


) => {

    return await rpost(

        mrpHost + '/structure/create',

        {},

        {

            quantity: quantity,
            dueDate: dueDate,
            materialToken: materialToken

        }

    )
}

export const structureSearchByAccountToken = async (

    queryString

) => {

    return await rget(

        mrpHost + '/structure/searchByAccountToken' + queryString,
        {}

    );
}

export const searchComponentsByMaterialToken = async (

    queryString

) => {

    return await rget(

        mrpHost + '/structure/searchComponentsByMaterialToken' + queryString,
        {}

    );
}

export const fractionProfileByToken = async (

    token

) => {

    return await rget(

        mrpHost + '/fraction/profileByToken' +

        '/' + token,

        {}

    )
}

export const structureProfileByToken = async (

    token

) => {

    return await rget(

        mrpHost + '/structure/profileByToken' +

        '/' + token,

        {}

    )
}

export default createStructure;