const isBatchCodeValid = (data) => {

    return (data.trim().match(/^.{0,45}$/i) != null);

}

export const isBatchQuantityValid = (data) => {

    let d = new String(data);

    d = d.trim();

    return (

        (d.match(/^\d+$/i) != null)

        && (d > 0)

    );

}

export default isBatchCodeValid;