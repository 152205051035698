const isOutputQuantityValid = (

    data

) => {

    let d = new String(data);

    d = d.trim();
    
    return (

        (d.match(/^\d+$/i) != null)

        && (d > 0)

    );

}

export default isOutputQuantityValid;