import { useEffect, useState } from 'react';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../lib/network';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { REDIRECT_HOME, REDIRECT_MENU } from '../../../App';
import sessionDetail, { emailLogin } from '../../../lib/api/accounts/session/api';
import { SESSION_ID, setSessionData, setSessionId } from '../../../lib/session';

const EMAIL_TOKENNOTFOUND = 'EMAIL_TOKENNOTFOUND';
const SESSION_TOKENNOTFOUND = 'SESSION_TOKENNOTFOUND';

const Controller = () => {

    const [op, setOp] = useState(null);

    let { emailToken } = useParams();

    useEffect(

        () => handleSubmit(emailToken),
        []
    );

    const handleSubmit = async (emailToken) => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        emailLogin(

            emailToken,

            (status, body, headers) => {

                if (status === 200) {

                    setSessionId(headers.get(SESSION_ID));

                    sessionDetail((status1, body1, headers1) => {

                        if (status1 === 200) {

                            setSessionData(body1);

                            setOp(REDIRECT_MENU);

                        } else if (status1 === 400) {

                            let m = body1.messages;

                            for (let i = 0; i < m.length; i++) {

                                if (m[i] === SESSION_TOKENNOTFOUND) {

                                    setOp(SESSION_TOKENNOTFOUND);

                                    break;

                                }

                            }

                        } else {

                            setOp(INTERNAL_ERROR);

                        }

                    });

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === EMAIL_TOKENNOTFOUND) {

                            setOp(EMAIL_TOKENNOTFOUND);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    if (op === EMAIL_TOKENNOTFOUND) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(REDIRECT_HOME) }}>

                    <p style={{ color: 'black' }}>Link de login inválido</p>

                </Alert>

            </div>
        )

    }

    if (op === SESSION_TOKENNOTFOUND) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(REDIRECT_HOME) }}>

                    <p style={{ color: 'black' }}>Sessão inválida</p>

                </Alert>

            </div>
        )

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(REDIRECT_HOME) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    //http://localhost:3000/pages/public/emaillogin/b0a4307cdb23

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to="/" />

        );

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    return null;

}

export default Controller;
