const isOrderQuantityValid = (

    data

) => {

    let d = new String(data);

    d = d.trim();

    return (

        (d.match(/^\d+$/i) != null)

        && (d > 0)

    );

}

export const isYieldValid = (

    data,   // quantity
    data1   // yield

) => {

    return (

        (data % data1) === 0

    );

}

export default isOrderQuantityValid;