const ConfigImages = {

	headerLogo: "https://blog.prodcalc.com/wp-content/uploads/2022/04/cropped-prodcalclogomrp.png",
	profilePic: "https://drive.google.com/uc?export=download&id=1USIvw70zqi43MT579LLnsN2wBUwmBQcW",
	loadingIcon: "https://drive.google.com/uc?export=download&id=118lnzTZTYU9Ks1gGcbpeLysmQKHyzR0x",

	facebook: "https://seeklogo.com/images/W/whatsapp-icon-logo-6E793ACECD-seeklogo.com.png",
	whatsapp: "https://www.seekpng.com/png/detail/280-2802791_moran-facebook-link-facebook-logo-black.png"

}

export default ConfigImages;