import { useEffect, useState } from 'react';

import Header from '../../../../../../components/page/Header/Component';
import Footer from '../../../../../../components/page/Footer/Component';
import Title from '../../../../../../components/page/Title/Component';

import Material from '../../../../../../components/Material/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../../lib/network';
import { INVALID_DATA } from '../../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../../../../App';

//import Viewer from '../../../../components/PageEditor/Main';

import { buildOrderLinkByQualifiedName } from '../../../../../../lib/util';
import { formatDate } from '../../../../../../lib/format';
import { orderSearchByMaterialToken } from '../../../../../../lib/api/mrp/input/api';

import { hasPermission, rpermissionListByBusiness } from '../../../../../../lib/api/accounts/permission/api';
import { hasSessionId } from '../../../../../../lib/session';

const Component = () => {

    let { businessQualifiedName, materialName, materialToken } = useParams();

    const [list, setList] = useState([]);
    const [isQuery, setIsQuery] = useState(false);
    const [status, setStatus] = useState('L');

    const [hasPermissionToEdit, setHasPermissionToEdit] = useState(false);

    const [op, setOp] = useState('');

    useEffect(

        () => initData(),

        []
    );

    const initData = async () => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (!hasSessionId()) {

            setOp(FRONTPAGE);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await rpermissionListByBusiness(

            businessQualifiedName

        );

        switch (response.status) {

            case 200:

                let localPermissionList = response.body;

                setHasPermissionToEdit(

                    hasPermission(

                        ['SUP', 'PUB'],

                        localPermissionList

                    )

                );

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

        setOp(FRONTPAGE);

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await orderSearchByMaterialToken(

            '?token=' + materialToken +

            '&status=' + status

        );

        switch (response.status) {

            case 200:

                let orderList = response.body;

                orderList.map(order => {

                    order.link = buildOrderLinkByQualifiedName(

                        businessQualifiedName +
                        '/' + materialName +
                        '/' + order.token

                    );

                });

                setIsQuery(true);

                setList(orderList)

                setOp(FRONTPAGE);

                break;

            case 400:

                console.log(response.body);

            default: setOp(INTERNAL_ERROR);

        }

    }

    const handleStatusChange = (e) => {

        setStatus(e.target.value);

    }

    const frontPage = () => {

        return (

            <div>

                <Header />

                <Title title="Procurar Ordens" />

                <Material materialToken={materialToken} />

                <br />

                <div className="content">

                    {hasPermissionToEdit &&

                        <p>

                            <a href={

                                '/pages/private/person/material/order/create' +

                                '/' + businessQualifiedName +

                                '/' + materialName +

                                '/' + materialToken

                            }>Abrir Ordem</a>

                        </p>

                    }

                    <form onSubmit={handleSubmit}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Situação</th>
                                    <td>

                                        <label><input type="radio" value="L" checked={status === 'L'} onChange={handleStatusChange} />Todas</label>
                                        <label><input type="radio" value="O" checked={status === 'O'} onChange={handleStatusChange} />Aberta</label>
                                        <label><input type="radio" value="C" checked={status === 'C'} onChange={handleStatusChange} />Cancelada</label>
                                        <label><input type="radio" value="R" checked={status === 'R'} onChange={handleStatusChange} />Rejeitada</label>
                                        <label><input type="radio" value="A" checked={status === 'A'} onChange={handleStatusChange} />Aceita</label>
                                        <label><input type="radio" value="I" checked={status === 'I'} onChange={handleStatusChange} />Interrompida</label>
                                        <label><input type="radio" value="D" checked={status === 'D'} onChange={handleStatusChange} />Entregue</label>

                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </form>

                    {(list.length > 0) &&

                        <div>

                            <p>

                                Legenda: O = Aberta, C = Cancelada, R = Rejeitada, A = Aceita, I = Interrompida, D = Entregue

                            </p>

                            <table>

                                <tbody>

                                    <tr>
                                        <th>Token</th>
                                        <th>Quantidade</th>
                                        <th>Início</th>
                                        <th>Fim</th>
                                        <th style={{ borderRightColor: "black" }}>Situação</th>
                                    </tr>

                                    {list.map((order, index) => {

                                        return (

                                            <tr key={index}>

                                                <td>

                                                    <a href={order.link}>{order.token}</a>

                                                </td>

                                                <td style={{ textAlign: "right" }}>

                                                    {order.quantity}

                                                </td>

                                                <td style={{ textAlign: "center" }}>

                                                    {formatDate(order.startDate)}

                                                </td>

                                                <td style={{ textAlign: "center" }}>

                                                    {formatDate(order.finishDate)}

                                                </td>

                                                <td style={{ textAlign: "center" }}>

                                                    {order.status}

                                                </td>

                                            </tr >

                                        )

                                    })}

                                </tbody>

                            </table >

                        </div>

                    }

                    {(isQuery && list.length === 0) &&

                        <div>

                            <br />

                            Sem resultados.

                        </div>

                    }

                </div>

                <br />

                <Footer />

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Nomes e email</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
