import { useEffect, useState } from 'react';

import Header from '../../../../../components/page/Header/Component';
import Footer from '../../../../../components/page/Footer/Component';
import Title from '../../../../../components/page/Title/Component';

import Material from '../../../../../components/Material/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../lib/network';
import { INVALID_DATA } from '../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../lib/rest';
import { CircularProgress, TextField } from '@mui/material';
import { Box } from '@mui/material';

import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../../../App';

//import Viewer from '../../../../components/PageEditor/Main';

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { batchProfileByToken, BATCH_ALREADYCREATED, BATCH_INVALIDQUANTITY, BATCH_NOTENOUGHTQUANTITY, BATCH_NOTFOUND, BATCH_ZEROQUANTITY, updateBatchCode, updateExpireDate } from '../../../../../lib/api/mrp/batch/api';
import { buildBatchLinkByQualifiedName } from '../../../../../lib/util';
import { setHasPermission } from '../../../../../lib/api/accounts/permission/api';
import formatDateTime, { parseDate, formatDate } from '../../../../../lib/format';
import { isBatchQuantityValid } from '../../../../../lib/api/mrp/batch/validation';
import createMovement from '../../../../../lib/api/mrp/batch/movement/api';

const BATCHCODE_FORM = 'BATCHCODE_FORM';
const EXPIREDATE_FORM = 'EXPIREDATE_FORM';
const MOVEMENT_FORM = 'MOVEMENT_FORM';
const BATCH_INVALIDMOVEMENTQUANTITY = 'BATCH_INVALIDMOVEMENTQUANTITY';

const Component = () => {

    let { businessQualifiedName, materialName, batchToken } = useParams();

    const [movementQuantity, setMovementQuantity] = useState('');
    const [movementType, setMovementType] = useState('I');
    const [batch, setBatch] = useState(null);
    const [batchCode, setBatchCode] = useState(null);
    const [expireDate, setExpireDate] = useState(null);
    const [previousExpireDate, setPreviousExpireDate] = useState(null);
    const [hasPermissionToEdit, setHasPermissionToEdit] = useState(false);
    const [editDate, setEditDate] = useState(false);

    const [op, setOp] = useState(null);

    useEffect(

        () => initData(),

        []
    );

    const initData = async () => {

        // if (!hasSessionId()) {

        //     setOp(REDIRECT_HOME);

        //     return;

        // }

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        batchProfileByToken(

            batchToken,

            (status, body, headers) => {

                let localBatch = body;

                if (status === 200) {

                    localBatch.link = buildBatchLinkByQualifiedName(

                        businessQualifiedName +

                        '/' + materialName +

                        '/' + batchToken,

                        true

                    );

                    setBatch(localBatch);

                    setBatchCode(localBatch.batchCode);

                    if (

                        localBatch.expireDate !== null

                    ) {

                        let ed = parseDate(localBatch.expireDate);

                        setExpireDate(ed);

                        setPreviousExpireDate(ed);

                    }

                    if (localBatch.expireDate !== null) {

                        setEditDate(true);

                    }

                    setHasPermission(

                        ['SUP', 'PUB'],

                        localBatch.material.business.accountToken,

                        setHasPermissionToEdit,

                        setOp);

                } else if (status === 400) {

                    /* let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === 'businessQualifiedName') {

                            setOp(MATERIAL_NOTFOUND);

                            break;

                        }

                    } */

                    setOp(BATCH_NOTFOUND);

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleBatchCodeSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        /* if (

            !
            isBatchCodeValid(batchCode)

        ) {

            setOp(BATCH);

            return;

        } */

        setOp(CIRCULAR_SPINNER);

        updateBatchCode(

            batchCode,
            batchToken,

            (status, body, headers) => {

                if (status === 200) {

                    setOp(FRONTPAGE);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === BATCH_ALREADYCREATED) {

                            setOp(BATCH_ALREADYCREATED);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleExpireDateSubmit = async (e) => {

        e.preventDefault();

        if (previousExpireDate === expireDate) {

            setOp(FRONTPAGE);

            return;
        }

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        updateExpireDate(

            (expireDate === null) ? null : expireDate.toISOString().substring(0, 10),

            batchToken,

            (status, body, headers) => {

                if (status === 200) {

                    setOp(FRONTPAGE);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        console.log(m[i]);

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleBatchCodeChange = (e) => {

        setBatchCode(e.target.value);

    }

    const handleExpireDateChange = (

        date // picked date

    ) => {

        setExpireDate(date);

    }

    const batchCodeForm = () => {

        return (

            <div className="content">

                <form onSubmit={handleBatchCodeSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Nome</th>

                                <td colSpan="2">

                                    <input type="text" value={batchCode} onChange={handleBatchCodeChange} />

                                </td>

                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table>

                </form>

            </div >

        );

    }

    const expireDateForm = () => {

        return (

            <div className="content">

                <form onSubmit={handleExpireDateSubmit}>

                    <table>

                        <tbody>

                            <tr>

                                <th className="verticalHeader">Data de Expiração <input type="checkbox" checked={editDate} onChange={() => {

                                    if (editDate) {

                                        setExpireDate(null);

                                    } else {

                                        setExpireDate(new Date());

                                    }

                                    setEditDate(editDate ? false : true);

                                }} />

                                </th>

                                <td>

                                    {editDate &&

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>

                                            <MobileDatePicker

                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) => <TextField {...params} />}
                                                onChange={handleExpireDateChange}
                                                value={expireDate}

                                            />

                                        </LocalizationProvider>

                                    }

                                </td>

                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => {

                                        setExpireDate(previousExpireDate);

                                        setOp(FRONTPAGE)

                                    }}>Cancelar</button>

                                </td>

                                <td colSpan="2" style={{ textAlign: "right" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table>

                </form>

            </div >

        );

    }

    const handleMovementSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !
            isBatchQuantityValid(movementQuantity)

        ) {

            setOp(BATCH_INVALIDMOVEMENTQUANTITY);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        createMovement(

            (movementType === 'I')

                ? movementQuantity

                : -movementQuantity

            ,
            batchToken,

            (status, body, headers) => {

                if (status === 200) {

                    initData();

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === BATCH_ZEROQUANTITY) {

                            setOp(BATCH_INVALIDMOVEMENTQUANTITY);

                            break;

                        }

                        if (m[i] === BATCH_NOTENOUGHTQUANTITY) {

                            setOp(BATCH_NOTENOUGHTQUANTITY);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleMovementQuantityChange = (e) => {

        setMovementQuantity(e.target.value);

    }

    const handleMovementTypeChange = (e) => {

        setMovementType(e.target.value);

    }

    const movementForm = () => {

        return (

            <div className="content">

                <form onSubmit={handleMovementSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Quantidade</th>

                                <td colSpan="2">

                                    <input type="text" value={movementQuantity} onChange={handleMovementQuantityChange} />

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Tipo</th>
                                <td>

                                    <label><input type="radio" value="I" checked={movementType === 'I'} onChange={handleMovementTypeChange} />Entrada</label>
                                    <label><input type="radio" value="O" checked={movementType === 'O'} onChange={handleMovementTypeChange} />Saída</label>

                                </td>
                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table>

                </form>

            </div >

        );

    }

    const frontPage = () => {

        return (

            <div>

                <Header />

                <Title title="Perfil do Lote" />

                <Material materialToken={batch.material.token} />

                <br />

                <div className="content">

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Código do Lote</th>

                                <td>

                                    {((batchCode === '') || (batchCode === null)) && 'Nenhum'}

                                    {!((batchCode === '') || (batchCode === null)) && batchCode}

                                </td>

                                <td style={{ textAlign: "center" }}>

                                    {hasPermissionToEdit &&

                                        <button type="button" onClick={() => {

                                            setOp(BATCHCODE_FORM);

                                        }} className="buttonLink">Editar</button>

                                    }

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Expiração</th>

                                <td>

                                    {(expireDate === null) && 'Nenhuma'}

                                    {(expireDate !== null) && formatDate(expireDate)}

                                </td>

                                <td style={{ textAlign: "center" }}>

                                    {hasPermissionToEdit &&

                                        <button type="button" onClick={() => {

                                            setOp(EXPIREDATE_FORM);

                                        }} className="buttonLink">Editar</button>

                                    }

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Quantidade</th>

                                <td colSpan="2">

                                    {batch.quantity}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Criado</th>

                                <td colSpan="2">

                                    {formatDateTime(batch.created)}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Modificado</th>

                                <td colSpan="2">

                                    {formatDateTime(batch.modified)}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Token</th>

                                <td colSpan="2">

                                    {batch.token}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Link</th>
                                <td colSpan="2"><input type="text" defaultValue={batch.link} style={{ width: "95%" }} /></td>
                            </tr>

                        </tbody>

                    </table>

                </div >

                <br />

                <div className="content">

                    {hasPermissionToEdit &&

                        <div><button type="button" className="buttonLink" onClick={() => setOp(MOVEMENT_FORM)}>Movimentar</button><br /><br /></div>

                    }

                    <table style={{ width: "100%" }}><tbody>

                        <tr>

                            <th>Tipo</th>
                            <th>Quantidade</th>
                            <th>Data</th>
                            <th>Origem</th>
                            <th style={{ borderRightColor: "black" }}>Token</th>

                        </tr>

                        {batch.movement.map((movement) => {

                            return (

                                <tr>

                                    <td style={{ textAlign: "center" }}>{movement.type}</td>

                                    <td style={{ textAlign: "right" }}>{movement.quantity}</td>

                                    <td style={{ textAlign: "center" }}>{formatDateTime(movement.created)}</td>

                                    <td style={{ textAlign: "center" }}>

                                        {movement.person !== undefined && 'PEO'}

                                        {movement.order !== undefined && 'ORD'}

                                    </td>

                                    <td style={{ textAlign: "center" }}>

                                        {movement.person !== undefined && movement.person}

                                        {movement.order !== undefined && movement.order}

                                    </td>

                                </tr>

                            );

                        })}

                    </tbody></table>

                </div >

                <br />

                {/* <Viewer

                    load={materialPageByAccountToken}
                    save={updatePersonPage}
                    token={material.account.token}
                    edit={isLoggedPerson(currentAccountToken)} />

                < br /> */}

                <Footer />

            </div >

        );

    }

    if (op === BATCH_INVALIDQUANTITY) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(BATCHCODE_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Quantidade deve ser positiva</p>

                </Alert>

            </div>
        )

    }

    if (op === BATCH_INVALIDMOVEMENTQUANTITY) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(MOVEMENT_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Quantidade deve ser positiva</p>

                </Alert>

            </div>
        )

    }

    if (op === BATCH_NOTENOUGHTQUANTITY) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(MOVEMENT_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Quantidade excede a quantidade em estoque</p>

                </Alert>

            </div>
        )

    }

    if (op === BATCH_ALREADYCREATED) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(BATCHCODE_FORM) }}>

                    <p style={{ color: 'black' }}>Código de Lote inválido</p>
                    <p style={{ color: 'black' }}>Já existe um lote com este código para esse material</p>

                </Alert>

            </div>
        )

    }

    if (op === BATCHCODE_FORM) {

        return batchCodeForm();

    }

    if (op === EXPIREDATE_FORM) {

        return expireDateForm();

    }

    if (op === MOVEMENT_FORM) {

        return movementForm();

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="success" onClose={() => { setOp(FRONTPAGE) }}>

                    <p style={{ color: 'black' }}>Conta criada</p>
                    <p style={{ color: 'black' }}>Um email de confirmação foi enviado para você</p>

                </Alert>

            </div >
        )

    }

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to="/" />

        );

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(FRONTPAGE) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

export default Component;
