import { useEffect, useState } from 'react';

import Header from '../../../../../../components/page/Header/Component';
import Footer from '../../../../../../components/page/Footer/Component';
import Title from '../../../../../../components/page/Title/Component';

import Material from '../../../../../../components/Material/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../../lib/network';
import { INVALID_DATA } from '../../../../../../lib/validation';

import { Alert, TextField } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../../../../App';

//import Viewer from '../../../../components/PageEditor/Main';

import { deleteInput, inputProfileByToken, INPUT_ALREADYCREATED, INPUT_INVALIDDUEDATE, INPUT_INVALIDQUANTITY, INPUT_NOTFOUND, INPUT_ZEROQUANTITY, updateInput, updateInputDuedate } from '../../../../../../lib/api/mrp/input/api';
import { buildInputLinkByQualifiedName, buildMaterialLinkByName } from '../../../../../../lib/util';
import { hasPermission, rpermissionListByBusinessToken } from '../../../../../../lib/api/accounts/permission/api';
import formatDateTime, { formatDate, parseDate } from '../../../../../../lib/format';
import isInputQuantityValid from '../../../../../../lib/api/mrp/input/validation';

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const INPUT_FORM = 'INPUT_FORM';
const INPUTDUEDATE_FORM = 'INPUTDUEDATE_FORM';

const Component = () => {

    let { businessQualifiedName, materialName, token } = useParams();

    const [dueDate, setDuedate] = useState(null);
    const [quantity, setQuantity] = useState('');
    const [input, setInput] = useState(null);
    const [hasPermissionToEdit, setHasPermissionToEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [op, setOp] = useState(null);

    useEffect(

        () => initData(),

        []
    );

    const initData = async () => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await inputProfileByToken(

            token

        );

        let localInput = null;

        switch (response.status) {

            case 200:

                localInput = response.body;

                localInput.link = buildInputLinkByQualifiedName(

                    businessQualifiedName +

                    '/' + materialName +

                    '/' + token,

                    true

                );

                setInput(localInput);

                setDuedate(

                    parseDate(

                        localInput.dueDate

                    )

                );

                setQuantity(

                    localInput.quantity

                );

                setOp(FRONTPAGE);

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

        response = await rpermissionListByBusinessToken(

            localInput.material.business.accountToken

        );

        switch (response.status) {

            case 200:

                let localPermissionList = response.body;

                setHasPermissionToEdit(

                    hasPermission(

                        ['SUP', 'PUB'],

                        localPermissionList

                    )

                );

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

        setOp(FRONTPAGE);

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isInputQuantityValid(quantity)

            )

        ) {

            setOp(INVALID_DATA);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await updateInput(

            quantity,
            token

        );

        switch (response.status) {

            case 200:

                initData();

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    if (

                        (m[i] === 'quantity')

                    ) {

                        setOp(INVALID_DATA);

                    }

                    if (m[i] === INPUT_NOTFOUND) {

                        console.log(INPUT_NOTFOUND);

                    }

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const handleChange = (e) => {

        setQuantity(e.target.value);

    }

    const handleDuedateSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await updateInputDuedate(

            dueDate.toISOString().substring(0, 10),
            token

        );

        switch (response.status) {

            case 200:

                initData();

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    if (

                        (m[i] === 'dueDate')

                    ) {

                        setOp(INPUT_INVALIDDUEDATE);

                    }

                    if (m[i] === INPUT_NOTFOUND) {

                        console.log(INPUT_NOTFOUND);

                    }

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const handleDuedateChange = (

        pickedDate

    ) => {

        setDuedate(pickedDate);

    }

    const inputForm = () => {

        return (

            <div className="content">

                <form onSubmit={handleSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Quantidade</th>

                                <td colSpan="2">

                                    <input type="text" value={quantity} onChange={handleChange} />

                                </td>

                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table>

                </form>

            </div >

        );

    }

    const inputFormDuedate = () => {

        return (

            <div className="content">

                <form onSubmit={handleDuedateSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Data de Entrada</th>
                                <td>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                                        <MobileDatePicker

                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) => <TextField {...params} />}
                                            onChange={handleDuedateChange}
                                            value={dueDate}

                                        />

                                    </LocalizationProvider>

                                </td>
                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table>

                </form>

            </div >

        );

    }

    const handleDeleteSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await deleteInput(

            token

        );

        switch (response.status) {

            case 200:

                setOp(REDIRECT_HOME);

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    if (m[i] === INPUT_NOTFOUND) {

                        console.log(INPUT_NOTFOUND);

                    }

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const frontPage = () => {

        return (

            <div>

                <Header />

                <Title title="Perfil da Entrada" />

                <Material materialToken={input.material.token} />

                <br />

                <div className="content">

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Quantidade</th>

                                <td>

                                    {input.quantity}

                                </td>

                                <td style={{ textAlign: "center" }}>

                                    {hasPermissionToEdit &&

                                        <button type="button" onClick={() => {

                                            setOp(INPUT_FORM);

                                        }} className="buttonLink">Editar</button>

                                    }

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Entrega</th>

                                <td>

                                    {formatDate(input.dueDate)}

                                </td>

                                <td style={{ textAlign: "center" }}>

                                    {hasPermissionToEdit &&

                                        <button type="button" onClick={() => {

                                            setOp(INPUTDUEDATE_FORM);

                                        }} className="buttonLink">Editar</button>

                                    }

                                </td>

                            </tr>

                            {/* <tr>
                                <th className="verticalHeader">Realizado</th>

                                <td colSpan="2">

                                    {input.realized}%

                                </td>

                            </tr> */}

                            <tr>
                                <th className="verticalHeader">Criado</th>

                                <td colSpan="2">

                                    {formatDateTime(input.created)}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Modificado</th>

                                <td colSpan="2">

                                    {formatDateTime(input.modified)}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Responsável</th>

                                <td colSpan="2">

                                    {input.person.accountToken}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Token</th>

                                <td colSpan="2">

                                    {input.token}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Link</th>
                                <td colSpan="2"><input type="text" defaultValue={input.link} style={{ width: "95%" }} /></td>
                            </tr>

                        </tbody>

                    </table>

                </div >

                <br />

                <div className="content" style={{ margin: "auto" }}>

                    <input type="checkbox" checked={confirmDelete} onChange={e => setConfirmDelete(e.target.checked)} />

                    Confirmo apagar

                    <button type="button" onClick={handleDeleteSubmit} style={{ float: "right" }} disabled={!confirmDelete}>Apagar</button>

                </div>

                <br />

                {/* <Viewer

                    load={materialPageByAccountToken}
                    save={updatePersonPage}
                    token={material.account.token}
                    edit={isLoggedPerson(currentAccountToken)} />

                < br /> */}

                <Footer />

            </div >

        );

    }

    if (op === INPUT_ALREADYCREATED) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(INPUT_FORM) }}>

                    <p style={{ color: 'black' }}>Data inválida </p>
                    <p style={{ color: 'black' }}>Já existe uma saída deste material para esta data</p>

                </Alert>

            </div>
        )

    }

    if (op === INPUT_FORM) {

        return inputForm();

    }

    if (op === INPUTDUEDATE_FORM) {

        return inputFormDuedate();

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(INPUT_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Quantidade deve ser um inteiro positivo</p>

                </Alert>

            </div >
        )

    }

    if (op === INPUT_INVALIDDUEDATE) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(INPUTDUEDATE_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Data deve ser igual ou maior que hoje</p>

                </Alert>

            </div >
        )

    }

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to={

                buildMaterialLinkByName(

                    businessQualifiedName +

                    '/' + materialName

                )} />

        );

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(FRONTPAGE) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

export default Component;
