import accountsHost from '../../../../config/api';
import { post } from '../../../rest';

export const updateProfileText = (

    profileText,
    callBack

) => {

    post(

        accountsHost + '/account/updateProfileText',
        {},
        { profileText: profileText },
        callBack

    )
}

export const updateBusinessProfileText = (

    profileText,
    businessQualifiedName,
    callBack

) => {

    post(

        accountsHost + '/account/updateBusinessProfileText',
        {},
        {

            businessQualifiedName: businessQualifiedName,
            profileText: profileText

        },
        callBack

    )
}

export const updateProfilePic = (

    imageUrl,
    callBack

) => {

    post(

        accountsHost + '/account/updateProfilePic',
        {},
        { imageUrl: imageUrl },
        callBack

    )
}

export const updateBusinessProfilePic = (

    imageUrl,
    businessQualifiedName,
    callBack

) => {

    post(

        accountsHost + '/account/updateBusinessProfilePic',
        {},
        {

            businessQualifiedName: businessQualifiedName,
            imageUrl: imageUrl

        },
        callBack

    )
}

export const resendConfirmEmail = (

    accountEmail,
    callBack

) => {

    post(

        accountsHost + '/account/resendConfirmEmail',
        {},
        {

            emailAccount: accountEmail,
            emailSubject: 'Confirme sua conta no ProdCalc',
            emailBody: 'Volte para o ProdCalc e use o código abaixo para confirmar sua conta.'

        },
        callBack

    )
}

export const sendConfirmCode = (

    txt, // confirm code
    callBack

) => {

    post(

        accountsHost + '/account/sendConfirmCode',
        {},
        {

            emailAccount: txt

        },
        callBack

    )
}

const confirmEmail = (

    token, // email token
    callBack

) => {

    post(

        accountsHost + '/account/confirmEmail',
        {},
        { token: token },
        callBack

    )
}

export default confirmEmail;