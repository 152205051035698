import { mrpHost } from '../../../../config/api';
import { get, rget, rpost } from '../../../rest';

export const INPUT_ALREADYCREATED = 'INPUT_ALREADYCREATED';
export const INPUT_CREATED = 'INPUT_CREATED';
export const INPUT_NOTFOUND = 'INPUT_NOTFOUND';
export const INPUT_INVALIDQUANTITY = 'INPUT_INVALIDQUANTITY';
export const INVALID_STOCKLIST = 'INVALID_STOCKLIST';
export const INPUT_ZEROQUANTITY = 'INPUT_ZEROQUANTITY';

export const deleteOutput = async (

    token

) => {

    return await rpost(

        mrpHost + '/output/deleteOutput',

        {},

        {

            token: token

        }

    )
}

export const cancelOrder = async (

    token

) => {

    return await rpost(

        mrpHost + '/order/cancel',

        {},

        {

            token: token

        }

    )
}

export const interruptOrder = async (

    token

) => {

    return await rpost(

        mrpHost + '/order/interrupt',

        {},

        {

            token: token

        }

    )
}

export const rejectOrder = async (

    token

) => {

    return await rpost(

        mrpHost + '/order/reject',

        {},

        {

            token: token

        }

    )
}

export const acceptOrder = async (

    body

) => {

    return await rpost(

        mrpHost + '/order/accept',

        {},

        body

    )
}

export const deliverOrder = async (

    token, // order token

    quantity, // quantity produced

    code, // batch code

    date // expire date

) => {

    return await rpost(

        mrpHost + '/order/deliver',

        {},

        {

            token: token,

            quantity: quantity,

            code: code,

            date: date

        }

    )
}

export const updateInput = async (

    quantity,
    token

) => {

    return await rpost(

        mrpHost + '/input/update',

        {},

        {

            quantity: quantity,
            token: token

        }

    )
}

export const updateInputDuedate = async (

    dueDate,
    token

) => {

    return await rpost(

        mrpHost + '/input/updateDuedate',

        {},

        {

            dueDate: dueDate,
            token: token

        }

    )
}

const createOrder = async (

    quantity,
    startDate,
    finishDate,
    token // material token

) => {

    return await rpost(

        mrpHost + '/order/create',

        {},

        {

            quantity: quantity,
            startDate: startDate,
            finishDate: finishDate,
            token: token // material token

        }

    )
}

export const orderSearchByAccountToken = async (

    queryString

) => {

    return await rget(

        mrpHost + '/order/searchByAccountToken' + queryString,
        {}

    );
}

export const inputSearchByMaterialToken = async (

    queryString

) => {

    return await rget(

        mrpHost + '/input/searchByMaterialToken' + queryString,
        {}

    );
}

export const inputProfileByToken = async (

    inputToken

) => {

    return await rget(

        mrpHost + '/input/profileByToken' +

        '/' + inputToken,

        {}

    )
}

export const orderProfileByToken = async (

    token // order token

) => {

    return await rget(

        mrpHost + '/order/profileByToken' +

        '/' + token,

        {}

    )
}

export const inventorySearchByToken = async (

    queryString

) => {

    return await rget(

        mrpHost + '/order/inventorySearchByToken' + queryString,
        {}

    );
}

export const usedInventorySearchByToken = async (

    queryString

) => {

    return await rget(

        mrpHost + '/order/usedInventorySearchByToken' + queryString,
        {}

    );
}

export default createOrder;