import { useEffect, useState } from 'react';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../lib/network';
import { INVALID_DATA } from '../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../App';

import Editor from './Editor';

const OPEN_EDITOR = 'OPEN_EDITOR';

const Component = (props) => {

    const [page, setPage] = useState(null);
    const [op, setOp] = useState(null);

    useEffect(

        () => initData(),
        []
    );

    const initData = async () => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        props.load(

            props.token,

            (status, body, headers) => {

                if (status === 200) {

                    if (body.content === undefined) {

                        body.content = '';

                    }

                    setPage(body);

                    setOp(FRONTPAGE);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        /* if (m[i] === 'businessQualifiedName') {

                            setOp(PERSON_NOTFOUND);

                            break;

                        } */

                        console.log(m[i]);

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const openEditor = () => {

        setOp(OPEN_EDITOR);

    }

    const save = async (content) => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        props.save(content, () => {

            page.content = content

            setOp(FRONTPAGE);

        });

    }

    const frontPage = () => {

        return (

            <div className="content">

                {props.edit &&

                    <div style={{ textAlign: "right" }}>

                        <button type="button" className="buttonLink" onClick={openEditor}>Editar</button>

                    </div>
                }

                <div dangerouslySetInnerHTML={{ __html: page.content }}></div>

            </div >

        );

    };

    if (op === OPEN_EDITOR) {

        return (

            <div className="content">

                <Editor page={page} cancel={() => setOp(FRONTPAGE)} save={save} />

            </div>
        )

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Nomes e email</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to="/" />

        );

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

export default Component;
