import { useState } from 'react';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../lib/network';
import isValidName, { INVALID_DATA, isValidEmail } from '../../../../lib/validation';
import personRegister from '../../../../lib/api/accounts/person/api';
import { resendConfirmEmail } from '../../../../lib/api/accounts/account/api';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { Presentation } from '../../../../config/help';

const ACCOUNT_CREATED = 'ACCOUNT_CREATED';
const EMAIL_ALREADYREGISTERED = 'EMAIL_ALREADYREGISTERED';
const RESENDEMAIL_FORM = 'RESEND_FORM';
const INVALID_RESENDEMAIL = 'INVALID_RESENDEMAIL';
const EMAIL_NOTREGISTERED = 'EMAIL_NOTREGISTERED';
const EMAIL_ALREADYCONFIRMED = 'EMAIL_ALREADYCONFIRMED';
const RESENDEMAIL_MSG = 'RESENDEMAIL_MSG';

const Component = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [accountEmail, setAccountEmail] = useState('');
    const [op, setOp] = useState(null);

    const handleChange = (e) => {


        if (e.target.name === 'firstName') {

            setFirstName(e.target.value);

        }

        if (e.target.name === 'lastName') {

            setLastName(e.target.value);

        }

        if (e.target.name === 'accountEmail') {

            setAccountEmail(e.target.value);

        }

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isValidName(firstName)
                && isValidName(lastName)
                && isValidEmail(accountEmail)

            )

        ) {

            setOp(INVALID_DATA);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        personRegister(

            firstName,
            lastName,
            accountEmail,

            (status, body) => {

                if (status === 200) {

                    setOp(ACCOUNT_CREATED);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === EMAIL_ALREADYREGISTERED) {

                            setOp(EMAIL_ALREADYREGISTERED);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleSubmitResendEmail = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isValidEmail(accountEmail)

            )

        ) {

            setOp(INVALID_RESENDEMAIL);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        resendConfirmEmail(

            accountEmail,

            (status, body) => {

                if (status === 200) {

                    setOp(RESENDEMAIL_MSG);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === EMAIL_ALREADYCONFIRMED) {

                            setOp(EMAIL_ALREADYCONFIRMED);

                            break;

                        }

                        if (m[i] === EMAIL_NOTREGISTERED) {

                            setOp(EMAIL_NOTREGISTERED);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const frontPage = () => {

        return (

            <div className="content">

                <h4>ProdCalc é um MRP Online e Gratuito para Pequenas Indústrias</h4>

                <blockquote>Um <a href="https://www.youtube.com/watch?v=BrZFunJRdCY" target="_blank">MRP (Material Requirements Planning)</a> é um sistema de software que indica o que deve ser
                    comprado e produzido em uma indústria ao longo do tempo.</blockquote>

                <h4>Ao usar o ProdCalc em sua indústria, você irá:</h4>

                <ul style={{ color: "white" }}>

                    <li>Usar o MRP no seu processo produtivo e aumentar a eficiência da sua indústria</li>
                    <li>Ser encontrado por novos fornecedores e obter novas ofertas de materiais</li>
                    <li>Conhecer outros profissionais de indústria e realizar novos negócios</li>

                </ul>

                <h4>Mais sobre o ProdCalc em:</h4>

                <p><a href={Presentation.main} target="_blank" rel="noreferrer">Blog do ProdCalc</a></p>

                <h3 style={{ textAlign: "right" }}>ProdCalc é gratuito para todos e sempre será.</h3>

            </div>

        );

    }

    const resendEmailForm = (props) => {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <p>Reenvio de email de confirmação</p>

                <div>

                    <p>Informe o email que você cadastrou no ProdCalc</p>

                    <form onSubmit={handleSubmitResendEmail}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Email</th>
                                    <td><input type="email" name="accountEmail" value={accountEmail} onChange={handleChange} /></td>
                                </tr>

                                <tr>
                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </form>

                    <p>

                        <button type="button" className="buttonLink" onClick={() => setOp(null)}>Cancelar</button>

                    </p>

                </div>

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Nomes e email</p>

                </Alert>

            </div>
        )

    }

    if (op === ACCOUNT_CREATED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="success" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Conta criada</p>
                    <p style={{ color: 'black' }}>Um email de confirmação foi enviado para você</p>

                </Alert>

            </div >
        )

    }

    if (op === EMAIL_ALREADYREGISTERED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Email já cadastrado</p>

                </Alert>

            </div >
        )

    }

    if (op === RESENDEMAIL_FORM) {

        return resendEmailForm();

    }

    if (op === INVALID_RESENDEMAIL) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Email</p>

                </Alert>

            </div>
        )

    }

    if (op === RESENDEMAIL_MSG) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="success" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email enviado</p>
                    <p style={{ color: 'black' }}>Use o link no email para confirmar sua conta.</p>

                </Alert>

            </div>
        )

    }

    if (op === EMAIL_ALREADYCONFIRMED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email já confirmado</p>

                </Alert>

            </div>
        )

    }

    if (op === EMAIL_NOTREGISTERED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email não cadastrado</p>

                </Alert>

            </div>
        )

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
