import { publishingHost } from '../../../config/api';
import get, { post } from '../../rest';

export const updatePersonPage = (

    pageContent,
    callBack

) => {

    post(

        publishingHost + '/page/updatePersonPage',
        {},
        {
            pageContent: pageContent

        },
        callBack

    )
}

export const updateMaterialPage = (

    content, // page content
    token, // material token
    callBack

) => {

    post(

        publishingHost + '/page/updateMaterialPage',
        {},
        {
            content: content,
            token: token

        },
        callBack

    )
}

export const updateBusinessPage = (

    pageContent,
    businessToken,
    callBack

) => {

    post(

        publishingHost + '/page/updateBusinessPage',
        {},
        {
            pageContent: pageContent,
            businessToken: businessToken

        },
        callBack

    )
}

export const businessPageByBusinessToken = (

    accountToken,
    callBack

) => {

    get(

        publishingHost + '/page/businessPageByAccountToken/' + accountToken,
        {},
        callBack

    )
}

export const materialPageByMaterialToken = (

    token,// material token
    callBack

) => {

    get(

        publishingHost + '/page/materialPageByMaterialToken/' + token,
        {},
        callBack

    )
}

export const personPageByAccountToken = (

    accountToken,
    callBack

) => {

    get(

        publishingHost + '/page/personPageByAccountToken/' + accountToken,
        {},
        callBack

    )
}

export const updateProfileText = (

    profileText,
    callBack

) => {

    post(

        publishingHost + '/account/updateProfileText',
        {},
        { profileText: profileText },
        callBack

    )
}

export const updateBusinessProfileText = (

    profileText,
    businessQualifiedName,
    callBack

) => {

    post(

        publishingHost + '/account/updateBusinessProfileText',
        {},
        {

            businessQualifiedName: businessQualifiedName,
            profileText: profileText

        },
        callBack

    )
}

export const updateProfilePic = (

    imageUrl,
    callBack

) => {

    post(

        publishingHost + '/account/updateProfilePic',
        {},
        { imageUrl: imageUrl },
        callBack

    )
}

export const updateBusinessProfilePic = (

    imageUrl,
    businessQualifiedName,
    callBack

) => {

    post(

        publishingHost + '/account/updateBusinessProfilePic',
        {},
        {

            businessQualifiedName: businessQualifiedName,
            imageUrl: imageUrl

        },
        callBack

    )
}

export const resendConfirmEmail = (

    accountEmail,
    callBack

) => {

    post(

        publishingHost + '/account/resendConfirmEmail',
        {},
        {

            emailAccount: accountEmail,
            emailSubject: 'Confirme sua conta no ProdCalc',
            emailBody: 'Use o link abaixo para confirmar sua conta.'

        },
        callBack

    )
}

const confirmEmail = (

    emailToken,
    callBack

) => {

    post(

        publishingHost + '/account/confirmEmail',
        {},
        { emailToken: emailToken },
        callBack

    )
}

export default personPageByAccountToken;