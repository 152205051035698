import { useEffect, useState } from 'react';

import Header from '../../../../../../components/page/Header/Component';
import Footer from '../../../../../../components/page/Footer/Component';
import Title from '../../../../../../components/page/Title/Component';

import Material from '../../../../../../components/Material/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../../lib/network';
import { INVALID_DATA } from '../../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../../lib/rest';
import { CircularProgress, TextField } from '@mui/material';
import { Box } from '@mui/material';

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { Navigate, useParams } from 'react-router-dom';
import { REDIRECT_PROFILE, FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../../../../App';

//import Viewer from '../../../../components/PageEditor/Main';

import { buildMaterialLinkByName, buildOrderLinkByQualifiedName } from '../../../../../../lib/util';
import { hasPermission, rpermissionListByBusinessToken } from '../../../../../../lib/api/accounts/permission/api';
import formatDateTime, { formatDate } from '../../../../../../lib/format';
import { acceptOrder, cancelOrder, deliverOrder, interruptOrder, INVALID_STOCKLIST, orderProfileByToken, rejectOrder } from '../../../../../../lib/api/mrp/order/api';
import Inventory from './Inventory';
import UsedInventory from './UsedInventory';
import { isBatchQuantityValid } from '../../../../../../lib/api/mrp/batch/validation';
import { ProductionProfile } from '../../../../../../config/help';

const INPUT_FORM = 'INPUT_FORM';

const Component = () => {

    let { businessQualifiedName, materialName, token } = useParams();

    const [materialToken, setMaterialToken] = useState(null);
    const [order, setOrder] = useState(null);

    const [hasPermissionToEdit, setHasPermissionToEdit] = useState(false);

    const [confirmOp, setConfirmOp] = useState(null);

    const [op, setOp] = useState(null);

    const [batchList, setBatchList] = useState(null);

    const [quantity, setQuantity] = useState(null);

    const [code, setCode] = useState(null);

    const [date, setDate] = useState(new Date());
    const [editDate, setEditDate] = useState(false);

    useEffect(

        () => initData(),

        []
    );

    const initData = async () => {

        // if (!hasSessionId()) {

        //     setOp(REDIRECT_HOME);

        //     return;

        // }

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await orderProfileByToken(

            token

        );

        let localOrder = null;

        switch (response.status) {

            case 200:

                localOrder = response.body;

                localOrder.link = buildOrderLinkByQualifiedName(

                    businessQualifiedName +

                    '/' + materialName +

                    '/' + token,

                    true

                );

                switch (

                localOrder.status

                ) {

                    case 'O':

                        localOrder.statusDescription = 'Aberta';
                        break;

                    case 'C':

                        localOrder.statusDescription = 'Cancelada';
                        break;

                    case 'R':

                        localOrder.statusDescription = 'Rejeitada';
                        break;

                    case 'A':

                        localOrder.statusDescription = 'Aceita';
                        break;

                    case 'I':

                        localOrder.statusDescription = 'Interrompida';
                        break;

                    case 'D':

                        localOrder.statusDescription = 'Entregue';
                        break;

                }

                setOrder(localOrder);

                setMaterialToken(localOrder.material.token);

                setQuantity(localOrder.quantity);

                setOp(FRONTPAGE);

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

        response = await rpermissionListByBusinessToken(

            localOrder.material.business.accountToken

        );

        switch (response.status) {

            case 200:

                let localPermissionList = response.body;

                setHasPermissionToEdit(

                    hasPermission(

                        ['SUP', 'PUB'],

                        localPermissionList

                    )

                );

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

        setOp(FRONTPAGE);

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        /* if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isInputQuantityValid(quantity)

            )

        ) {

            setOp(INVALID_DATA);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await updateInput(

            quantity,
            token

        );

        switch (response.status) {

            case 200:

                initData();

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    if (

                        (m[i] === 'quantity')

                    ) {

                        setOp(INVALID_DATA);

                    }

                    if (m[i] === INPUT_NOTFOUND) {

                        console.log(INPUT_NOTFOUND);

                    }

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        } */

    }

    const handleChange = (e) => {

        setQuantity(e.target.value);

    }

    const handleCodeChange = (e) => {

        setCode(e.target.value);

    }

    const handleExpireDateChange = (

        date // expire date

    ) => {

        setDate(date);

    }

    const inputForm = () => {

        return (

            <div className="content">

                <form onSubmit={handleSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Quantidade</th>

                                <td colSpan="2">

                                    {/* <input type="text" value={quantity} onChange={handleChange} /> */}

                                </td>

                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table>

                </form>

            </div >

        );

    }

    const handleCancelSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await cancelOrder(

            token

        );

        switch (response.status) {

            case 200:

                setOp(REDIRECT_HOME);

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    console.log(m[i]);

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const handleRejectSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await rejectOrder(

            token

        );

        switch (response.status) {

            case 200:

                setOp(REDIRECT_HOME);

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    console.log(m[i]);

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const handleAcceptSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let l = [];

        for (

            let i = 0;

            i < batchList.length;

            i++

        ) {

            let b = batchList[i];

            let name = b.name

            while (

                name === b.name

            ) {

                if (

                    b.selected

                ) {

                    l.push({

                        name: b.name,

                        token: b.token

                    });

                    break;

                }

                if (

                    (i + 1) < batchList.length

                ) {

                    b = batchList[++i];

                } else {

                    break;

                }

            }

        }

        let body = {

            token: token,

            list: l

        };

        let response = await acceptOrder(

            body

        );

        switch (response.status) {

            case 200:

                setOp(REDIRECT_HOME);

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    if (

                        m[i] === 'list'

                    ) {

                        setOp(INVALID_STOCKLIST);

                    }

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const handleInterruptSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await interruptOrder(

            token

        );

        switch (response.status) {

            case 200:

                setOp(REDIRECT_HOME);

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    console.log(m[i]);

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const handleDeliverSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isBatchQuantityValid(quantity)

            )

        ) {

            setQuantity(order.quantity);

            setOp(INVALID_DATA);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await deliverOrder(

            token, // order token

            quantity, // quantity produced

            code, // batch code

            editDate ? date.toISOString().substring(0, 10) : null

        );

        switch (response.status) {

            case 200:

                setOp(REDIRECT_HOME);

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    if (

                        m[i] === 'quantity'

                    ) {

                        setOp(INVALID_DATA);

                    }

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const frontPage = () => {

        return (

            <div>

                <Header />

                <Title title="Perfil da Ordem" />

                <Material materialToken={order.material.token} />

                <br />

                <div className="content">

                    <p><a href={ProductionProfile.main} target={"_blank"}>Ajuda</a></p>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Quantidade</th>

                                <td>

                                    {order.quantity}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Início</th>

                                <td>

                                    {formatDate(order.startDate)}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Entrega</th>

                                <td>

                                    {formatDate(order.finishDate)}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Criado</th>

                                <td>

                                    {formatDateTime(order.created)}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Modificado</th>

                                <td>

                                    {formatDateTime(order.modified)}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Responsável</th>

                                <td>

                                    {order.person.accountToken}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Token</th>

                                <td>

                                    {order.token}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Situação</th>

                                <td>

                                    {order.statusDescription}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Link</th>
                                <td><input type="text" defaultValue={order.link} style={{ width: "95%" }} /></td>
                            </tr>

                        </tbody>

                    </table>

                </div >

                {((order.status === 'A') || (order.status === 'I') || (order.status === 'D')) &&

                    <div>

                        <br />

                        <UsedInventory token={token} />

                    </div>

                }

                {(hasPermissionToEdit && (order.status === 'O')) &&

                    <div>

                        < br />

                        <div className="content" style={{ margin: "auto" }}>

                            <table><tbody>

                                <tr>

                                    <td>

                                        <input type="radio" value="C" checked={confirmOp === 'C'} onChange={e => setConfirmOp(e.target.value)} />

                                        Confirmo CANCELAR

                                    </td>

                                    <td style={{ textAlign: 'right' }}>

                                        <button type="button" onClick={handleCancelSubmit} style={{ float: "right" }} disabled={confirmOp !== 'C'}>Cancelar</button>

                                    </td>

                                </tr>

                                <tr>

                                    <td>

                                        <input type="radio" value="R" checked={confirmOp === 'R'} onChange={e => setConfirmOp(e.target.value)} />

                                        Confirmo REJEITAR

                                    </td>

                                    <td style={{ textAlign: 'right' }}>

                                        <button type="button" onClick={handleRejectSubmit} style={{ float: "right" }} disabled={confirmOp !== 'R'}>Rejeitar</button>

                                    </td>

                                </tr>

                            </tbody></table>

                        </div>

                        <Title title="Aceite de Ordem" header="3" />

                        <div className="content" style={{ margin: "auto" }}>

                            <Inventory token={token} setBatchList={setBatchList} />

                        </div>

                        <br />

                        <div className="content" style={{ margin: "auto" }}>

                            <table><tbody>

                                <tr>

                                    <td>

                                        <input type="radio" value="A" checked={confirmOp === 'A'} onChange={e => setConfirmOp(e.target.value)} />

                                        Confirmo ACEITAR

                                    </td>

                                    <td style={{ textAlign: 'right' }}>

                                        <button type="button" onClick={handleAcceptSubmit} style={{ float: "right" }} disabled={confirmOp !== 'A'}>Aceitar</button>

                                    </td>

                                </tr>

                            </tbody></table>

                        </div>

                    </div>

                }

                {(hasPermissionToEdit && (order.status === 'A')) &&

                    <div>

                        < br />

                        <div className="content" style={{ margin: "auto" }}>

                            <table><tbody>

                                <tr>

                                    <td>

                                        <input type="radio" value="I" checked={confirmOp === 'I'} onChange={e => setConfirmOp(e.target.value)} />

                                        Confirmo INTERROMPER

                                    </td>

                                    <td style={{ textAlign: 'right' }}>

                                        <button type="button" onClick={handleInterruptSubmit} style={{ float: "right" }} disabled={confirmOp !== 'I'}>Interromper</button>

                                    </td>

                                </tr>

                            </tbody></table>

                        </div>

                        <Title title="Entrega de Ordem" header="3" />

                        <div className="content" style={{ margin: "auto" }}>

                            <form onSubmit={handleDeliverSubmit}>

                                <table>

                                    <tbody>

                                        <tr>
                                            <th className="verticalHeader">Quantidade</th>
                                            <td><input type="text" value={quantity} onChange={handleChange} /></td>
                                        </tr>

                                        <tr>
                                            <th className="verticalHeader">Código do Lote</th>
                                            <td><input type="text" value={code} onChange={handleCodeChange} /></td>
                                        </tr>

                                        <tr>
                                            <th className="verticalHeader">Data de Expiração <input type="checkbox" checked={editDate} onChange={() => setEditDate(editDate ? false : true)} /></th>
                                            <td>

                                                {editDate &&

                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                        <MobileDatePicker

                                                            inputFormat="dd/MM/yyyy"
                                                            renderInput={(params) => <TextField {...params} />}
                                                            onChange={handleExpireDateChange}
                                                            value={date}

                                                        />

                                                    </LocalizationProvider>

                                                }

                                            </td>

                                        </tr>

                                        <tr>

                                            <td>

                                                <input type="radio" value="D" checked={confirmOp === 'D'} onChange={e => setConfirmOp(e.target.value)} />

                                                Confirmo ENTREGAR

                                            </td>

                                            <td style={{ textAlign: "right" }}>

                                                <button type="submit" value="Enviar" disabled={confirmOp !== 'D'}>Enviar</button>

                                            </td>

                                        </tr>

                                    </tbody>

                                </table>

                            </form>

                        </div>

                    </div>

                }

                <br />

                {/* <Viewer

                    load={materialPageByAccountToken}
                    save={updatePersonPage}
                    token={material.account.token}
                    edit={isLoggedPerson(currentAccountToken)} />

                < br /> */}

                <Footer />

            </div >

        );

    }

    if (op === INPUT_FORM) {

        return inputForm();

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(FRONTPAGE) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Quantidade deve ser um inteiro positivo</p>

                </Alert>

            </div >
        )

    }

    if (op === INVALID_STOCKLIST) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(REDIRECT_PROFILE) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>O estoque de cada componente deve ser escolhido</p>

                </Alert>

            </div >
        )

    }

    if (op === REDIRECT_PROFILE) {

        window.location.reload(false);

    }

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to={

                buildMaterialLinkByName(

                    businessQualifiedName +

                    '/' + materialName

                )} />

        );

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(FRONTPAGE) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

export default Component;
