const Component = (props) => {
    
    const frontPage = () => {

        return (

            <div className="content titleContent">

                {(props.header === '1') && <h1 className="titleContent">{props.title}</h1>}
                {(props.header === '2') && <h2 className="titleContent">{props.title}</h2>}
                {(props.header === '3') && <h3 className="titleContent">{props.title}</h3>}
                {(props.header === '4') && <h4 className="titleContent">{props.title}</h4>}

            </div>

        );

    }

    return frontPage();

}

Component.defaultProps = {

    header: '2'

}

export default Component;
