const isInputQuantityValid = (

    data

) => {

    let d = new String(data);

    return (

        (d.match(/^\d+$/i) != null)

        && (d > 0)

    );

}

export default isInputQuantityValid;