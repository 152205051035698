import { useEffect, useState } from 'react';

import Header from '../../../../../../components/page/Header/Component';
import Footer from '../../../../../../components/page/Footer/Component';
import Title from '../../../../../../components/page/Title/Component';

import Material from '../../../../../../components/Material/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../../lib/network';
import { INVALID_DATA } from '../../../../../../lib/validation';

import { Alert, TextField } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../../../../App';

//import Viewer from '../../../../components/PageEditor/Main';

import { outputProfileByToken, OUTPUT_ALREADYCREATED, OUTPUT_INVALIDDUEDATE, OUTPUT_INVALIDQUANTITY, OUTPUT_NOTFOUND, OUTPUT_ZEROQUANTITY, updateOutput, updateOutputDuedate } from '../../../../../../lib/api/mrp/output/api';
import { buildMaterialLinkByName, buildOutputLinkByQualifiedName } from '../../../../../../lib/util';
import { hasPermission, rpermissionListByBusinessToken } from '../../../../../../lib/api/accounts/permission/api';
import formatDateTime, { formatDate, parseDate } from '../../../../../../lib/format';
import isOutputQuantityValid from '../../../../../../lib/api/mrp/output/validation';

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { deleteOutput } from '../../../../../../lib/api/mrp/input/api';

const OUTPUT_FORM = 'OUTPUT_FORM';
const OUTPUTDUEDATE_FORM = 'OUTPUTDUEDATE_FORM';

const Component = () => {

    let { businessQualifiedName, materialName, outputToken } = useParams();

    const [dueDate, setDuedate] = useState(null);
    const [quantity, setQuantity] = useState('');
    const [output, setOutput] = useState(null);
    const [hasPermissionToEdit, setHasPermissionToEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [op, setOp] = useState(null);

    useEffect(

        () => initData(),

        []
    );

    const initData = async () => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await outputProfileByToken(

            outputToken

        );

        let localOutput = null;

        switch (response.status) {

            case 200:

                localOutput = response.body;

                localOutput.link = buildOutputLinkByQualifiedName(

                    businessQualifiedName +

                    '/' + materialName +

                    '/' + outputToken,

                    true

                );

                setOutput(localOutput);

                setDuedate(

                    parseDate(

                        localOutput.dueDate

                    )

                );

                setQuantity(

                    localOutput.quantity

                );

                setOp(FRONTPAGE);

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

        response = await rpermissionListByBusinessToken(

            localOutput.material.business.accountToken

        );

        switch (response.status) {

            case 200:

                let localPermissionList = response.body;

                setHasPermissionToEdit(

                    hasPermission(

                        ['SUP', 'PUB'],

                        localPermissionList

                    )

                );

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

        setOp(FRONTPAGE);

    }

    const handleOutputSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isOutputQuantityValid(quantity)

            )

        ) {

            setOp(INVALID_DATA);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await updateOutput(

            quantity,
            outputToken

        );

        switch (response.status) {

            case 200:

                initData();

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    if (

                        (m[i] === 'quantity')

                    ) {

                        setOp(INVALID_DATA);

                    }

                    if (m[i] === OUTPUT_NOTFOUND) {

                        console.log(OUTPUT_NOTFOUND);

                    }

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const handleQuantityChange = (e) => {

        setQuantity(e.target.value);

    }

    const handleOutputDuedateSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await updateOutputDuedate(

            dueDate.toISOString().substring(0, 10),
            outputToken

        );

        switch (response.status) {

            case 200:

                initData();

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    if (

                        (m[i] === 'dueDate')

                    ) {

                        setOp(OUTPUT_INVALIDDUEDATE);

                    }

                    if (m[i] === OUTPUT_NOTFOUND) {

                        console.log(OUTPUT_NOTFOUND);

                    }

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const handleDuedateChange = (

        pickedDate

    ) => {

        setDuedate(pickedDate);

    }

    const outputForm = () => {

        return (

            <div className="content">

                <form onSubmit={handleOutputSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Quantidade</th>

                                <td colSpan="2">

                                    <input type="text" value={quantity} onChange={handleQuantityChange} />

                                </td>

                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table>

                </form>

            </div >

        );

    }

    const outputFormDuedate = () => {

        return (

            <div className="content">

                <form onSubmit={handleOutputDuedateSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Data de Saída</th>
                                <td>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                                        <MobileDatePicker

                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) => <TextField {...params} />}
                                            onChange={handleDuedateChange}
                                            value={dueDate}

                                        />

                                    </LocalizationProvider>

                                </td>
                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table>

                </form>

            </div >

        );

    }

    const handleDeleteSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await deleteOutput(

            outputToken

        );

        switch (response.status) {

            case 200:

                setOp(REDIRECT_HOME);

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    if (m[i] === OUTPUT_NOTFOUND) {

                        console.log(OUTPUT_NOTFOUND);

                    }

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const frontPage = () => {

        return (

            <div>

                <Header />

                <Title title="Perfil da Saída" />

                <Material materialToken={output.material.token} />

                <br />

                <div className="content">

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Quantidade</th>

                                <td>

                                    {output.quantity}

                                </td>

                                <td style={{ textAlign: "center" }}>

                                    {hasPermissionToEdit &&

                                        <button type="button" onClick={() => {

                                            setOp(OUTPUT_FORM);

                                        }} className="buttonLink">Editar</button>

                                    }

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Entrega</th>

                                <td>

                                    {formatDate(output.dueDate)}

                                </td>

                                <td style={{ textAlign: "center" }}>

                                    {hasPermissionToEdit &&

                                        <button type="button" onClick={() => {

                                            setOp(OUTPUTDUEDATE_FORM);

                                        }} className="buttonLink">Editar</button>

                                    }

                                </td>

                            </tr>

                            {/* <tr>
                                <th className="verticalHeader">Realizado</th>

                                <td colSpan="2">

                                    {output.realized}%

                                </td>

                            </tr> */}

                            <tr>
                                <th className="verticalHeader">Criado</th>

                                <td colSpan="2">

                                    {formatDateTime(output.created)}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Modificado</th>

                                <td colSpan="2">

                                    {formatDateTime(output.modified)}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Responsável</th>

                                <td colSpan="2">

                                    {output.person.accountToken}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Token</th>

                                <td colSpan="2">

                                    {output.token}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Link</th>
                                <td colSpan="2"><input type="text" defaultValue={output.link} style={{ width: "95%" }} /></td>
                            </tr>

                        </tbody>

                    </table>

                </div >

                <br />

                <div className="content" style={{ margin: "auto" }}>

                    <input type="checkbox" checked={confirmDelete} onChange={e => setConfirmDelete(e.target.checked)} />

                    Confirmo apagar

                    <button type="button" onClick={handleDeleteSubmit} style={{ float: "right" }} disabled={!confirmDelete}>Apagar</button>

                </div>

                <br />

                {/* <Viewer

                    load={materialPageByAccountToken}
                    save={updatePersonPage}
                    token={material.account.token}
                    edit={isLoggedPerson(currentAccountToken)} />

                < br /> */}

                <Footer />

            </div >

        );

    }

    if (op === OUTPUT_ALREADYCREATED) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(OUTPUT_FORM) }}>

                    <p style={{ color: 'black' }}>Data inválida </p>
                    <p style={{ color: 'black' }}>Já existe uma saída deste material para esta data</p>

                </Alert>

            </div>
        )

    }

    if (op === OUTPUT_FORM) {

        return outputForm();

    }

    if (op === OUTPUTDUEDATE_FORM) {

        return outputFormDuedate();

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(OUTPUT_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Quantidade deve ser um inteiro positivo</p>

                </Alert>

            </div >
        )

    }

    if (op === OUTPUT_INVALIDDUEDATE) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(OUTPUTDUEDATE_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Data deve ser igual ou maior que hoje</p>

                </Alert>

            </div >
        )

    }

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to={

                buildMaterialLinkByName(

                    businessQualifiedName +

                    '/' + materialName

                )} />

        );

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(FRONTPAGE) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

export default Component;
