import { useEffect, useState } from 'react';

import Header from '../../../../components/page/Header/Component';
import Footer from '../../../../components/page/Footer/Component';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../lib/network';
import isValidName, { INVALID_DATA, isValidEmail } from '../../../../lib/validation';
import { personProfileByQualifiedName, personSearch, updatePersonName } from '../../../../lib/api/accounts/person/api';
import { updateProfilePic, updateProfileText } from '../../../../lib/api/accounts/account/api';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import ConfigImages from '../../../../config/images';
import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, MAINPAGE, MENU_PATH, REDIRECT_MENU } from '../../../../App';


import { getPersonQualifiedName, isLoggedUser, setSessionData } from '../../../../lib/session';
import profilePicValidate, { profileTextValidate } from '../../../../lib/api/accounts/account/validation';
import sessionDetail from '../../../../lib/api/accounts/session/api';
import { buildPersonQualifiedName, useQuery } from '../../../../lib/util';
import { permissionListByBusiness, updatePermission } from '../../../../lib/api/accounts/permission/api';
import ConfigText from '../../../../config/text';
import formatDateTime from '../../../../lib/format';

const ACCOUNT_CREATED = 'ACCOUNT_CREATED';
const EMAIL_ALREADYREGISTERED = 'EMAIL_ALREADYREGISTERED';
const RESENDEMAIL_FORM = 'RESEND_FORM';
const INVALID_RESENDEMAIL = 'INVALID_RESENDEMAIL';
const EMAIL_ALREADYCONFIRMED = 'EMAIL_ALREADYCONFIRMED';
const RESENDEMAIL_MSG = 'RESENDEMAIL_MSG';

const PROFILEPICFORM = 'PROFILEPICFORM';
const INVALID_PROFILEPIC = 'INVALID_PROFILEPIC';

const PROFILETEXTFORM = 'PROFILETEXTFORM';
const INVALID_PROFILETEXT = 'INVALID_PROFILETEXT';

const PERSONNAMEFORM = 'PERSONNAMEFORM';
const INVALID_PERSONNAME = 'INVALID_PERSONNAME';
const PERMISSION_FORM = 'PERMISSION_FORM';

const PERMISSION_LIST = ['SUP', 'PUB'/*, 'MGR', 'OPE'*/, 'NON'];

const Component = (props) => {

    const [list, setList] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [permission, setPermission] = useState(props.permission);
    const [permissionCode, setPermissionCode] = useState(props.permission.permission);

    const [op, setOp] = useState(null);

    let { businessQualifiedName } = useParams();

    const [currentBusinessQualifiedName, setCurrentBusinessQualifiedName] = useState(businessQualifiedName);

    useEffect(

        () => initData(),
        []
    );

    const initData = () => {

        if (permission.person.account.profileText == null) {

            permission.person.account.profileText = ConfigText.profileText;

        }

        permission.person.personQualifiedName = buildPersonQualifiedName(permission.person);

        setPermission(permission);

        setPermissionCode(permission.code);

        setOp(PERMISSION_FORM);

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        updatePermission(

            permissionCode,
            permission.person.personQualifiedName,

            (status, body, headers) => {

                if (status === 200) {

                    setOp(MAINPAGE);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        /*  if (m[i] === EMAIL_TOKENNOTFOUND) {
    
                             setOp(EMAIL_TOKENNOTFOUND);
    
                             break;
    
                         } */

                        console.log([i]);

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleChange = (e) => {

        setPermissionCode(e.target.value);

        setOp(PERMISSION_FORM);

    }

    const frontPage = () => {

        let p = permission;
        let p1 = p.person;

        return (

            <div>

                <Header />

                <br />

                <div className="content">

                    <form onSubmit={handleSubmit}>

                        <table style={{ width: "100%" }}>

                            <tbody>

                                <tr>

                                    <td colSpan="2" style={{ textAlign: "center", padding: "10px" }}>

                                        {p1.account.profileText}

                                    </td>

                                </tr>

                                <tr>
                                    <th className="verticalHeader">Nome</th>

                                    <td>{p1.firstName}</td>

                                </tr>

                                <tr>
                                    <th className="verticalHeader">Sobrenome</th>
                                    <td>

                                        {p1.lastName}

                                    </td>

                                </tr>

                                <tr>
                                    <th className="verticalHeader">Criado</th>
                                    <td>

                                        {formatDateTime(p1.account.created)}

                                    </td>
                                </tr>

                                <tr>

                                    <th className="verticalHeader">Permissões</th>

                                    <td>

                                        <table>

                                            <tbody>

                                                {PERMISSION_LIST.map((p2, index) => {

                                                    return (

                                                        <tr key={index}>

                                                            <td>

                                                                <input type="radio" value={p2}
                                                                    checked={p2 === permissionCode}
                                                                    onChange={handleChange} />

                                                            </td>

                                                            <td>{p2}</td>

                                                        </tr>

                                                    )

                                                })}

                                            </tbody>

                                        </table>

                                    </td>

                                </tr>

                                {(p.created !== null) &&

                                    <tr>

                                        <th className="verticalHeader">Concedida</th>
                                        <td>

                                            {formatDateTime(p.created)}

                                        </td>
                                    </tr>

                                }

                                <tr>
                                    <td style={{ textAlign: "left" }}>

                                        <button type="button" onClick={() => setOp(MAINPAGE)}>Cancelar</button>

                                    </td>

                                    <td style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>

                                </tr>

                            </tbody>

                        </table>

                    </form>

                </div >

                <br />

                <Footer />

            </div >

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Nomes e email</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to={MENU_PATH} />

        );

    }

    if (op === MAINPAGE) {

        window.location.reload(false);

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
