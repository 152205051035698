import { cloneElement, useEffect, useState } from 'react';

import Header from '../../../../../../components/page/Header/Component';
import Footer from '../../../../../../components/page/Footer/Component';
import Title from '../../../../../../components/page/Title/Component';

import Material from '../../../../../../components/Material/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../../lib/network';
import { INVALID_DATA } from '../../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../../../../App';

//import Viewer from '../../../../components/PageEditor/Main';

import cloneObject, { buildInputLinkByQualifiedName, buildOrderLinkByQualifiedName } from '../../../../../../lib/util';
import { formatDate } from '../../../../../../lib/format';
import { inventorySearchByToken } from '../../../../../../lib/api/mrp/order/api';

const Component = (

    props // ordertoken

) => {

    const [list, setList] = useState([]);

    const [op, setOp] = useState('');

    const [batchList, setBatchList] = useState([]);

    useEffect(

        () => initData(),

        []
    );

    const initData = async () => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await inventorySearchByToken(

            '?token=' + props.token // order token

        );

        let localList = null;

        switch (response.status) {

            case 200:

                localList = response.body;

                localList.map(e => { // material

                    e.list.map(e1 => { // batch

                        batchList.push(

                            {

                                name: e.rec.name,
                                token: e1.token,
                                selected: false

                            }

                        );

                    });

                });

                props.setBatchList(

                    batchList

                );

                setList(localList); // inventory list

                setOp(FRONTPAGE);

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

    }

    const isChecked = (

        name, // material name
        token // batch token

    ) => {

        for (

            let i = 0;

            i < batchList.length;

            i++

        ) {

            if (

                (batchList[i].name === name)

                && (batchList[i].token === token)

            ) {

                return batchList[i].selected;

            }

        }

    }

    const check = (

        name, // material name
        token // batch token

    ) => {

        for (

            let i = 0;

            i < batchList.length;

            i++

        ) {

            if (

                (batchList[i].name === name)

                && (batchList[i].token === token)

            ) {

                batchList[i].selected = true;

            } else if (batchList[i].name === name) {

                batchList[i].selected = false;

            }

        }

        setBatchList(

            cloneObject(batchList)

        );

        props.setBatchList(

            batchList

        );

    }

    const handleSubmit = async (e) => {

        /* e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        let response = await orderSearchByMaterialToken(

            '?token=' + materialToken +

            '&status=' + status

        );

        switch (response.status) {

            case 200:

                let orderList = response.body;

                orderList.map(order => {

                    order.link = buildOrderLinkByQualifiedName(

                        businessQualifiedName +
                        '/' + materialName +
                        '/' + order.token

                    );

                });

                setIsQuery(true);

                setList(orderList)

                setOp(FRONTPAGE);

                break;

            case 400:

                console.log(response.body);

            default: setOp(INTERNAL_ERROR);

        } */

    }

    const handleStatusChange = (e) => {

        //    setStatus(e.target.value);

    }

    const frontPage = () => {

        return (

            <div className="content">

                {(list.length > 0) &&

                    list.map((

                        e, // component

                        index) => {

                        return (

                            <div key={index}>

                                <br />

                                <table>

                                    <tbody>

                                        <tr>
                                            <th>Material</th>
                                            <th style={{ borderRightColor: "black" }}>Quantidade</th>
                                        </tr>

                                        <tr key={index}>

                                            <td style={{ padding: "5px" }}>

                                                {e.rec.name}

                                            </td>

                                            <td style={{ textAlign: "right", padding: "5px" }}>

                                                {e.rec.quantity}

                                            </td>

                                        </tr >

                                    </tbody>

                                </table>

                                <br />

                                {(e.list.length > 0) &&

                                    <table>

                                        <tbody>

                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Token</th>
                                                <th>Quantidade</th>
                                                <th style={{ borderRightColor: "black" }}>Validade</th>
                                            </tr>

                                            {e.list.map((

                                                e1, // inventory

                                                index1) => {

                                                return (

                                                    <tr key={index1}>

                                                        <td style={{ padding: "5px" }}>

                                                            <input type="radio"

                                                                checked={isChecked(

                                                                    e.rec.name,

                                                                    e1.token

                                                                )}

                                                                onChange={() => check(

                                                                    e.rec.name,

                                                                    e1.token

                                                                )} />

                                                        </td>

                                                        <td style={{ textAlign: "center", padding: "5px" }}>

                                                            {e1.token}

                                                        </td>

                                                        <td style={{ textAlign: "right", padding: "5px" }}>

                                                            {e1.quantity}

                                                        </td>

                                                        <td style={{ textAlign: "center", padding: "5px" }}>

                                                            {(e1.expireDate !== null) && formatDate(e1.expireDate)}

                                                            {(e1.expireDate === null) && 'N/A'}

                                                        </td>

                                                    </tr >


                                                );

                                            })}

                                        </tbody>

                                    </table>

                                }

                                {(e.list.length === 0) &&

                                    <div style={{ textAlign: "center" }}>

                                        Nenhum estoque com essa quantidade

                                    </div>

                                }

                            </div>

                        );

                    })

                }

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Nomes e email</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
