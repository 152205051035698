import { useEffect, useState } from 'react';

import Header from '../../../../../components/page/Header/Component';
import Footer from '../../../../../components/page/Footer/Component';
import Title from '../../../../../components/page/Title/Component';

import Material from '../../../../../components/Material/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../lib/network';
import { INVALID_DATA } from '../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU, REDIRECT_PROFILE, SHOW_FORM } from '../../../../../App';

//import Viewer from '../../../../components/PageEditor/Main';

import { fractionProfileByToken, structureProfileByToken, updateFraction, updateStructure } from '../../../../../lib/api/mrp/structure/api';

import ComponentPicker from './ComponentPicker';
import isQuantityValid from '../../../../../lib/api/mrp/structure/validation';
import { buildInputLinkByQualifiedName, buildMaterialLinkByName, buildMaterialQualifiedName } from '../../../../../lib/util';
import { hasPermission, rpermissionListByBusinessToken } from '../../../../../lib/api/accounts/permission/api';
import { FractionProfile, ProductionProfile } from '../../../../../config/help';
import { hasSessionId } from '../../../../../lib/session';

const NO_BASEMATERIAL = 'NO_BASEMATERIAL';
const ORDER_ISSUED = 'ORDER_ISSUED';

const Component = () => {

    let {

        businessQualifiedName,
        materialName,
        token, // material token

    } = useParams();

    const [list, setList] = useState([]);
    const [excludeList, setExcludeList] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [material, setMaterial] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [position, setPosition] = useState(null);
    const [confirmSave, setConfirmSave] = useState(false);
    const [hasPermissionToEdit, setHasPermissionToEdit] = useState(false);

    const [op, setOp] = useState('');

    useEffect(

        () => initData(),

        []
    );

    const initData = async () => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await fractionProfileByToken(

            token

        );

        let localList = null;
        let localExcludeList = [materialName];

        switch (response.status) {

            case 200:

                localList = response.body.list;

                localList.map(component => {

                    component.link = buildMaterialLinkByName(

                        businessQualifiedName +
                        '/' + component['material']['name']

                    );

                    switch (

                    component['material']['produced']

                    ) {

                        case 'T':

                            component['material']['producedTxt'] = 'P';

                            break;

                        case 'F':

                            component['material']['producedTxt'] = 'C';

                            break;

                        case 'S':

                            component['material']['producedTxt'] = 'F';

                            break;

                        default: console.log('invalid material type in Material Fraction');

                    }

                    localExcludeList.push(

                        component['material']['name']

                    );

                });

                setList(localList);

                setExcludeList(

                    localExcludeList

                );

                setOp(FRONTPAGE);

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

        setOp(FRONTPAGE);

    }

    const setUpPermission = async (

        accountToken

    ) => {

        if (!hasSessionId()) {

            return;

        }

        let response = await rpermissionListByBusinessToken(

            accountToken

        );

        switch (response.status) {

            case 200:

                let localPermissionList = response.body;

                setHasPermissionToEdit(

                    hasPermission(

                        ['SUP', 'PUB'],

                        localPermissionList

                    )

                );

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

    }

    const handleSaveSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            list.length === 0

        ) {

            setOp(NO_BASEMATERIAL);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let localList = [];

        list.map(l => {

            localList.push(

                {

                    name: l.material.name,
                    quantity: l.quantity

                }

            );

        });

        let response = await updateFraction(

            localList,
            token

        );

        switch (response.status) {

            case 200:

                setOp(REDIRECT_PROFILE);

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    if (

                        m[i] === 'ORDER_ISSUED'

                    ) {

                        setOp(ORDER_ISSUED);

                        return;

                    }

                    console.log(m[i]);

                }

                break;

            default:

                setOp(INTERNAL_ERROR);
        }

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (

            !isQuantityValid(quantity)

        ) {

            setOp(INVALID_DATA);

            return;

        }

        list[position].quantity = quantity;

        setList(JSON.parse(JSON.stringify(list)));

        setOp(FRONTPAGE);

    }

    const handleChange = (e) => {

        setQuantity(e.target.value);

    }

    const showQuantityForm = () => {

        return (

            <div className="content">

                <form onSubmit={handleSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Quantidade</th>

                                <td colSpan="2">

                                    <input type="text" value={quantity} onChange={handleChange} />

                                </td>

                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table>

                </form>

            </div >

        );

    }

    const addComponent = (

        material

    ) => {

        list.push({

            material: {

                name: material.materialName,
                producedTxt: material.producedTxt

            },

            quantity: 1,

            link: buildMaterialLinkByName(

                businessQualifiedName +
                '/' + material.materialName

            )

        });

        let localExcludeList = [materialName];

        list.map(l => {

            localExcludeList.push(

                l.material.name

            );

        });

        setExcludeList(

            localExcludeList

        );

        setShowForm(false);

    }

    const removeComponent = (

        position

    ) => {

        list.splice(position, 1);

        setList(JSON.parse(JSON.stringify(list)));

        let localExcludeList = [materialName];

        list.map(l => {

            localExcludeList.push(

                l.material.name

            );

        });

        setExcludeList(

            localExcludeList

        );

    }

    const editComponent = (

        p

    ) => {

        setPosition(p);

        setQuantity(

            list[p].quantity

        );

        setOp(SHOW_FORM);

    }

    const frontPage = () => {

        return (

            <div>

                <Header />

                <Title title="Rendimento do Material" />

                <Material materialToken={token} setMaterial={async (materialParam) => {

                    setMaterial(materialParam);

                    await setUpPermission(materialParam.business.accountToken);

                }} />

                <Title title="Material Base" header="3" />

                {!showForm &&

                    <div>

                        <div className="content">

                            <p><a href={FractionProfile.main} target={"_blank"}>Ajuda</a></p>

                            {(hasPermissionToEdit && (list.length === 0)) &&

                                <button className="buttonLink" onClick={() => setShowForm(true)}>Adicionar</button>

                            }

                            {(list.length > 0) &&

                                <div>

                                    <p>

                                        Legenda: P = produzido, C = comprado, F = fracionado

                                    </p>

                                    <table>

                                        <tbody>

                                            <tr>
                                                <th>Nome</th>
                                                <th>Rendimento</th>
                                                <th>Tipo</th>

                                                {hasPermissionToEdit &&

                                                    <th>&nbsp;</th>

                                                }

                                                {hasPermissionToEdit &&
                                                    <th style={{ borderRightColor: "black" }}>&nbsp;</th>

                                                }

                                            </tr>

                                            {list.map((component, index) => {

                                                return (

                                                    <tr key={index}>

                                                        <td style={{ padding: "5px" }}>

                                                            <a href={component.link}>{component.material.name}</a>

                                                        </td>

                                                        <td style={{ textAlign: "right", padding: "5px" }}>

                                                            {component.quantity}

                                                        </td>

                                                        <td style={{ textAlign: "center", padding: "5px" }}>

                                                            {component.material.producedTxt}

                                                        </td>

                                                        {hasPermissionToEdit &&

                                                            <td style={{ padding: "5px" }}>

                                                                <button type="button" className="buttonLink" onClick={() => editComponent(index)}>Editar</button>

                                                            </td>

                                                        }

                                                        {hasPermissionToEdit &&

                                                            <td style={{ padding: "5px" }}>

                                                                <button type="button" className="buttonLink" onClick={() => removeComponent(index)}>Remover</button>

                                                            </td>

                                                        }

                                                    </tr>

                                                )

                                            })}

                                        </tbody>

                                    </table >

                                </div>

                            }

                        </div>

                        {hasPermissionToEdit &&

                            <br />

                        }

                        {hasPermissionToEdit &&

                            <div className="content" style={{ margin: "auto" }}>

                                <input type="checkbox" checked={confirmSave} onChange={e => setConfirmSave(e.target.checked)} />

                                Confirmo Salvar

                                <button type="button" onClick={handleSaveSubmit} style={{ float: "right" }} disabled={!confirmSave}>Salvar</button>

                            </div>

                        }

                    </div>

                }

                {
                    showForm &&

                    <div className="content">

                        <ComponentPicker

                            addComponent={addComponent}
                            businessToken={material.business.accountToken}
                            excludeList={excludeList}
                            cancel={() => { setShowForm(false); }}

                        />

                    </div>

                }

                <br />

                <Footer />

            </div >

        );

    }

    if (op === REDIRECT_PROFILE) {

        return (

            <Navigate to={

                buildMaterialLinkByName(

                    businessQualifiedName +
                    '/' + materialName

                )

            } />
        )

    }

    if (op === SHOW_FORM) {

        return showQuantityForm();

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(SHOW_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Quantidade deve ser um inteiro positivo</p>

                </Alert>

            </div>
        )

    }

    if (op === NO_BASEMATERIAL) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(FRONTPAGE) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Um material a ser fracionado deve ser escolhido</p>

                </Alert>

            </div>
        )

    }

    if (op === ORDER_ISSUED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(FRONTPAGE) }}>

                    <p style={{ color: 'black' }}>Ação inválida</p>
                    <p style={{ color: 'black' }}>Proibido mudar material base de produto com Ordem de Produção Emitida</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

export default Component;
