
import { useState } from 'react';

import Header from '../../../../../../components/page/Header/Component';
import Footer from '../../../../../../components/page/Footer/Component';
import Title from '../../../../../../components/page/Title/Component';

import Business from '../../../../../../components/Business/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../../lib/network';
import isValidName, { INVALID_DATA } from '../../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_MENU } from '../../../../../../App';


import { materialSearch } from '../../../../../../lib/api/mrp/material/api';
import { buildBusinessLink, buildBusinessQualifiedName, buildMaterialLink, buildMaterialLinkByToken } from '../../../../../../lib/util';
import { businessDetailByAccountToken } from '../../../../../../lib/api/accounts/business/api';
import formatDateTime from '../../../../../../lib/format';

const ACCOUNT_CREATED = 'ACCOUNT_CREATED';
const EMAIL_ALREADYREGISTERED = 'EMAIL_ALREADYREGISTERED';
const RESENDEMAIL_FORM = 'RESEND_FORM';
const INVALID_RESENDEMAIL = 'INVALID_RESENDEMAIL';
const EMAIL_ALREADYCONFIRMED = 'EMAIL_ALREADYCONFIRMED';
const RESENDEMAIL_MSG = 'RESENDEMAIL_MSG';

const PROFILEPICFORM = 'PROFILEPICFORM';
const INVALID_PROFILEPIC = 'INVALID_PROFILEPIC';

const PROFILETEXTFORM = 'PROFILETEXTFORM';
const INVALID_PROFILETEXT = 'INVALID_PROFILETEXT';

const PERSONNAMEFORM = 'PERSONNAMEFORM';
const INVALID_PERSONNAME = 'INVALID_PERSONNAME';

const Component = () => {

    const [list, setList] = useState([]);
    const [isQuery, setIsQuery] = useState(false);
    const [materialName, setMaterialName] = useState('');

    const [op, setOp] = useState(null);

    let { accountToken } = useParams();

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        materialSearch(

            '?accountToken=' + accountToken +
            ((materialName === null) ? '' : '&materialName=' + materialName),

            (status, body, headers) => {

                if (status === 200) {

                    setIsQuery(true);

                    if (body.length === 0) {

                        setList(body);

                        setOp(FRONTPAGE);

                        return;

                    }

                    let accountTokenList = [];

                    body.map(material => {

                        accountTokenList[accountTokenList.length] = material.accountToken;

                        switch (

                        material.produced

                        ) {

                            case 'T': material.type = 'P';
                                break;

                            case 'F': material.type = 'C';
                                break;

                            case 'S': material.type = 'F';
                                break;

                            default: console.log('invalid material type in Material Search');

                        }

                    })

                    businessDetailByAccountToken(

                        accountTokenList,

                        (status1, body1, headers1) => {

                            if (status1 === 200) {

                                body1.map(business => {

                                    business.businessQualifiedName = buildBusinessQualifiedName(business);

                                    business.link = buildBusinessLink(business);

                                    body.map(material => {

                                        if (business.account.token === material.accountToken) {

                                            material.business = business;

                                            material.link = buildMaterialLink(

                                                business,
                                                material

                                            );

                                        }

                                    });

                                })

                                setList(body);

                                setOp(FRONTPAGE);

                            } else if (status1 === 400) {

                                let m = body1.messages;

                                for (let i = 0; i < m.length; i++) {

                                    /*  if (m[i] === EMAIL_TOKENNOTFOUND) {
            
                                         setOp(EMAIL_TOKENNOTFOUND);
            
                                         break;
            
                                     } */

                                }

                            } else {

                                setOp(INTERNAL_ERROR);

                            }

                        }

                    );

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        /*  if (m[i] === EMAIL_TOKENNOTFOUND) {

                             setOp(EMAIL_TOKENNOTFOUND);

                             break;

                         } */

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleMaterialNameChange = (e) => {

        setMaterialName(e.target.value);

    }

    const frontPage = () => {

        return (

            <div>

                <Header />

                <Title title="Procurar Materiais" />

                <Business accountToken={accountToken} />

                <br />

                <div className="content">

                    <form onSubmit={handleSubmit}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Nome</th>
                                    <td>

                                        <input type="text" value={materialName} onChange={handleMaterialNameChange} />

                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </form>

                    {(list.length > 0) &&

                        <div>

                            {(isQuery && list.length > 0) &&

                                <p>

                                    Legenda: P = produzido, C = comprado, F = fracionado

                                </p>

                            }

                            <table>

                                <tbody>

                                    <tr>
                                        <th>Nome</th>
                                        <th>Tipo</th>
                                        <th style={{ borderRightColor: "black" }}>Criado</th>
                                    </tr>

                                    {list.map((material, index) => {

                                        return (

                                            <tr key={index}>

                                                <td style={{ padding: "5px" }}>

                                                    <a href={material.link}>{material.materialName}</a>

                                                </td>

                                                <td style={{ textAlign: "center", padding: "5px" }}>

                                                    {material.type}

                                                </td>

                                                <td style={{ textAlign: "center", padding: "5px" }}>

                                                    {formatDateTime(material.created)}

                                                </td>

                                            </tr>

                                        )

                                    })}

                                </tbody>

                            </table >

                        </div>

                    }

                    {(isQuery && list.length === 0) &&

                        <div>

                            <br />

                            Sem resultados.

                        </div>

                    }

                </div>

                <br />

                <Footer />

            </div>

        );

    }

    if (op === INVALID_PERSONNAME) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(PERSONNAMEFORM) }}>

                    <p style={{ color: 'black' }}>Nome inválido</p>
                    <p style={{ color: 'black' }}>Use 10 caracteres no mínimo</p>

                </Alert>

            </div>
        )

    }

    if (op === INVALID_PROFILETEXT) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(PROFILETEXTFORM) }}>

                    <p style={{ color: 'black' }}>Texto inválido</p>
                    <p style={{ color: 'black' }}>Use 10 caracteres no mínimo</p>

                </Alert>

            </div>
        )

    }

    if (op === INVALID_PROFILEPIC) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(PROFILEPICFORM) }}>

                    <p style={{ color: 'black' }}>Url inválida</p>
                    <p style={{ color: 'black' }}>Coloque uma url válida do google drive para uma imagem</p>

                </Alert>

            </div>
        )

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Nomes e email</p>

                </Alert>

            </div>
        )

    }

    if (op === ACCOUNT_CREATED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="success" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Conta criada</p>
                    <p style={{ color: 'black' }}>Um email de confirmação foi enviado para você</p>

                </Alert>

            </div >
        )

    }

    if (op === EMAIL_ALREADYREGISTERED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Email já cadastrado</p>

                </Alert>

            </div >
        )

    }

    if (op === INVALID_RESENDEMAIL) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Email</p>

                </Alert>

            </div>
        )

    }

    if (op === RESENDEMAIL_MSG) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="success" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email enviado</p>
                    <p style={{ color: 'black' }}>Use o link no email para confirmar sua conta.</p>

                </Alert>

            </div>
        )

    }

    if (op === EMAIL_ALREADYCONFIRMED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email já confirmado</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
