import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/public/Home/Component';
import EmailConfirm from './pages/public/EmailConfirm/Component';
import LoginEmail from './pages/public/Home/LoginEmail/Component';
import EmailLogin from './pages/public/EmailLogin/Component';
import Menu from './pages/private/Menu/Component';

import PersonProfile from './pages/private/Person/Profile/Component';
import PersonSearch from './pages/private/Person/Search/Main';

import PersonBusinessProfile from './pages/private/Person/Business/Profile/Main';
import PersonBusinessSearch from './pages/private/Person/Business/Search/Main';

import BusinessMaterialSearch from './pages/private/Person/Business/Material/Search/Main';
import BusinessMaterialCreate from './pages/private/Person/Business/Material/Create/Main';

import BatchSearch from './pages/private/Person/Batch/Search/Main';
import BatchCreate from './pages/private/Person/Batch/Create/Main';
import BatchProfile from './pages/private/Person/Batch/Profile/Main';

import BusinessOutputSearch from './pages/private/Person/Business/Output/Search/Main';
import BusinessInputSearch from './pages/private/Person/Business/Input/Search/Main';

import BusinessBrokenSearch from './pages/private/Person/Business/Broken/Search/Main';

import BusinessOrderSearch from './pages/private/Person/Business/Order/Search/Main';

import MaterialOutputSearch from './pages/private/Person/Material/Output/Search/Main';
import MaterialOutputCreate from './pages/private/Person/Material/Output/Create/Main';
import MaterialOutputProfile from './pages/private/Person/Material/Output/Profile/Main';

import MaterialInputSearch from './pages/private/Person/Material/Input/Search/Main';
import MaterialInputCreate from './pages/private/Person/Material/Input/Create/Main';
import MaterialInputProfile from './pages/private/Person/Material/Input/Profile/Main';

import MaterialOrderSearch from './pages/private/Person/Material/Order/Search/Main';
import MaterialOrderCreate from './pages/private/Person/Material/Order/Create/Main';
import MaterialOrderProfile from './pages/private/Person/Material/Order/Profile/Main';

import StructureProfile from './pages/private/Person/Structure/Profile/Main';
import FractionProfile from './pages/private/Person/Fraction/Profile/Main';

import MaterialSearch from './pages/private/Person/Material/Search/Main';
import MaterialProfile from './pages/private/Person/Material/Profile/Main';

import BusinessProfile from './pages/private/Business/Profile/Component';
import BusinessPermission from './pages/private/Business/Permission/Main';
import BusinessPersonSearch from './pages/private/Business/Person/Search/Main';

export const REDIRECT_HOME = 'REDIRECT_HOME';
export const REDIRECT_MENU = 'REDIRECT_MENU';
export const REDIRECT_PROFILE = 'REDIRECT_PROFILE';

export const FRONTPAGE = 'FRONTPAGE';
export const MAINPAGE = 'MAINPAGE';
export const BACKPAGE = 'BACKPAGE';

export const SHOW_FORM = 'SHOW_FORM';

export const MENU_PATH = '/pages/private/menu';

const App = () => {

  return (

    <BrowserRouter>

      <Routes>

        <Route element={<Home />} path="/" exact />
        <Route element={<EmailConfirm />} path="/pages/public/emailconfirm/:emailToken" />
        <Route element={<LoginEmail />} path="/pages/public/home/loginemail" />
        <Route element={<Home />} path="/pages/public/home" />
        <Route element={<EmailLogin />} path="/pages/public/emaillogin/:emailToken" />
        <Route element={<Menu />} path="/pages/private/menu" />

        <Route element={<PersonSearch />} path="/pages/private/person/search" />
        <Route element={<PersonProfile />} path="/pages/private/person/profile/:personQualifiedName" />

        <Route element={<PersonBusinessSearch />} path="/pages/private/person/business/search" />
        <Route element={<PersonBusinessProfile />} path="/pages/private/person/business/profile/:businessQualifiedName" />

        <Route element={<BusinessMaterialSearch />} path="/pages/private/person/business/material/search/:accountToken" />
        <Route element={<BusinessMaterialCreate />} path="/pages/private/person/business/material/create/:accountToken" />

        <Route element={<BatchSearch />} path="/pages/private/person/batch/search/:businessQualifiedName/:materialName/:materialToken" />
        <Route element={<BatchCreate />} path="/pages/private/person/batch/create/:businessQualifiedName/:materialName/:materialToken" />
        <Route element={<BatchProfile />} path="/pages/private/person/batch/profile/:businessQualifiedName/:materialName/:batchToken" />

        <Route element={<BusinessOutputSearch />} path="/pages/private/person/business/output/search/:businessQualifiedName/:accountToken" />
        <Route element={<BusinessInputSearch />} path="/pages/private/person/business/input/search/:businessQualifiedName/:accountToken" />

        <Route element={<BusinessBrokenSearch />} path="/pages/private/person/business/broken/search/:businessQualifiedName/:token" />

        <Route element={<BusinessOrderSearch />} path="/pages/private/person/business/order/search/:businessQualifiedName/:token" />

        <Route element={<MaterialOutputSearch />} path="/pages/private/person/material/output/search/:businessQualifiedName/:materialName/:materialToken" />
        <Route element={<MaterialOutputCreate />} path="/pages/private/person/material/output/create/:businessQualifiedName/:materialName/:materialToken" />
        <Route element={<MaterialOutputProfile />} path="/pages/private/person/material/output/profile/:businessQualifiedName/:materialName/:outputToken" />

        <Route element={<MaterialInputSearch />} path="/pages/private/person/material/input/search/:businessQualifiedName/:materialName/:materialToken" />
        <Route element={<MaterialInputCreate />} path="/pages/private/person/material/input/create/:businessQualifiedName/:materialName/:materialToken" />
        <Route element={<MaterialInputProfile />} path="/pages/private/person/material/input/profile/:businessQualifiedName/:materialName/:token" />

        <Route element={<MaterialOrderSearch />} path="/pages/private/person/material/order/search/:businessQualifiedName/:materialName/:materialToken" />
        <Route element={<MaterialOrderCreate />} path="/pages/private/person/material/order/create/:businessQualifiedName/:materialName/:token" />
        <Route element={<MaterialOrderProfile />} path="/pages/private/person/material/order/profile/:businessQualifiedName/:materialName/:token" />

        <Route element={<StructureProfile />} path="/pages/private/person/structure/profile/:businessQualifiedName/:materialName/:token" />
        <Route element={<FractionProfile />} path="/pages/private/person/fraction/profile/:businessQualifiedName/:materialName/:token" />

        <Route element={<MaterialSearch />} path="/pages/private/person/material/search" />
        <Route element={<MaterialProfile />} path="/pages/private/person/material/profile/:businessQualifiedName/:businessMaterialName" />

        <Route element={<BusinessProfile />} path="/pages/private/business/profile/:businessQualifiedName" />
        <Route element={<BusinessPermission />} path="/pages/private/business/permission/:businessQualifiedName" />
        <Route element={<BusinessPersonSearch />} path="/pages/private/business/person/search/:businessQualifiedName" />

        <Route element={<Menu />} path="*" />

      </Routes>

    </BrowserRouter>

  );

}

export default App;