import { useLocation } from "react-router-dom";
import { businessSearch } from "./api/accounts/business/api";
import { getPersonQualifiedName } from "./session";

const cloneObject = (obj) => {

    return JSON.parse(JSON.stringify(obj));

}

const sleep = async (time = 3000) => {

    await new Promise(resolve => setTimeout(resolve, time));

}

export const useQuery = () => {

    return new URLSearchParams(useLocation().search);

}

export const buildPersonQualifiedName = (person) => {

    return (

        person.firstName.toLowerCase() + '.' +
        person.lastName.toLowerCase() + '.' +
        person.counter

    )

}

export const buildBusinessQualifiedName = (business) => {

    return (

        business.businessName.toLowerCase() + '.' +
        business.counter

    )

}

export const buildMaterialQualifiedName = (

    business,
    material

) => {

    return (

        buildBusinessQualifiedName(

            business

        ) +

        '/' + material.materialName.toLowerCase()

    )

}

export const buildPersonLink = (

    person,

    complete = false

) => {

    if (complete) {

        return (

            window.location.protocol +

            '//' + window.location.host +

            '/pages/private/person/profile/' + buildPersonQualifiedName(

                person

            )

        );

    }

    return (

        '/pages/private/person/profile/' + buildPersonQualifiedName(

            person

        )

    );

}

export const buildBusinessLink = (

    business,
    complete = false

) => {

    return (

        window.location.protocol +

        '//' + window.location.host +

        '/pages/private/person/business/profile' +

        '/' + buildBusinessQualifiedName(

            business

        )

    );

}

export const buildMaterialLinkByToken = (

    accountToken,
    materialToken

) => {

    return (

        window.location.protocol +

        '//' + window.location.host +

        '/pages/private/person/material/profile' +

        '/' + accountToken +

        '/' + materialToken

    );

}

export const buildMaterialLink = (

    business,
    material

) => {

    return (

        window.location.protocol +

        '//' + window.location.host +

        '/pages/private/person/material/profile' +

        '/' + buildMaterialQualifiedName(

            business,
            material

        )

    );

}

export const buildMaterialLinkByName = (

    materialQualifiedName,
    complete = false

) => {

    if (complete) {

        return (

            window.location.protocol +

            '//' + window.location.host +

            '/pages/private/person/material/profile' +

            '/' + materialQualifiedName

        );

    }

    return (

        '/pages/private/person/material/profile' +

        '/' + materialQualifiedName.toLowerCase()

    );

}

export const buildBatchLinkByToken = (

    batchToken,
    complete = false

) => {

    if (complete) {

        return (

            window.location.protocol +

            '//' + window.location.host +

            '/pages/private/person/batch/profile' +

            '/' + batchToken

        );

    }

    return (

        '/pages/private/person/batch/profile' +

        '/' + batchToken

    );

}

export const buildBatchLinkByQualifiedName = (

    batchQualifiedName,
    complete = false

) => {

    if (complete) {

        return (

            window.location.protocol +

            '//' + window.location.host +

            '/pages/private/person/batch/profile' +

            '/' + batchQualifiedName

        );

    }

    return (

        '/pages/private/person/batch/profile' +

        '/' + batchQualifiedName

    );

}

export const buildOutputLinkByQualifiedName = (

    outputQualifiedName,
    complete = false

) => {

    let link = '/pages/private/person/material/output/profile' +

        '/' + outputQualifiedName.toLowerCase();

    if (complete) {

        return (

            window.location.protocol +

            '//' + window.location.host +

            link

        );

    }

    return link;

}

export const buildInputLinkByQualifiedName = (

    inputQualifiedName,
    complete = false

) => {

    let link = '/pages/private/person/material/input/profile' +

        '/' + inputQualifiedName.toLowerCase();

    if (complete) {

        return (

            window.location.protocol +

            '//' + window.location.host +

            link

        );

    }

    return link;

}

export const buildOrderLinkByQualifiedName = (

    orderQualifiedName,
    complete = false

) => {

    let link = '/pages/private/person/material/order/profile' +

        '/' + orderQualifiedName.toLowerCase();

    if (complete) {

        return (

            window.location.protocol +

            '//' + window.location.host +

            link

        );

    }

    return link;

}

export const setHasPermission = (

    personPermissionList,
    businessPermissionList,
    setHasPermissionToEdit

) => {

    let pqn = getPersonQualifiedName();

    for (

        let i = 0;
        i < businessPermissionList.length;
        i++) {

        let p = businessPermissionList[i];

        if (pqn === p.personQualifiedName) {

            setHasPermissionToEdit(

                personPermissionList.includes(p.code)

            );

            break;

        }

    };

}

export default cloneObject;