export const INVALID_DATA = 'INVALID_DATA';

const isValidName = (name) => {

    return (

        name
            .trim()
            .match(/^[a-z|0-9| |,|\.|-]{3,100}$/i) != null

    );

}

export const isValidPersonName = (name) => {

    return (

        name
            .trim()
            .match(/^[a-z|0-9]{3,100}$/i) != null

    );

}

export const isValidBusinessName = (name) => {

    return (

        name
            .trim()
            .match(/^[a-z|0-9]{3,100}$/i) != null

    );

}

export const isValidConfirmCode = (

    txt // validation code

) => {

    return (

        txt
            .trim()
            .match(/^[a-z|0-9]{12}$/i) != null

    );

}

export const isValidLoginCode = (

    txt // login code

) => {

    return (

        txt
            .trim()
            .match(/^[a-z|0-9]{12}$/i) != null

    );

}

export const isValidEmail = (email) => {

    return (

        email
            .trim()
            .match(/^[^@]+@[^@]+$/i) != null

    );

}

export default isValidName;