import React, { useState } from 'react';

import Header from '../../../components/page/Header/Component';
import Footer from '../../../components/page/Footer/Component';
import Title from '../../../components/page/Title/Component';

import Person from './Person/Component';
import Business from './Business/Component';

import Presentation from '../../public/Home/Presentation/Component';

import { isBusiness, isPerson, removeSessionData, removeSessionId } from '../../../lib/session';
import { Navigate } from 'react-router-dom';
import { REDIRECT_HOME } from '../../../App';
import { REDIRECT_MENU } from '../../../App';

const Component = () => {

    const [op, setOp] = useState(null);

    const logout = () => {

        removeSessionId();

        removeSessionData();

        // setOp(REDIRECT_MENU);

        setOp(REDIRECT_HOME);

    }

    const home = () => {

        setOp(REDIRECT_HOME);

    }

    const frontPage = () => {

        return (

            <div>

                <Header showLink={false} />

                {isPerson() && <Title title="Menu da Pessoa" />}

                {isBusiness() && <Title title="Menu da Empresa" />}

                <div className="content">

                    {isPerson() && <Person />}

                    {isBusiness() && <Business />}

                    {<button type="button" className="buttonLink" onClick={logout}>Sair</button>}

                </div>

                <Title title="Sobre o ProdCalc" />

                <Presentation />

                <br />

                <Footer showLink={false} />

            </div>

        );

    }

    // if (!hasSessionId()) {

    //     return (<Navigate to="/" />);

    // }

    if (op === REDIRECT_MENU) {

        return (<Navigate to="/" />);

    }

    if (op === REDIRECT_HOME) {

        return (<Navigate to="/pages/public/home" />);

    }

    return frontPage();

}

export default Component;