import { useState } from 'react';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../lib/network';
import { INVALID_DATA } from '../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

import { Navigate } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../../../App';
import { rMaterialSearch } from '../../../../../lib/api/mrp/material/api';

//import Viewer from '../../../../components/PageEditor/Main';

const Component = (props) => {

    const [list, setList] = useState([]);
    const [isQuery, setIsQuery] = useState(false);
    const [materialName, setMaterialName] = useState('');

    const [op, setOp] = useState('');

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        let queryString =

            '?' + 'materialName=' + materialName +
            '&' + 'accountToken=' + props.businessToken

        let response = await rMaterialSearch(

            queryString

        );

        switch (response.status) {

            case 200:

                let localList = response.body;

                let excludeList = props.excludeList;

                localList = localList.filter(element => {

                    return (excludeList.find(element1 => {

                        return (element1.toLowerCase() === element.materialName.toLowerCase());

                    }) === undefined);

                });

                localList.map(material => {

                    switch (

                    material.produced

                    ) {

                        case 'T':

                            material.producedTxt = 'P';

                            break;

                        case 'F':

                            material.producedTxt = 'C';

                            break;

                        case 'S':

                            material.producedTxt = 'F';

                            break;

                        default: console.log('invalid material type in Material Fraction Picker');

                    }

                });

                setIsQuery(true);

                setList(localList)

                setOp(FRONTPAGE);

                break;

            case 400:

                console.log(response.body);

            default: setOp(INTERNAL_ERROR);

        }

    }

    const handleChange = (e) => {

        setMaterialName(e.target.value);

    }

    const frontPage = () => {

        return (

            <div>

                <h3>Procurar Materiais</h3>

                <form onSubmit={handleSubmit}>

                    <table>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Nome</th>
                                <td>

                                    <input type="text" value={materialName} onChange={handleChange} />

                                </td>
                            </tr>

                            <tr>
                                <td colSpan="2">

                                    <span><button type="button" onClick={props.cancel}>Cancelar</button></span>

                                    <span style={{ float: "right" }}><input type="submit" value="Enviar" /></span>

                                </td>
                            </tr>

                        </tbody>

                    </table>

                </form>

                {(list.length > 0) &&

                    <div>

                        <p>

                            Legenda: P = produzido, C = comprado, F = fracionado

                        </p>

                        <table>

                            <tbody>

                                <tr>
                                    <th>Nome</th>
                                    <th style={{ borderRightColor: "black" }}>Tipo</th>
                                </tr>

                                {list.map((material, index) => {

                                    return (

                                        <tr key={index}>

                                            <td style={{ padding: "5px" }}>

                                                <button type="button" className="buttonLink"

                                                    onClick={() => props.addComponent(material)}>{material.materialName}</button>

                                            </td>

                                            <td style={{ padding: "5px", textAlign: "center" }}>

                                                {material.producedTxt}

                                            </td>

                                        </tr>

                                    )

                                })}

                            </tbody>

                        </table >

                    </div>

                }

                {(isQuery && list.length === 0) &&

                    <div>

                        <br />

                        Sem resultados.

                    </div>

                }

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Nomes e email</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
