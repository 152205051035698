import { mrpHost } from '../../../../config/api';
import { post, get, rget, rpost } from '../../../rest';

export const OUTPUT_ALREADYCREATED = 'OUTPUT_ALREADYCREATED';
export const OUTPUT_CREATED = 'OUTPUT_CREATED';
export const OUTPUT_NOTFOUND = 'OUTPUT_NOTFOUND';
export const OUTPUT_INVALIDQUANTITY = 'OUTPUT_INVALIDQUANTITY';
export const OUTPUT_INVALIDDUEDATE = 'OUTPUT_INVALIDDUEDATE';
export const OUTPUT_ZEROQUANTITY = 'OUTPUT_ZEROQUANTITY';

export const updateOutput = async (

    quantity,
    outputToken

) => {

    return await rpost(

        mrpHost + '/output/updateOutput',

        {},

        {

            quantity: quantity,
            outputToken: outputToken

        }

    )
}

export const updateOutputDuedate = async (

    dueDate,
    outputToken

) => {

    return await rpost(

        mrpHost + '/output/updateOutputDuedate',

        {},

        {

            dueDate: dueDate,
            outputToken: outputToken

        }

    )
}

const createOutput = async (

    quantity,
    dueDate,
    materialToken


) => {

    return await rpost(

        mrpHost + '/output/createOutput',

        {},

        {

            quantity: quantity,
            dueDate: dueDate,
            materialToken: materialToken

        }

    )
}

export const outputProfileByOutputCode = (

    accountToken,
    materialToken,
    callBack

) => {

    get(

        mrpHost + '/material/materialProfileByToken' +
        '/' + accountToken +
        '/' + materialToken,
        {},
        callBack

    )
}

export const outputSearchByAccountToken = async (

    queryString

) => {

    return await rget(

        mrpHost + '/output/outputSearchByAccountToken' + queryString,
        {}

    );
}

export const outputSearchByMaterialToken = async (

    queryString

) => {

    return await rget(

        mrpHost + '/output/outputSearchByMaterialToken' + queryString,
        {}

    );
}

export const outputProfileByToken = async (

    outputToken

) => {

    return await rget(

        mrpHost + '/output/outputProfileByToken' +

        '/' + outputToken,

        {}

    )
}

export default createOutput;