// const accountsHost = 'http://local-accounts.prodcalc.com'
// export const publishingHost = 'http://local-publishing.prodcalc.com'
// export const mrpHost = 'http://local-mrp.prodcalc.com'

// const accountsHost = 'https://tst-accounts.prodcalc.com'
// export const publishingHost = 'https://tst-publishing.prodcalc.com'
// export const mrpHost = 'https://tst-mrp.prodcalc.com'

const accountsHost = 'https://prd-accounts.prodcalc.com'
export const publishingHost = 'https://prd-publishing.prodcalc.com'
export const mrpHost = 'https://prd-mrp.prodcalc.com'

export default accountsHost;