export const MATERIAL_INVALIDNAME = 'MATERIAL_INVALIDNAME';
export const MATERIAL_INVALIDYIELD = 'MATERIAL_INVALIDYIELD';

const isMaterialNameValid = (data) => {

    return (data.trim().match(/^.{10,100}$/i) != null);

}

export const isMaterialProfileTextValid = (data) => {

    return (data.trim().match(/^.{10,45}$/i) != null);

}

export default isMaterialNameValid;