export const BusinessMenu = {

	main: "https://blog.prodcalc.com/mrp-pcp-software-producao-estoque-sistema-ajuda/"

}

export const PersonMenu = {

	main: "https://blog.prodcalc.com/mrp-pcp-software-producao-estoque-sistema-ajuda/"

}

export const PersonProfile = {

	main: "https://blog.prodcalc.com/mrp-pcp-software-producao-estoque-sistema-perfil-da-pessoa/"

}

export const BusinessProfile = {

	main: "https://blog.prodcalc.com/mrp-pcp-software-producao-estoque-sistema-perfil-da-empresa/"

}

export const MaterialProfile = {

	main: "https://blog.prodcalc.com/mrp-pcp-software-producao-estoque-sistema-perfil-do-material/"

}

export const ProductionProfile = {

	main: "https://blog.prodcalc.com/mrp-pcp-software-producao-estoque-sistema-producao/"

}

export const Presentation = {

	main: "https://blog.prodcalc.com/mrp-pcp-software-producao-estoque-sistema-sobre-page/"

}

export const CreateMaterial = {

	main: "https://blog.prodcalc.com/mrp-pcp-software-producao-estoque-sistema-sobre-page/"

}

export const FractionProfile = {

	main: "https://blog.prodcalc.com/mrp-pcp-software-producao-estoque-sistema-rendimento-do-material/"

}

export default PersonProfile;