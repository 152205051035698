import React, { useRef } from 'react';

import { Editor } from '@tinymce/tinymce-react';

const Component = (props) => {

    const editorRef = useRef(null);

    const frontPage = () => {

        const log = () => {

            if (editorRef.current) {

                console.log(editorRef.current.getContent());

            }

        };

        const save = () => {

            props.save(

                editorRef.current.getContent()

            );

        }

        return (

            <div className="content">

                <div>

                    <button type="button" onClick={props.cancel}>Cancel</button>

                    <button type="button" onClick={save} style={{ float: "right" }}>Save</button>

                    <br />
                    <br />

                </div>

                <Editor

                    apiKey='cz4d3kwttxyzfll9r03ilov667cjhjydvlt45egyyfozvjhf'

                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={props.page.content}

                    init={{

                        height: 500,
                        menubar: false,

                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code wordcount media image link'
                        ],

                        toolbar: 'undo redo | formatselect |' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist | ' +
                            'removeformat | link image media',

                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'

                    }}
                />

            </div >

        );

    };

    return frontPage();

}

Component.defaultProps = {

    showLink: true

}

export default Component;
