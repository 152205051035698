import { useState } from 'react';

import Header from '../../../../../components/page/Header/Component';
import Footer from '../../../../../components/page/Footer/Component';
import Title from '../../../../../components/page/Title/Component';

import Material from '../../../../../components/Material/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../lib/network';
import { INVALID_DATA } from '../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

import { FRONTPAGE, REDIRECT_MENU } from '../../../../../App';

import { Navigate, useParams } from 'react-router-dom';
import { batchSearch } from '../../../../../lib/api/mrp/batch/api';
import { formatDate } from '../../../../../lib/format';
import { buildBatchLinkByQualifiedName } from '../../../../../lib/util';

const PROFILETEXTFORM = 'PROFILETEXTFORM';
const INVALID_PROFILETEXT = 'INVALID_PROFILETEXT';

const PERSONNAMEFORM = 'PERSONNAMEFORM';
const INVALID_PERSONNAME = 'INVALID_PERSONNAME';

const Component = () => {

    let { businessQualifiedName, materialName, materialToken } = useParams();

    const [list, setList] = useState([]);
    const [isQuery, setIsQuery] = useState(false);
    const [batchCode, setBatchCode] = useState('');
    const [material, setMaterial] = useState(null);
    const [business, setBusiness] = useState(null);

    const [op, setOp] = useState(null);

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        batchSearch(

            (batchCode === null) ?
                '' :
                '?batchCode=' + batchCode +
                '&materialToken=' + materialToken,

            (status, body, headers) => {

                if (status === 200) {

                    setIsQuery(true);

                    let batchList = body;

                    if (batchList.length === 0) {

                        setList(batchList);

                        setOp(FRONTPAGE);

                        return;

                    }

                    batchList.map((batch) => {

                        batch.link = buildBatchLinkByQualifiedName(

                            businessQualifiedName +
                            '/' + materialName +
                            '/' + batch.token

                        );

                    });

                    setList(batchList);

                    setOp(FRONTPAGE);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        /*  if (m[i] === EMAIL_TOKENNOTFOUND) {

                             setOp(EMAIL_TOKENNOTFOUND);

                             break;

                         } */

                        console.log(m[i]);

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleBatchCodeChange = (e) => {

        setBatchCode(e.target.value);

    }

    const frontPage = () => {

        return (

            <div>

                <Header />

                <Title title="Procurar Lotes" />

                <Material materialToken={materialToken} setMaterial={setMaterial} setBusiness={setBusiness} />

                <br />

                <div className="content">

                    <form onSubmit={handleSubmit}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Código do Lote</th>
                                    <td>

                                        <input type="text" value={batchCode} onChange={handleBatchCodeChange} />

                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </form>

                    {(list.length > 0) &&

                        <div>

                            <br />

                            <table>

                                <tbody>

                                    <tr>
                                        <th>Token</th>
                                        <th>Código do Lote</th>
                                        <th>Quantidade</th>
                                        <th style={{ borderRightColor: "black" }}>Validade</th>
                                    </tr>

                                    {list.map((batch, index) => {

                                        return (

                                            <tr key={index}>

                                                <td style={{ textAlign: "center" }}>

                                                    <a href={batch.link}>

                                                        {batch.token}

                                                    </a>

                                                </td>

                                                <td style={{ textAlign: "center" }}>

                                                    {((batch.batchCode === '') || (batch.batchCode === null)) && 'Nenhum'}

                                                    {!((batch.batchCode === '') || (batch.batchCode === null)) && batch.batchCode}

                                                </td>

                                                <td style={{ textAlign: "right" }}>

                                                    {batch.quantity}

                                                </td>

                                                <td style={{ textAlign: "center" }}>

                                                    {(batch.expireDate === null) && 'Nenhum'}

                                                    {(batch.expireDate !== null) && formatDate(batch.expireDate)}

                                                </td>

                                            </tr >

                                        )

                                    })}

                                </tbody>

                            </table >

                        </div>

                    }

                    {(isQuery && list.length === 0) &&

                        <div>

                            <br />

                            Sem resultados.

                        </div>

                    }

                </div>

                <br />

                <Footer />

            </div >

        );

    }

    if (op === INVALID_PERSONNAME) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(PERSONNAMEFORM) }}>

                    <p style={{ color: 'black' }}>Nome inválido</p>
                    <p style={{ color: 'black' }}>Use 10 caracteres no mínimo</p>

                </Alert>

            </div>
        )

    }

    if (op === INVALID_PROFILETEXT) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(PROFILETEXTFORM) }}>

                    <p style={{ color: 'black' }}>Texto inválido</p>
                    <p style={{ color: 'black' }}>Use 10 caracteres no mínimo</p>

                </Alert>

            </div>
        )

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Nomes e email</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
