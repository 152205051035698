import { format, parse } from "date-fns";

export const formatDate = (

    date

) => {

    return format(

        parse(

            date, 'yyyy-MM-dd', new Date()

        )

        , 'dd/MM/yyyy'

    );

}

export const parseDateTime = (

    date

) => {

    return parse(

        date, 'yyyy-MM-dd HH:mm:ss', new Date()

    );

}

export const parseDate = (

    date

) => {

    return parse(

        date, 'yyyy-MM-dd', new Date()

    );

}

const formatDateTime = (

    date

) => {

    return format(

        parse(

            date, 'yyyy-MM-dd HH:mm:ss', new Date()

        )

        , 'dd/MM/yyyy HH:mm:ss'

    );

}

export default formatDateTime;