import { useEffect, useState } from 'react';

import Header from '../../../../../../components/page/Header/Component';
import Footer from '../../../../../../components/page/Footer/Component';
import Title from '../../../../../../components/page/Title/Component';

import Material from '../../../../../../components/Material/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../../lib/network';
import { INVALID_DATA } from '../../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../../lib/rest';
import { CircularProgress, TextField } from '@mui/material';

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_PROFILE } from '../../../../../../App';
import isOrderQuantityValid, { isYieldValid } from '../../../../../../lib/api/mrp/order/validation';
import createOrder from '../../../../../../lib/api/mrp/order/api';

import { buildMaterialLinkByName, buildOrderLinkByQualifiedName } from '../../../../../../lib/util';
import { ProductionProfile } from '../../../../../../config/help';
import { fractionProfileByToken } from '../../../../../../lib/api/mrp/structure/api';
import { materialProfileByToken, rMaterialProfileByToken } from '../../../../../../lib/api/mrp/material/api';

const ORDER_FORM = 'ORDER_FORM';
const INVALID_YIELD = 'INVALID_YIELD';

const Component = () => {

    let {

        businessQualifiedName,
        materialName,
        token // material token

    } = useParams();

    const [startDate, setStartDate] = useState(new Date());
    const [finishDate, setFinishDate] = useState(new Date());
    const [quantity, setQuantity] = useState('');
    const [createdToken, setCreatedToken] = useState(null);

    const [list, setList] = useState([]);   // base material
    const [excludeList, setExcludeList] = useState([]);
    const [isFraction, setIsFraction] = useState(false);

    const [op, setOp] = useState(null);

    useEffect(

        () => initData(),

        []
    );

    const initData = async () => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await rMaterialProfileByToken(

            token   // material token

        );

        switch (response.status) {

            case 200:

                let material = response.body;

                if (

                    material['produced'] !== 'S'

                ) {

                    setOp(FRONTPAGE);

                    return;

                }

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

        setIsFraction(true);

        response = await fractionProfileByToken(

            token   // material token

        );

        let localList = null;
        let localExcludeList = [materialName];

        switch (response.status) {

            case 200:

                localList = response.body.list;

                localList.map(component => {

                    component.link = buildMaterialLinkByName(

                        businessQualifiedName +
                        '/' + component['material']['name']

                    );

                    switch (

                    component['material']['produced']

                    ) {

                        case 'T':

                            component['material']['producedTxt'] = 'P';

                            break;

                        case 'F':

                            component['material']['producedTxt'] = 'C';

                            break;

                        case 'S':

                            component['material']['producedTxt'] = 'F';

                            break;

                        default: console.log('invalid material type in Order Create');

                    }

                    localExcludeList.push(

                        component['material']['name']

                    );

                });

                setList(localList);

                setExcludeList(

                    localExcludeList

                );

                setOp(FRONTPAGE);

                break;

            case 400:

                console.log(response.body);

            default:

                setOp(INTERNAL_ERROR);

                return;

        }

        setOp(FRONTPAGE);

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isOrderQuantityValid(quantity)

            )

        ) {

            setOp(INVALID_DATA);

            return;

        }

        if (

            isFraction
            && !isYieldValid(

                quantity,
                list[0].quantity

            )

        ) {

            setOp(INVALID_YIELD);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        let response = await createOrder(

            quantity,
            startDate.toISOString().substring(0, 10),
            finishDate.toISOString().substring(0, 10),
            token // material token

        );

        let localOrder = null;

        switch (response.status) {

            case 200:

                localOrder = response.body;

                setCreatedToken(

                    localOrder.token

                );

                setOp(REDIRECT_PROFILE);

                break;

            case 400:

                let m = response.body.messages;

                for (

                    let i = 0;

                    i < m.length;

                    i++) {

                    if (

                        (m[i] === 'quantity')

                        || (m[i] === 'startDate')

                        || (m[i] === 'finishDate')

                    ) {

                        setOp(INVALID_DATA);

                    }

                }

                break;

            default:

                setOp(INTERNAL_ERROR);

        }

    }

    const handleChange = (e) => {

        setQuantity(e.target.value);

    }

    const handleStartDateChange = (

        pickedDate

    ) => {

        setStartDate(pickedDate);

    }

    const handleFinishDateChange = (

        pickedDate

    ) => {

        setFinishDate(pickedDate);

    }

    const frontPage = () => {

        return (

            <div>

                <Header />

                <Title title="Abrir Ordem" />

                <Material materialToken={token} />

                <br />

                <div className="content">

                    <a href={ProductionProfile.main} target={"_blank"}>Ajuda</a>

                    <br /><br />

                    <form onSubmit={handleSubmit}>

                        <table>

                            <tbody>

                                {isFraction &&

                                    <tr>
                                        <th className="verticalHeader">Rendimento</th>
                                        <td>{list[0].quantity}</td>
                                    </tr>

                                }

                                <tr>
                                    <th className="verticalHeader">Quantidade</th>
                                    <td><input type="text" value={quantity} onChange={handleChange} /></td>
                                </tr>

                                <tr>
                                    <th className="verticalHeader">Data de Aceite</th>
                                    <td>

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>

                                            <MobileDatePicker

                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) => <TextField {...params} />}
                                                onChange={handleStartDateChange}
                                                value={startDate}

                                            />

                                        </LocalizationProvider>

                                    </td>
                                </tr>

                                <tr>
                                    <th className="verticalHeader">Data de Entrega</th>
                                    <td>

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>

                                            <MobileDatePicker

                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) => <TextField {...params} />}
                                                onChange={handleFinishDateChange}
                                                value={finishDate}

                                            />

                                        </LocalizationProvider>

                                    </td>
                                </tr>

                                <tr>

                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>

                                </tr>

                            </tbody>

                        </table>

                    </form>

                </div>

                <br />

                <Footer />

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Ordem de Produção</h3>

                <Alert severity="error" onClose={() => { setOp(FRONTPAGE) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Quantidade deve ser inteiro positivo</p>
                    <p style={{ color: 'black' }}>Datas devem ser iguais ou maiores que hoje</p>
                    <p style={{ color: 'black' }}>Data de Aceite deve ser igual ou menor que a data de Entrega</p>

                </Alert>

            </div>
        )

    }

    if (op === INVALID_YIELD) {

        return (

            <div className="content">

                <h3>Ordem de Produção</h3>

                <Alert severity="error" onClose={() => { setOp(FRONTPAGE) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Quantidade deve ser um múltiplo do rendimento</p>

                </Alert>

            </div>
        )

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === REDIRECT_PROFILE) {

        return (

            <Navigate to={

                buildOrderLinkByQualifiedName(

                    businessQualifiedName +
                    '/' + materialName +
                    '/' + createdToken

                )

            } />
        )

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

export default Component;
