import { useState } from 'react';

import Header from '../../../../../components/page/Header/Component';
import Footer from '../../../../../components/page/Footer/Component';
import Title from '../../../../../components/page/Title/Component';

import Material from '../../../../../components/Material/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../lib/network';
import { INVALID_DATA } from '../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../lib/rest';
import { CircularProgress, TextField } from '@mui/material';

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { buildBatchLinkByQualifiedName } from '../../../../../lib/util';
import { isBatchQuantityValid } from '../../../../../lib/api/mrp/batch/validation';
import createBatch, { BATCH_ALREADYCREATED, BATCH_CREATED, BATCH_INVALIDQUANTITY } from '../../../../../lib/api/mrp/batch/api';

const BATCH_FORM = 'BATCH_FORM';

const Component = () => {

    let { businessQualifiedName, materialName, materialToken } = useParams();

    const [batchCode, setBatchCode] = useState('');
    const [expireDate, setExpireDate] = useState(new Date());
    const [batchQuantity, setBatchQuantity] = useState('');
    const [batch, setBatch] = useState(null);
    const [editDate, setEditDate] = useState(false);

    const [op, setOp] = useState(null);

    const handleBatchCodeChange = (e) => {

        setBatchCode(e.target.value);

    }

    const handleBatchQuantityChange = (e) => {

        setBatchQuantity(e.target.value);

    }

    const handleExpireDateChange = (pickedDate) => {

        setExpireDate(pickedDate);

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isBatchQuantityValid(batchQuantity)

            )

        ) {

            setOp(INVALID_DATA);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        createBatch(

            batchCode,
            editDate ? expireDate.toISOString().substring(0, 10) : null,
            materialToken,
            batchQuantity,

            (status, body) => {

                if (status === 200) {

                    setBatchCode(body.token);

                    setOp(BATCH_CREATED);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === 'batchQuantity') {

                            setOp(BATCH_INVALIDQUANTITY);

                            break;

                        }

                        if (m[i] === BATCH_ALREADYCREATED) {

                            setOp(BATCH_ALREADYCREATED);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const frontPage = (props) => {

        return (

            <div>

                <Header />

                <Title title="Cadastrar Lote" />

                <Material materialToken={materialToken} />

                <br />

                <div className="content">

                    <form onSubmit={handleSubmit}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Código do Lote</th>
                                    <td><input type="text" name="batchCode" value={batchCode} onChange={handleBatchCodeChange} /></td>
                                </tr>

                                <tr>
                                    <th className="verticalHeader">Quantidade</th>
                                    <td><input type="text" name="batchQuantity" value={batchQuantity} onChange={handleBatchQuantityChange} /></td>
                                </tr>

                                <tr>
                                    <th className="verticalHeader">Data de Expiração <input type="checkbox" checked={editDate} onChange={() => setEditDate(editDate ? false : true)} /></th>
                                    <td>

                                        {editDate &&

                                            <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                <MobileDatePicker

                                                    inputFormat="dd/MM/yyyy"
                                                    renderInput={(params) => <TextField {...params} />}
                                                    onChange={handleExpireDateChange}
                                                    value={expireDate}

                                                />

                                            </LocalizationProvider>

                                        }

                                    </td>

                                </tr>

                                <tr>

                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>

                                </tr>

                            </tbody>

                        </table>

                    </form>

                </div>

                <br />

                <Footer />

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(BATCH_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Informe a quantidade do lote</p>

                </Alert>

            </div>
        )

    }

    if (op === BATCH_ALREADYCREATED) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(BATCH_FORM) }}>

                    <p style={{ color: 'black' }}>Nome inválido</p>
                    <p style={{ color: 'black' }}>Já existe um material com esse nome nessa empresa</p>

                </Alert>

            </div>
        )

    }

    if (op === BATCH_CREATED) {

        return (

            <Navigate to={

                buildBatchLinkByQualifiedName(

                    businessQualifiedName +
                    '/' + materialName +
                    '/' + batchCode

                )

            } />
        )

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
