import { useState } from 'react';

import Header from '../../../../../../components/page/Header/Component';
import Footer from '../../../../../../components/page/Footer/Component';
import Title from '../../../../../../components/page/Title/Component';

import Business from '../../../../../../components/Business/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../../lib/network';
import { INVALID_DATA } from '../../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_MENU } from '../../../../../../App';

import { formatDate } from '../../../../../../lib/format';
import { buildMaterialLinkByName, buildMaterialQualifiedName } from '../../../../../../lib/util';
import { searchByAccountToken } from '../../../../../../lib/api/mrp/broken/api';
import { ProductionProfile } from '../../../../../../config/help';

const Component = () => {

    let {

        businessQualifiedName,

        token // business token

    } = useParams();

    const [list, setList] = useState([]);
    const [isQuery, setIsQuery] = useState(false);
    const [name, setName] = useState('');

    const [op, setOp] = useState('');

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        let response = await searchByAccountToken(

            '?token=' + token +

            '&name=' + name

        );

        switch (response.status) {

            case 200:

                let localList = response.body;

                localList.map(e => {

                    switch (

                    e.material.produced

                    ) {

                        case 'T':

                            e.material.type = 'P';

                            break;

                        case 'F':

                            e.material.type = 'C';

                            break;

                        case 'S':

                            e.material.type = 'F';

                            break;

                        default: console.log('invalid material type in Broken Search');

                    }

                    e.link = buildMaterialLinkByName(

                        businessQualifiedName +
                        '/' + e.material.name

                    );

                });

                setIsQuery(true);

                setList(localList)

                setOp(FRONTPAGE);

                break;

            case 400:

                console.log(response.body);

            default: setOp(INTERNAL_ERROR);

        }

    }

    const handleNameChange = (e) => {

        setName(e.target.value);

    }

    const frontPage = () => {

        return (

            <div>

                <Header />

                <Title title="Quebras de Materiais" />

                <Business accountToken={token} />

                <br />

                <div className="content">

                    <p><a href={ProductionProfile.main} target={"_blank"}>Ajuda</a></p>

                    <form onSubmit={handleSubmit}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Nome</th>
                                    <td>

                                        <input type="text" value={name} onChange={handleNameChange} />

                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </form>

                    {(list.length > 0) &&

                        <div>

                            <p>

                                Legenda: P = produzido, C = comprado, F = fracionado

                            </p>

                            <table>

                                <tbody>

                                    <tr>
                                        <th>Data</th>
                                        <th>Material</th>
                                        <th>Tipo</th>
                                        <th style={{ borderRightColor: "black" }}>Quantidade</th>
                                    </tr>

                                    {list.map((e, index) => {// broken supply material

                                        return (

                                            <tr key={index}>

                                                <td style={{ textAlign: "center", padding: "5px" }}>

                                                    {formatDate(e.date)}

                                                </td>

                                                <td style={{ padding: "5px" }}>

                                                    <a href={e.link}>{e.material.name}</a>

                                                </td>

                                                <td style={{ textAlign: "center", padding: "5px" }}>

                                                    {e.material.type}

                                                </td>

                                                <td style={{ textAlign: "right", padding: "5px" }}>

                                                    {e.quantity}

                                                </td>

                                            </tr >

                                        )

                                    })}

                                </tbody>

                            </table >

                        </div>

                    }

                    {(isQuery && list.length === 0) &&

                        <div>

                            <br />

                            Sem resultados.

                        </div>

                    }

                </div>

                <br />

                <Footer />

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Nomes e email</p>

                </Alert>

            </div>
        )

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
