import { useEffect, useState } from 'react';

import Header from '../../../../components/page/Header/Component';
import Footer from '../../../../components/page/Footer/Component';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../lib/network';
import { isValidPersonName } from '../../../../lib/validation';
import { personProfileByQualifiedName, updatePersonName } from '../../../../lib/api/accounts/person/api';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../../App';
import { ErrorText } from '../../../../config/text';

import { hasSessionId, isLoggedPerson, setSessionData } from '../../../../lib/session';
import sessionDetail from '../../../../lib/api/accounts/session/api';

import PermissionList from '../Permission/Main';

import Viewer from '../../../../components/PageEditor/Main';

import personPageByAccountToken, { updatePersonPage } from '../../../../lib/api/publishing/api';
import formatDateTime from '../../../../lib/format';
import PersonProfile from '../../../../config/help';

const PERSONNAMEFORM = 'PERSONNAMEFORM';
const INVALID_PERSONNAME = 'INVALID_PERSONNAME';
const PERSON_NOTFOUND = 'PERSON_NOTFOUND';

const Component = () => {

    let { personQualifiedName } = useParams();

    const [person, setPerson] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);

    const [op, setOp] = useState(null);

    const [currentPersonQualifiedName, setCurrentPersonQualifiedName] = useState(personQualifiedName);

    useEffect(

        () => initData(personQualifiedName),
        []
    );

    const initData = async (personQualifiedName) => {

        // if (!hasSessionId()) {

        //     setOp(REDIRECT_HOME);

        //     return;

        // }

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        personProfileByQualifiedName(

            personQualifiedName,

            (status, body, headers) => {

                if (status === 200) {

                    body.personQualifiedName = personQualifiedName;

                    body.link =

                        window.location.protocol +
                        '//' + window.location.host +
                        '/pages/private/person/profile/' + personQualifiedName;

                    setPerson(body);

                    setOp(FRONTPAGE);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === 'businessQualifiedName') {

                            setOp(PERSON_NOTFOUND);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handlePersonNameSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isValidPersonName(firstName)
                && isValidPersonName(lastName)

            )

        ) {

            setOp(INVALID_PERSONNAME);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        updatePersonName(

            firstName,
            lastName,

            (status, body, headers) => {

                if (status === 200) {

                    refreshSessionData(status, body, headers);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        /* if (m[i] === EMAIL_ALREADYREGISTERED) {

                            setOp(EMAIL_ALREADYREGISTERED);

                            break;

                        } */

                        console.log(m[i]);

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const refreshSessionData = (status1, body1, headers1) => {

        sessionDetail((status1, body1, headers1) => {

            if (status1 === 200) {

                setSessionData(body1);

                setOp(REDIRECT_MENU);

            } else if (status1 === 400) {

                let m = body1.messages;

                for (let i = 0; i < m.length; i++) {

                    /* if (m[i] === SESSION_TOKENNOTFOUND) {

                        setOp(SESSION_TOKENNOTFOUND);

                        break;

                    } */

                    console.log(m[i]);

                }

            } else {

                setOp(INTERNAL_ERROR);

            }

        });

    }

    const handleFirstNameChange = (e) => {

        setFirstName(e.target.value);

    }

    const handleLastNameChange = (e) => {

        setLastName(e.target.value);

    }

    const personNameForm = (props) => {

        return (

            <div className="content">

                <form onSubmit={handlePersonNameSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Nome</th>

                                <td colSpan="2">

                                    <input type="text" value={firstName} onChange={handleFirstNameChange} />

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Sobrenome</th>

                                <td colSpan="2">

                                    <input type="text" value={lastName} onChange={handleLastNameChange} />

                                </td>

                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table>

                </form>

            </div >

        );

    }

    const frontPage = (props) => {

        return (

            <div>

                <Header />

                <div style={{ backgroundColor: "black" }} className="content">

                    <h2 style={{ color: "white" }}>Perfil da Pessoa</h2>

                </div>

                <div className="content">

                    <p><a href={PersonProfile.main} target={"_blank"}>Ajuda</a></p>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Nome</th>

                                <td>{person.firstName}</td>


                                <td rowSpan="2" style={{ textAlign: "center" }}>

                                    {isLoggedPerson(currentPersonQualifiedName) &&

                                        <button type="button" onClick={() => {

                                            setFirstName(person.firstName);
                                            setLastName(person.lastName);

                                            setOp(PERSONNAMEFORM);

                                        }} className="buttonLink">Editar</button>

                                    }

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Sobrenome</th>
                                <td>

                                    {person.lastName}

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Criado</th>
                                <td colSpan="2">

                                    {formatDateTime(person.account.created)}

                                </td>
                            </tr>

                            <tr>
                                <th className="verticalHeader">Modificado</th>
                                <td colSpan="2">

                                    {formatDateTime(person.account.modified)}

                                </td>
                            </tr>

                            <tr>
                                <th className="verticalHeader">Token</th>
                                <td colSpan="2">

                                    {person.account.token}

                                </td>
                            </tr>

                            <tr>
                                <th className="verticalHeader">Link</th>
                                <td colSpan="2"><input type="text" defaultValue={person.link} style={{ width: "95%" }} /></td>
                            </tr>

                        </tbody>

                    </table>

                </div >

                <br />

                <PermissionList personQualifiedName={currentPersonQualifiedName} />

                <br />

                <Viewer

                    load={personPageByAccountToken}
                    save={updatePersonPage}
                    token={person.account.token}
                    edit={(hasSessionId() && isLoggedPerson(currentPersonQualifiedName))} />

                < br />

                <Footer />

            </div>

        );

    }

    if (op === INVALID_PERSONNAME) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(PERSONNAMEFORM) }}>

                    <p style={{ color: 'black' }}>Nome inválido</p>
                    <p style={{ color: 'black' }}>{ErrorText.name}</p>

                </Alert>

            </div>
        )

    }

    if (op === PERSONNAMEFORM) {

        return personNameForm();

    }

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to="/" />

        );

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

export default Component;
