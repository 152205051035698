import { mrpHost } from '../../../../config/api';
import { get, rget, rpost } from '../../../rest';

export const INPUT_ALREADYCREATED = 'INPUT_ALREADYCREATED';
export const INPUT_CREATED = 'INPUT_CREATED';
export const INPUT_NOTFOUND = 'INPUT_NOTFOUND';
export const INPUT_INVALIDQUANTITY = 'INPUT_INVALIDQUANTITY';
export const INPUT_INVALIDDUEDATE = 'INPUT_INVALIDDUEDATE';
export const INPUT_ZEROQUANTITY = 'INPUT_ZEROQUANTITY';

export const deleteOutput = async (

    token

) => {

    return await rpost(

        mrpHost + '/output/deleteOutput',

        {},

        {

            token: token

        }

    )
}

export const deleteInput = async (

    token

) => {

    return await rpost(

        mrpHost + '/input/delete',

        {},

        {

            token: token

        }

    )
}

export const updateInput = async (

    quantity,
    token

) => {

    return await rpost(

        mrpHost + '/input/update',

        {},

        {

            quantity: quantity,
            token: token

        }

    )
}

export const updateInputDuedate = async (

    dueDate,
    token

) => {

    return await rpost(

        mrpHost + '/input/updateDuedate',

        {},

        {

            dueDate: dueDate,
            token: token

        }

    )
}

const createInput = async (

    quantity,
    dueDate,
    materialToken


) => {

    return await rpost(

        mrpHost + '/input/create',

        {},

        {

            quantity: quantity,
            dueDate: dueDate,
            materialToken: materialToken

        }

    )
}

export const inputSearchByAccountToken = async (

    queryString

) => {

    return await rget(

        mrpHost + '/input/searchByAccountToken' + queryString,
        {}

    );
}

export const inputSearchByMaterialToken = async (

    queryString

) => {

    return await rget(

        mrpHost + '/input/searchByMaterialToken' + queryString,
        {}

    );
}

export const orderSearchByMaterialToken = async (

    queryString

) => {

    return await rget(

        mrpHost + '/order/searchByMaterialToken' + queryString,
        {}

    );
}

export const inputProfileByToken = async (

    inputToken

) => {

    return await rget(

        mrpHost + '/input/profileByToken' +

        '/' + inputToken,

        {}

    )
}

export default createInput;