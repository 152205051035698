import { useEffect, useState } from 'react';

import Header from '../../../../../components/page/Header/Component';
import Footer from '../../../../../components/page/Footer/Component';
import Title from '../../../../../components/page/Title/Component';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../lib/network';
import { isValidBusinessName } from '../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../../../App';

import { getPersonQualifiedName, setSessionData } from '../../../../../lib/session';

import PermissionList from '../../Permission/PermissionByBusiness';

import { businessProfileByQualifiedName, updateBusinessName } from '../../../../../lib/api/accounts/business/api';

import Viewer from '../../../../../components/PageEditor/Main';
import { businessPageByBusinessToken, updateBusinessPage } from '../../../../../lib/api/publishing/api';
import formatDateTime from '../../../../../lib/format';
//import { hasPermission, rpermissionListByBusiness, rpermissionListByBusinessToken } from '../../../../../lib/api/accounts/permission/api';
import { BusinessProfile } from '../../../../../config/help';
import businesNameValidate from '../../../../../lib/api/accounts/business/validation';
import sessionDetail from '../../../../../lib/api/accounts/session/api';
import { ErrorText } from '../../../../../config/text';

const ACCOUNT_CREATED = 'ACCOUNT_CREATED';
const EMAIL_ALREADYREGISTERED = 'EMAIL_ALREADYREGISTERED';
const RESENDEMAIL_FORM = 'RESEND_FORM';
const INVALID_RESENDEMAIL = 'INVALID_RESENDEMAIL';
const EMAIL_ALREADYCONFIRMED = 'EMAIL_ALREADYCONFIRMED';
const RESENDEMAIL_MSG = 'RESENDEMAIL_MSG';

const PROFILETEXTFORM = 'PROFILETEXTFORM';
const INVALID_PROFILETEXT = 'INVALID_PROFILETEXT';

const BUSINESSNAMEFORM = 'BUSINESSNAMEFORM';
const INVALID_BUSINESSNAME = 'INVALID_BUSINESSNAME';

const PERSONNAMEFORM = 'PERSONNAMEFORM';
const INVALID_PERSONNAME = 'INVALID_PERSONNAME';
const PERSON_NOTFOUND = 'PERSON_NOTFOUND';

const Component = () => {

    let { businessQualifiedName } = useParams();

    const [business, setBusiness] = useState(null);
    const [profileText, setProfileText] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [businessName, setBusinessName] = useState(null);
    const [hasPermissionToEdit, setHasPermissionToEdit] = useState(false);

    const [op, setOp] = useState(null);

    const [currentBusinessQualifiedName, setCurrentBusinessQualifiedName] = useState(businessQualifiedName);

    useEffect(

        () => initData(businessQualifiedName),
        []
    );

    const initData = async (businessQualifiedName) => {

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        businessProfileByQualifiedName(

            businessQualifiedName,

            (status, body, headers) => {

                if (status === 200) {

                    body.businessQualifiedName = businessQualifiedName;

                    body.link =

                        window.location.protocol +
                        '//' + window.location.host +
                        '/pages/private/person/business/profile/' + businessQualifiedName;

                    setBusiness(body);

                    setOp(FRONTPAGE);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === 'businessQualifiedName') {

                            setOp(PERSON_NOTFOUND);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleBusinessNameSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isValidBusinessName(businessName)

            )

        ) {

            setOp(INVALID_BUSINESSNAME);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        updateBusinessName(

            businessName,
            business.account.token,

            (status, body, headers) => {

                if (status === 200) {

                    setOp(REDIRECT_MENU);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        /* if (m[i] === EMAIL_ALREADYREGISTERED) {

                            setOp(EMAIL_ALREADYREGISTERED);

                            break;

                        } */

                        console.log(m[i]);

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleBusinessNameChange = (e) => {

        setBusinessName(e.target.value);

    }

    const businessNameForm = (props) => {

        return (

            <div className="content">

                <form onSubmit={handleBusinessNameSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <td colSpan="2">

                                    <input style={{ width: "98%" }} type="text" onChange={handleBusinessNameChange} value={businessName} />

                                </td>
                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table >

                </form >

            </div >

        );

    }

    const refreshSessionData = (status1, body1, headers1) => {

        sessionDetail((status1, body1, headers1) => {

            if (status1 === 200) {

                setSessionData(body1);

                setOp(REDIRECT_MENU);

            } else if (status1 === 400) {

                let m = body1.messages;

                for (let i = 0; i < m.length; i++) {

                    /* if (m[i] === SESSION_TOKENNOTFOUND) {

                        setOp(SESSION_TOKENNOTFOUND);

                        break;

                    } */

                    console.log(m[i]);

                }

            } else {

                setOp(INTERNAL_ERROR);

            }

        });

    }

    const frontPage = (props) => {

        return (

            <div>

                <Header />

                <Title title="Perfil da Empresa" />

                <div className="content">

                    <p><a href={BusinessProfile.main} target={"_blank"}>Ajuda</a></p>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr style={{ borderRightColor: "var(--panel-bg-color)" }}>

                                <th className="verticalHeader">Nome</th>

                                <td>{business.businessName}</td>

                                <td style={{ textAlign: "center" }}>

                                    {hasPermissionToEdit &&

                                        <button type="button" onClick={() => {

                                            setBusinessName(business.businessName);

                                            setOp(BUSINESSNAMEFORM)

                                        }} className="buttonLink">Editar</button>

                                    }

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Criado</th>
                                <td style={{ borderRightColor: "var(--panel-bg-color)" }}>

                                    {formatDateTime(business.account.created)}

                                </td>
                                <td>&nbsp;</td>
                            </tr>

                            <tr>
                                <th className="verticalHeader">Modificado</th>
                                <td style={{ borderRightColor: "var(--panel-bg-color)" }}>

                                    {formatDateTime(business.account.modified)}

                                </td>
                                <td>&nbsp;</td>
                            </tr>

                            <tr>
                                <th className="verticalHeader">Token</th>
                                <td style={{ borderRightColor: "var(--panel-bg-color)" }}>

                                    {business.account.token}

                                </td>
                                <td>&nbsp;</td>
                            </tr>

                            <tr>
                                <th className="verticalHeader">Link</th>
                                <td colSpan="2"><input type="text" defaultValue={business.link} style={{ width: "95%" }} /></td>
                            </tr>

                        </tbody>

                    </table>

                </div >

                <br />

                <PermissionList businessQualifiedName={currentBusinessQualifiedName} setHasPermission={setHasPermission} />

                <br />

                <div className="content">

                    <table style={{ width: "100%" }}><tbody>

                        <tr>

                            <th>Materiais</th>
                            <th>Produção</th>
                            <th>Entradas</th>
                            <th style={{ borderRightColor: "black" }}>Saídas</th>

                        </tr>

                        <tr>

                            <td>

                                <ul style={{ padding: "5px", margin: "0px", listStylePosition: "inside" }}>

                                    <li><a href={"/pages/private/person/business/material/search/" + business.account.token}>Procurar</a></li>

                                    {hasPermissionToEdit &&

                                        <li><a href={"/pages/private/person/business/material/create/" + business.account.token}>Cadastrar</a></li>

                                    }

                                </ul>

                            </td>

                            <td>

                                <ul style={{ padding: "5px", margin: "0px", listStylePosition: "inside" }}>

                                    <li><a href={

                                        '/pages/private/person/business/order/search' +

                                        '/' + businessQualifiedName +

                                        '/' + business.account.token

                                    }>Ordens</a></li>

                                    <li><a href={

                                        '/pages/private/person/business/broken/search' +

                                        '/' + businessQualifiedName +

                                        '/' + business.account.token

                                    }>Quebras</a></li>

                                </ul>

                            </td>

                            <td>

                                <ul style={{ padding: "5px", margin: "0px", listStylePosition: "inside" }}>

                                    <li><a href={

                                        '/pages/private/person/business/input/search' +

                                        '/' + businessQualifiedName +

                                        '/' + business.account.token

                                    }>Procurar</a></li>

                                </ul>

                            </td>

                            <td>

                                <ul style={{ padding: "5px", margin: "0px", listStylePosition: "inside" }}>

                                    <li><a href={

                                        '/pages/private/person/business/output/search' +

                                        '/' + businessQualifiedName +

                                        '/' + business.account.token

                                    }>Procurar</a></li>

                                </ul>

                            </td>

                        </tr>

                    </tbody></table>

                </div >

                <br />

                <Viewer

                    load={businessPageByBusinessToken}
                    save={

                        (content, callBack) => {

                            updateBusinessPage(

                                content,
                                business.account.token,
                                callBack

                            );

                        }

                    }
                    token={business.account.token}
                    edit={hasPermissionToEdit} />

                <br />

                <Footer />

            </div>

        );

    }

    const setHasPermission = (permissionList) => {

        for (let i = 0; i < permissionList.length; i++) {

            let p = permissionList[i];

            let pqn = getPersonQualifiedName();

            if (pqn === p.personQualifiedName) {

                if (

                    (p.code === 'SUP')

                    || (p.code === 'PUB')

                ) {

                    setHasPermissionToEdit(true);

                    break;

                }

            }

        };

    }

    if (op === INVALID_BUSINESSNAME) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(BUSINESSNAMEFORM) }}>

                    <p style={{ color: 'black' }}>Nome de empresa inválido</p>
                    <p style={{ color: 'black' }}>{ErrorText.name}</p>

                </Alert>

            </div>
        )

    }

    if (op === BUSINESSNAMEFORM) {

        return businessNameForm();

    }

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to="/" />

        );

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

export default Component;
