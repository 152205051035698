export const BUSINESS_NOTFOUND = 'BUSINESS_NOTFOUND';

const businesNameValidate = (data) => {

    return (data.trim().match(/^.{3,45}$/i) != null);

}

export const profileTextValidate = (data) => {

    return (data.trim().match(/^.{10,400}$/i) != null);

}

export default businesNameValidate;