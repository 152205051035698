import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../lib/network';
import { INVALID_DATA, isValidEmail, isValidLoginCode } from '../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { EmailText, ErrorText } from '../../../../config/text';
import { REDIRECT_HOME, REDIRECT_MENU } from '../../../../App';
import sessionDetail, { emailLogin, sendLoginEmail } from '../../../../lib/api/accounts/session/api';
import { SESSION_ID, setSessionData, setSessionId } from '../../../../lib/session';

const ACCOUNT_CONFIRMED = 'ACCOUNT_CONFIRMED';
const RESENDEMAIL_FORM = 'RESEND_FORM';
const INVALID_RESENDEMAIL = 'INVALID_RESENDEMAIL';
const EMAIL_NOTREGISTERED = 'EMAIL_NOTREGISTERED';
const RESENDEMAIL_MSG = 'RESENDEMAIL_MSG';
const EMAIL_TOKENNOTFOUND = 'EMAIL_TOKENNOTFOUND';
const EMAIL_ALREADYCONFIRMED = 'EMAIL_ALREADYCONFIRMED';
const EMAIL_SENT = 'EMAIL_SENT';
const EMAIL_NOTCONFIRMED = 'EMAIL_NOTCONFIRMED';

const Component = () => {

    const [accountEmail, setAccountEmail] = useState('');
    const [confirmCode, setConfirmCode] = useState('');
    const [op, setOp] = useState(null);

    const handleChange = (e) => {

        setConfirmCode(e.target.value);

    }

    const handleEmailChange = (e) => {

        setAccountEmail(e.target.value);

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !isValidLoginCode(confirmCode)

        ) {

            setOp(EMAIL_TOKENNOTFOUND);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        emailLogin(

            confirmCode,

            (status, body, headers) => {

                if (status === 200) {

                    setSessionId(headers.get(SESSION_ID));

                    sessionDetail((status1, body1, headers1) => {

                        if (status1 === 200) {

                            setSessionData(body1);

                            setOp(REDIRECT_MENU);

                        } else if (status1 === 400) {

                            let m = body1.messages;

                            for (let i = 0; i < m.length; i++) {

                                if (m[i] === EMAIL_TOKENNOTFOUND) {

                                    setOp(EMAIL_TOKENNOTFOUND);

                                    break;

                                }

                            }

                        } else {

                            setOp(INTERNAL_ERROR);

                        }

                    });

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === EMAIL_TOKENNOTFOUND) {

                            setOp(EMAIL_TOKENNOTFOUND);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleSubmitSendEmail = async (e) => {

        e.preventDefault();

        if (

            !isConnected()

        ) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isValidEmail(accountEmail)

            )

        ) {

            setOp(INVALID_RESENDEMAIL);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        sendLoginEmail(

            accountEmail,

            (status, body) => {

                if (status === 200) {

                    setOp(EMAIL_SENT);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === EMAIL_NOTREGISTERED) {

                            setOp(EMAIL_NOTREGISTERED);

                            break;

                        }

                        if (m[i] === EMAIL_NOTCONFIRMED) {

                            setOp(EMAIL_NOTCONFIRMED);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const frontPage = (props) => {

        return (

            <div className="content">

                <h3>Login</h3>

                <div>

                    <p>Use o código de login enviado para o email que você cadastrou no ProdCalc</p>

                    <form onSubmit={handleSubmit}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Código</th>
                                    <td><input type="text" value={confirmCode} onChange={handleChange} /></td>
                                </tr>

                                <tr>
                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </form>

                    <p style={{ backgroundColor: "black", padding: "10px" }}>

                        Clique <button type="button" className="buttonLink" onClick={() => setOp(RESENDEMAIL_FORM)}>aqui</button> para enviar um email com o código de login.

                    </p>

                </div>

            </div>

        );

    }

    const sendEmailForm = (props) => {

        return (

            <div className="content">

                <h3>Já tem uma conta no ProdCalc?</h3>

                <div>

                    <p>Informe o email que você cadastrou no ProdCalc para se authenticar</p>

                    <form onSubmit={handleSubmitSendEmail}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Email</th>
                                    <td><input type="email" name="accountEmail" value={accountEmail} onChange={handleEmailChange} /></td>
                                </tr>

                                <tr>
                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </form>

                    <p>

                        <button type="button" className="buttonLink" onClick={() => setOp(null)}>Cancelar</button>

                    </p>

                </div>

            </div>

        );

    }

    if (op === EMAIL_SENT) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="success" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Email enviado</p>
                    <p style={{ color: 'black' }}>Um email com o código de login foi enviado para você</p>

                </Alert>

            </div >
        )

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>{ErrorText.name}</p>
                    <p style={{ color: 'black' }}>Email inválido</p>

                </Alert>

            </div>
        )

    }

    if (op === ACCOUNT_CONFIRMED) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="success" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Conta confirmada!</p>

                </Alert>

            </div >
        )

    }

    if (op === EMAIL_ALREADYCONFIRMED) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Email já confirmado</p>

                </Alert>

            </div >
        )

    }

    if (op === RESENDEMAIL_FORM) {

        return sendEmailForm();

    }

    if (op === INVALID_RESENDEMAIL) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Email</p>

                </Alert>

            </div>
        )

    }

    if (op === EMAIL_TOKENNOTFOUND) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Código de login inválido</p>

                </Alert>

            </div>
        )

    }

    if (op === RESENDEMAIL_MSG) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="success" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email enviado</p>
                    <p style={{ color: 'black' }}>{EmailText.confirmCode}</p>

                </Alert>

            </div>
        )

    }

    if (op === EMAIL_NOTREGISTERED) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email não cadastrado</p>

                </Alert>

            </div>
        )

    }

    if (op === EMAIL_NOTCONFIRMED) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Email não confirmado</p>

                </Alert>

            </div>
        )

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Login</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to="/" />

        );

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    return frontPage();

}

export default Component;
