import { useState } from 'react';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../lib/network';
import { INVALID_DATA, isValidConfirmCode, isValidEmail, isValidPersonName } from '../../../../lib/validation';
import personRegister from '../../../../lib/api/accounts/person/api';
import confirmEmail, { resendConfirmEmail, sendConfirmCode } from '../../../../lib/api/accounts/account/api';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { EmailText, ErrorText } from '../../../../config/text';
import { REDIRECT_HOME } from '../../../../App';

const ACCOUNT_CONFIRMED = 'ACCOUNT_CONFIRMED';
const EMAIL_ALREADYREGISTERED = 'EMAIL_ALREADYREGISTERED';
const RESENDEMAIL_FORM = 'RESEND_FORM';
const CONFIRMCODE_FORM = 'CONFIRMCODE_FORM';
const INVALID_RESENDEMAIL = 'INVALID_RESENDEMAIL';
const INVALID_CONFIRMCODE = 'INVALID_CONFIRMCODE';
const EMAIL_NOTREGISTERED = 'EMAIL_NOTREGISTERED';
const RESENDEMAIL_MSG = 'RESENDEMAIL_MSG';
const EMAIL_TOKENNOTFOUND = 'EMAIL_TOKENNOTFOUND';
const EMAIL_ALREADYCONFIRMED = 'EMAIL_ALREADYCONFIRMED';

const Component = () => {

    const [accountEmail, setAccountEmail] = useState('');
    const [confirmCode, setConfirmCode] = useState('');
    const [op, setOp] = useState(null);

    const handleChange = (e) => {

        setConfirmCode(e.target.value);

    }

    const handleEmailChange = (e) => {

        setAccountEmail(e.target.value);

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isValidConfirmCode(confirmCode)

            )

        ) {

            setOp(EMAIL_TOKENNOTFOUND);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        confirmEmail(

            confirmCode,

            (status, body) => {

                if (status === 200) {

                    setOp(ACCOUNT_CONFIRMED);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === EMAIL_ALREADYCONFIRMED) {

                            setOp(EMAIL_ALREADYCONFIRMED);

                            break;

                        }

                        if (m[i] === EMAIL_TOKENNOTFOUND) {

                            setOp(EMAIL_TOKENNOTFOUND);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleSubmitResendEmail = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isValidEmail(accountEmail)

            )

        ) {

            setOp(INVALID_RESENDEMAIL);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        resendConfirmEmail(

            accountEmail,

            (status, body) => {

                if (status === 200) {

                    setOp(RESENDEMAIL_MSG);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === EMAIL_ALREADYCONFIRMED) {

                            setOp(EMAIL_ALREADYCONFIRMED);

                            break;

                        }

                        if (m[i] === EMAIL_NOTREGISTERED) {

                            setOp(EMAIL_NOTREGISTERED);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const frontPage = (props) => {

        return (

            <div className="content">

                <h3>Confirme sua Conta</h3>

                <div>

                    <p>Use o código de confirmação enviado para o email que você cadastrou no ProdCalc</p>

                    <form onSubmit={handleSubmit}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Código</th>
                                    <td><input type="text" value={confirmCode} onChange={handleChange} /></td>
                                </tr>

                                <tr>
                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </form>

                    <p style={{ backgroundColor: "black", padding: "10px" }}>

                        Clique <button type="button" className="buttonLink" onClick={() => setOp(RESENDEMAIL_FORM)}>aqui</button> para reenviar o email de confirmação.

                    </p>

                </div>

            </div>

        );

    }

    const resendEmailForm = (props) => {

        return (

            <div className="content">

                <h3>Confirmar Conta</h3>

                <p>Reenvio do código de confirmação</p>

                <div>

                    <p>Informe o email que você cadastrou no ProdCalc</p>

                    <form onSubmit={handleSubmitResendEmail}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Email</th>
                                    <td><input type="email" name="accountEmail" value={accountEmail} onChange={handleEmailChange} /></td>
                                </tr>

                                <tr>
                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </form>

                    <p>

                        <button type="button" className="buttonLink" onClick={() => setOp(null)}>Cancelar</button>

                    </p>

                </div>

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Confirmar Conta</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>{ErrorText.name}</p>
                    <p style={{ color: 'black' }}>Email inválido</p>

                </Alert>

            </div>
        )

    }

    if (op === ACCOUNT_CONFIRMED) {

        return (

            <div className="content">

                <h3>Confirmar Conta</h3>

                <Alert severity="success" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Conta confirmada!</p>

                </Alert>

            </div >
        )

    }

    if (op === EMAIL_ALREADYCONFIRMED) {

        return (

            <div className="content">

                <h3>Confirmar Conta</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Email já confirmado</p>

                </Alert>

            </div >
        )

    }

    if (op === RESENDEMAIL_FORM) {

        return resendEmailForm();

    }

    if (op === INVALID_RESENDEMAIL) {

        return (

            <div className="content">

                <h3>Confirmar Conta</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Email</p>

                </Alert>

            </div>
        )

    }

    if (op === EMAIL_TOKENNOTFOUND) {

        return (

            <div className="content">

                <h3>Confirmação de Conta</h3>

                <Alert severity="error" onClose={() => { setOp(REDIRECT_HOME) }}>

                    <p style={{ color: 'black' }}>Código de confirmação inválido</p>

                </Alert>

            </div>
        )

    }

    if (op === RESENDEMAIL_MSG) {

        return (

            <div className="content">

                <h3>Confirmar Conta</h3>

                <Alert severity="success" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email enviado</p>
                    <p style={{ color: 'black' }}>{EmailText.confirmCode}</p>

                </Alert>

            </div>
        )

    }

    if (op === EMAIL_NOTREGISTERED) {

        return (

            <div className="content">

                <h3>Confirmar Conta</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email não cadastrado</p>

                </Alert>

            </div>
        )

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Confirmar Conta</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Confirmar Conta</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Confirmar Conta</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
