import getSessionId, { SESSION_ID, hasSessionId } from './session';

export const CIRCULAR_SPINNER = 'CIRCULAR_SPINNER';

export const post = async (

    url,
    headers,
    body,
    callBack,
    onError = (error) => console.log(error)

) => {

    if (hasSessionId()) {

        headers[SESSION_ID] = getSessionId()

    }

    const resp = await fetch(

        url,
        {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        }

    );


    if (resp.status >= 500) {

        callBack(500);

        return;

    }

    const json = await resp.json();

    if (

        ((resp.status <= 200) && (resp.status < 300))

    ) {

        callBack(200, json, resp.headers);

    } else {

        callBack(400, json);

    }

}

export const rpost = async (

    url,
    headers,
    body,
    onError = (error) => console.log(error)

) => {

    if (

        hasSessionId()

    ) {

        headers[SESSION_ID] = getSessionId()

    }

    const resp = await fetch(

        url,

        {

            method: 'POST',

            headers: headers,

            body: JSON.stringify(

                body

            )

        }

    );

    let response = {};

    if (

        resp.status >= 500

    ) {

        response.status = 500;

        return;

    }

    const json = await resp.json();

    if (

        (

            (resp.status <= 200)
            && (resp.status < 300)

        )

    ) {

        response.status = 200;
        response.body = json;
        response.headers = headers;

    } else {

        response.status = 400;
        response.body = json;

    }

    return response;
}

export const get = async (

    url,
    headers,
    callBack,
    onError = (error) => console.log(error)

) => {

    if (hasSessionId()) {

        headers[SESSION_ID] = getSessionId()

    }

    const resp = await fetch(

        url,
        {
            method: 'GET',
            headers: headers
        }

    );

    if (resp.status >= 500) {

        callBack(500);

        return;

    }

    const json = await resp.json();

    if (

        ((resp.status <= 200) && (resp.status < 300))

    ) {

        callBack(200, json, resp.headers);

    } else {

        callBack(400, json);

    }

}

export const rget = async (

    url,
    headers,
    onError = (error) => console.log(error)

) => {

    if (hasSessionId()) {

        headers[SESSION_ID] = getSessionId()

    }

    const resp = await fetch(

        url,
        {
            method: 'GET',
            headers: headers
        }

    );

    let response = {};

    if (resp.status >= 500) {

        response.status = 500;

        return;

    }

    const json = await resp.json();

    if (

        (

            (resp.status <= 200)
            && (resp.status < 300)

        )

    ) {

        response.status = 200;
        response.body = json;
        response.headers = headers;

    } else {

        response.status = 400;
        response.body = json;

    }

    return response;

}

export default get;