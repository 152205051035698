import { mrpHost } from '../../../../config/api';
import { post, get } from '../../../rest';

export const BATCH_ALREADYCREATED = 'BATCH_ALREADYCREATED';
export const BATCH_CREATED = 'BATCH_CREATED';
export const BATCH_NOTFOUND = 'BATCH_NOTFOUND';
export const BATCH_INVALIDQUANTITY = 'BATCH_INVALIDQUANTITY';
export const BATCH_ZEROQUANTITY = 'BATCH_ZEROQUANTITY';
export const BATCH_NOTENOUGHTQUANTITY = 'BATCH_NOTENOUGHTQUANTITY';

export const updateBatchCode = (

    batchCode,
    batchToken,
    callBack

) => {

    post(

        mrpHost + '/batch/updateBatchCode',
        {},
        {

            batchCode: batchCode,
            batchToken: batchToken

        },
        callBack

    )
}

export const updateExpireDate = (

    expireDate,
    batchToken,
    callBack

) => {

    post(

        mrpHost + '/batch/updateExpireDate',
        {},
        {

            expireDate: expireDate,
            batchToken: batchToken

        },
        callBack

    )
}

const createBatch = (

    batchCode,
    expireDate,
    materialToken,
    batchQuantity,
    callBack

) => {

    post(

        mrpHost + '/batch/createBatch',
        {},
        {

            batchCode: batchCode,
            expireDate: expireDate,
            materialToken: materialToken,
            quantity: batchQuantity

        },
        callBack

    )
}

export const batchProfileByBatchCode = (

    accountToken,
    materialToken,
    callBack

) => {

    get(

        mrpHost + '/material/materialProfileByToken' +
        '/' + accountToken +
        '/' + materialToken,
        {},
        callBack

    )
}

export const batchSearch = (

    queryString,
    callBack,

) => {

    get(

        mrpHost + '/batch/batchSearch' + queryString,

        {},
        callBack

    )
}

export const batchProfileByToken = (

    batchToken,
    callBack,

) => {

    get(

        mrpHost + '/batch/batchProfileByToken' +

        '/' + batchToken,

        {},
        callBack

    )
}

export default createBatch;