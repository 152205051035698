import { useEffect, useState } from 'react';

import Header from '../../../../../components/page/Header/Component';
import Footer from '../../../../../components/page/Footer/Component';
import Title from '../../../../../components/page/Title/Component';

import Business from '../../../../../components/Business/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../lib/network';
import isValidName, { INVALID_DATA } from '../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import ConfigText, { ErrorText, PersonMaterialProfile } from '../../../../../config/text';
import { Navigate, useParams } from 'react-router-dom';
import { FRONTPAGE, REDIRECT_HOME, REDIRECT_MENU } from '../../../../../App';

import Viewer from '../../../../../components/PageEditor/Main';

import { materialProfileByQualifiedName, MATERIAL_ALREADYCREATED, MATERIAL_NOTFOUND, updateMaterialName, updateMaterialProfileText, updateMaterialYield } from '../../../../../lib/api/mrp/material/api';
import { buildMaterialLinkByName, buildPersonQualifiedName, setHasPermission } from '../../../../../lib/util';
import { permissionListByBusinessToken } from '../../../../../lib/api/accounts/permission/api';
import { businessProfileByQualifiedName } from '../../../../../lib/api/accounts/business/api';
import isMaterialNameValid, { isMaterialProfileTextValid, MATERIAL_INVALIDNAME, MATERIAL_INVALIDYIELD, MATERIAL_YIELD } from '../../../../../lib/api/mrp/material/validation';
import formatDateTime from '../../../../../lib/format';
import { materialPageByMaterialToken, updateMaterialPage } from '../../../../../lib/api/publishing/api';
import { MaterialProfile } from '../../../../../config/help';
import { hasSessionId } from '../../../../../lib/session';
import isQuantityValid from '../../../../../lib/api/mrp/structure/validation';

const PROFILETEXT_FORM = 'PROFILETEXTFORM';
const INVALID_PROFILETEXT = 'INVALID_PROFILETEXT';

const MATERIALNAME_FORM = 'MATERIALNAME_FORM';
const MATERIALYIELD_FORM = 'MATERIALYIELD_FORM';

const Component = () => {

    let { businessQualifiedName, businessMaterialName } = useParams();

    const [material, setMaterial] = useState(null);
    const [profileText, setProfileText] = useState(null);
    const [materialName, setMaterialName] = useState(null);
    const [materialYield, setMaterialYield] = useState(null);
    const [hasPermissionToEdit, setHasPermissionToEdit] = useState(false);

    const [op, setOp] = useState(null);

    useEffect(

        () => initData(),

        []
    );

    const initData = async () => {

        // if (!hasSessionId()) {

        //     setOp(REDIRECT_HOME);

        //     return;

        // }

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        businessProfileByQualifiedName(

            businessQualifiedName,

            (status, body, headers) => {

                let localBusiness = body;

                if (status === 200) {

                    let materialQualifiedName =

                        businessQualifiedName +

                        '/' + businessMaterialName;

                    materialProfileByQualifiedName(

                        localBusiness.account.token +

                        '/' + businessMaterialName,

                        (status1, body1, headers1) => {

                            if (status1 === 200) {

                                let localMaterial = body1;

                                localMaterial.business = localBusiness;

                                setMaterialName(localMaterial.materialName);

                                setMaterialYield(localMaterial.materialYield);

                                localMaterial.accountToken = localBusiness.account.token;

                                switch (

                                localMaterial.produced

                                ) {

                                    case 'T':

                                        localMaterial.type = 'Produzido';

                                        break;

                                    case 'F':

                                        localMaterial.type = 'Comprado';

                                        break;

                                    case 'S':

                                        localMaterial.type = 'Fracionado';

                                        break;

                                    default: console.log('invalid material type in Material Detail');

                                }

                                localMaterial.materialQualifiedName = materialQualifiedName;

                                localMaterial.link = buildMaterialLinkByName(

                                    materialQualifiedName,
                                    true

                                );

                                setMaterial(localMaterial);

                                permissionListByBusinessToken(

                                    localBusiness.account.token,

                                    (status1, permissionList, headers1) => {

                                        if (status1 === 200) {

                                            permissionList.map(permission => {

                                                permission.personQualifiedName = buildPersonQualifiedName(permission.person);

                                            });

                                            if (hasSessionId()) {

                                                setHasPermission(

                                                    ['SUP', 'PUB'],
                                                    permissionList,
                                                    setHasPermissionToEdit

                                                );

                                            } else {

                                                setHasPermissionToEdit(false);

                                            }

                                            setOp(FRONTPAGE);

                                        } else if (status1 === 400) {

                                            let m = permissionList.messages;

                                            for (let i = 0; i < m.length; i++) {

                                                /* if (m[i] === 'businessQualifiedName') {
                        
                                                    setOp(BUSINESS_NOTFOUND);
                        
                                                    break;
                        
                                                } */

                                                console.log(m[1]);

                                            }

                                        } else {

                                            setOp(INTERNAL_ERROR);

                                        }

                                    }

                                );

                            } else if (status1 === 400) {

                                /* let m = body.messages;
            
                                for (let i = 0; i < m.length; i++) {
            
                                    if (m[i] === 'businessQualifiedName') {
            
                                        setOp(MATERIAL_NOTFOUND);
            
                                        break;
            
                                    }
            
                                } */

                                setOp(MATERIAL_NOTFOUND);

                            } else {

                                setOp(INTERNAL_ERROR);

                            }

                        }

                    );


                } else if (status === 400) {

                    /* let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === 'businessQualifiedName') {

                            setOp(MATERIAL_NOTFOUND);

                            break;

                        }

                    } */

                    setOp(MATERIAL_NOTFOUND);

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleProfileTextSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isMaterialProfileTextValid(profileText)

            )

        ) {

            setOp(INVALID_PROFILETEXT);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        updateMaterialProfileText(

            profileText,

            material.token,

            (status, body, headers) => {

                if (status === 200) {

                    setOp(FRONTPAGE);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        /* if (m[i] === EMAIL_ALREADYREGISTERED) {
             
                            setOp(EMAIL_ALREADYREGISTERED);
             
                            break;
             
                        } */

                        console.log(m[i]);

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleProfileTextChange = (e) => {

        setProfileText(e.target.value);

    }

    const profileTextForm = () => {

        return (

            <div className="content">

                <form onSubmit={handleProfileTextSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <td colSpan="2">

                                    <input type="text" value={profileText} onChange={handleProfileTextChange} />

                                </td>
                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table >

                </form >

            </div >

        );

    }

    const handleMaterialNameSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !
            isValidName(materialName)

        ) {

            setOp(MATERIAL_INVALIDNAME);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        updateMaterialName(

            materialName,
            material.token,

            (status, body, headers) => {

                if (status === 200) {

                    setOp(FRONTPAGE);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === 'materialName') {

                            setOp(MATERIAL_INVALIDNAME);

                            break;

                        }

                        if (m[i] === MATERIAL_ALREADYCREATED) {

                            setOp(MATERIAL_ALREADYCREATED);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleMaterialNameChange = (e) => {

        setMaterialName(e.target.value);

    }

    const materialNameForm = () => {

        return (

            <div className="content">

                <form onSubmit={handleMaterialNameSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Nome</th>

                                <td colSpan="2">

                                    <input type="text" value={materialName} onChange={handleMaterialNameChange} />

                                </td>

                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table>

                </form>

            </div >

        );

    }

    const handleMateriaLYieldSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !
            isQuantityValid(materialYield)

        ) {

            setOp(MATERIAL_INVALIDYIELD);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        updateMaterialYield(

            materialYield,
            material.token,

            (status, body, headers) => {

                if (status === 200) {

                    setOp(FRONTPAGE);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === 'materialYield') {

                            setOp(MATERIAL_INVALIDYIELD);

                            break;

                        }

                        if (m[i] === MATERIAL_ALREADYCREATED) {

                            setOp(MATERIAL_ALREADYCREATED);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleMaterialYieldChange = (e) => {

        setMaterialYield(e.target.value);

    }

    const materialYieldForm = () => {

        return (

            <div className="content">

                <form onSubmit={handleMateriaLYieldSubmit}>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Rendimento</th>

                                <td colSpan="2">

                                    <input type="text" value={materialYield} onChange={handleMaterialYieldChange} />

                                </td>

                            </tr>

                            <tr>

                                <td style={{ borderRightColor: "gray" }}>

                                    <button type="button" onClick={() => setOp(FRONTPAGE)}>Cancelar</button>

                                </td>

                                <td style={{ textAlign: "right", borderLeftColor: "gray" }}>

                                    <input type="submit" value="Enviar" />

                                </td>

                            </tr>

                        </tbody>

                    </table>

                </form>

            </div >

        );

    }

    const frontPage = () => {

        return (

            <div>

                <Header />

                <Title title="Perfil do Material" />

                <Business accountToken={material.business.account.token} />

                <br />

                <div className="content">

                    <p><a href={MaterialProfile.main} target={"_blank"}>Ajuda</a></p>

                    <table style={{ width: "100%" }}>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Nome</th>

                                <td>{materialName}</td>

                                <td style={{ textAlign: "center" }}>

                                    {hasPermissionToEdit &&

                                        <button type="button" onClick={() => {

                                            setOp(MATERIALNAME_FORM);

                                        }} className="buttonLink">Editar</button>

                                    }

                                </td>

                            </tr>

                            <tr>
                                <th className="verticalHeader">Criado</th>
                                <td colSpan="2">

                                    {formatDateTime(material.created)}


                                </td>
                            </tr>

                            <tr>
                                <th className="verticalHeader">Modificado</th>
                                <td colSpan="2">

                                    {formatDateTime(material.modified)}

                                </td>
                            </tr>

                            <tr>
                                <th className="verticalHeader">Tipo</th>
                                <td colSpan="2">

                                    {material.type}

                                </td>
                            </tr>

                            <tr>
                                <th className="verticalHeader">Token</th>
                                <td colSpan="2">{material.token}</td>
                            </tr>

                            <tr>
                                <th className="verticalHeader">Link</th>
                                <td colSpan="2"><input type="text" defaultValue={material.link} style={{ width: "95%" }} /></td>
                            </tr>

                        </tbody>

                    </table>

                </div >

                <br />

                <div className="content">

                    <table style={{ width: "100%" }}><tbody>

                        <tr>

                            <th>Lotes</th>
                            {(material.produced !== 'F') && <th>Produção</th>}
                            {(material.produced === 'F') && <th>Entrada</th>}
                            <th style={{ borderRightColor: "black" }}>Saída</th>

                        </tr>

                        <tr>

                            <td>

                                <ul style={{ padding: "0px", listStylePosition: "inside" }}>

                                    <li><a href={

                                        '/pages/private/person/batch/search' +

                                        '/' + material.materialQualifiedName +

                                        '/' + material.token

                                    }>Procurar</a></li>

                                    {hasPermissionToEdit &&

                                        <li><a href={

                                            '/pages/private/person/batch/create' +

                                            '/' + material.materialQualifiedName +

                                            '/' + material.token

                                        }>Cadastrar</a></li>

                                    }

                                </ul>

                            </td>

                            {(material.produced !== 'F') &&

                                <td>

                                    <ul style={{ padding: "0px", listStylePosition: "inside" }}>

                                        <li><a href={

                                            '/pages/private/person/material/order/search' +

                                            '/' + businessQualifiedName +

                                            '/' + material.materialName.toLowerCase() +

                                            '/' + material.token

                                        }>Ordens</a></li>

                                        {(material.produced === 'T') &&

                                            <li><a href={

                                                '/pages/private/person/structure/profile' +

                                                '/' + businessQualifiedName +

                                                '/' + material.materialName.toLowerCase() +

                                                '/' + material.token

                                            }>Estrutura</a></li>

                                        }

                                        {(material.produced === 'S') &&

                                            <li><a href={

                                                '/pages/private/person/fraction/profile' +

                                                '/' + businessQualifiedName +

                                                '/' + material.materialName.toLowerCase() +

                                                '/' + material.token

                                            }>Rendimento</a></li>

                                        }

                                    </ul>

                                </td>

                            }

                            {(material.produced === 'F') &&

                                <td>

                                    <ul style={{ padding: "0px", listStylePosition: "inside" }}>

                                        <li><a href={

                                            '/pages/private/person/material/input/search' +

                                            '/' + businessQualifiedName +

                                            '/' + material.materialName.toLowerCase() +

                                            '/' + material.token

                                        }>Procurar</a></li>

                                        {hasPermissionToEdit &&

                                            <li><a href={

                                                '/pages/private/person/material/input/create' +

                                                '/' + businessQualifiedName +

                                                '/' + material.materialName.toLowerCase() +

                                                '/' + material.token

                                            }>Cadastrar</a></li>

                                        }

                                    </ul>

                                </td>

                            }

                            <td>

                                <ul style={{ padding: "0px", listStylePosition: "inside" }}>

                                    <li><a href={

                                        '/pages/private/person/material/output/search' +

                                        '/' + businessQualifiedName +

                                        '/' + material.materialName.toLowerCase() +

                                        '/' + material.token

                                    }>Procurar</a></li>

                                    {hasPermissionToEdit &&

                                        <li><a href={

                                            '/pages/private/person/material/output/create' +

                                            '/' + businessQualifiedName +

                                            '/' + material.materialName.toLowerCase() +

                                            '/' + material.token

                                        }>Cadastrar</a></li>

                                    }

                                </ul>

                            </td>

                        </tr>

                    </tbody></table>

                </div >

                <br />

                <Viewer

                    load={materialPageByMaterialToken}
                    save={

                        (content, callBack) => {

                            updateMaterialPage(

                                content,
                                material.token,
                                callBack

                            );

                        }

                    }
                    token={material.token}
                    edit={hasPermissionToEdit} />

                <br />

                <Footer />

            </div >

        );

    }

    if (op === MATERIAL_INVALIDNAME) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(MATERIALNAME_FORM) }}>

                    <p style={{ color: 'black' }}>Nome inválido</p>
                    <p style={{ color: 'black' }}>{PersonMaterialProfile.invalidName}</p>

                </Alert>

            </div>
        )

    }

    if (op === MATERIAL_ALREADYCREATED) {

        return (

            <div className="content">

                <h3>Perfil do Material</h3>

                <Alert severity="error" onClose={() => { setOp(MATERIALNAME_FORM) }}>

                    <p style={{ color: 'black' }}>Nome inválido</p>
                    <p style={{ color: 'black' }}>Já existe um material com esse nome nessa empresa</p>

                </Alert>

            </div>
        )

    }

    if (op === MATERIALNAME_FORM) {

        return materialNameForm();

    }

    if (op === MATERIALYIELD_FORM) {

        return materialYieldForm();

    }

    if (op === PROFILETEXT_FORM) {

        return profileTextForm();

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="success" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Conta criada</p>
                    <p style={{ color: 'black' }}>Um email de confirmação foi enviado para você</p>

                </Alert>

            </div >
        )

    }

    if (op === REDIRECT_HOME) {

        return (

            <Navigate to="/" />

        );

    }

    if (op === REDIRECT_MENU) {

        return (

            <Navigate to="/pages/private/menu" />

        );

    }

    if (op === FRONTPAGE) {

        return frontPage();

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return null;

}

export default Component;
