const ConfigText = {

	profileText: "MRP para todos!",
	headerIconText: "ProdCalc Logo"

}

export const EmailText = {

	confirmCode: "Um email com o código de confirmação foi enviado para você"

}

export const ErrorText = {

	name: "Use entre 3 e 45 caracteres ou números sem acentuação"

}

export const PersonBusinessMaterialCreate = {

	invalidName: "Use de 3 a 45 caracteres alfanuméricos, espaços, vírgulas, pontos e hífens"

}

export const PersonMaterialProfile = {

	invalidName: "Use de 3 a 45 caracteres alfanuméricos, espaços, vírgulas, pontos e hífens"

}

export default ConfigText;