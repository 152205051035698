import accountsHost from '../../../../config/api';
import { post, get } from '../../../rest';

export const updatePersonName = (

    firstName,
    lastName,
    callBack

) => {

    post(

        accountsHost + '/person/updatePersonName',
        {},
        {

            firstName: firstName,
            lastName: lastName

        },
        callBack

    )
}

const personRegister = (

    firstName,
    lastName,
    email,
    callBack

) => {

    post(

        accountsHost + '/person/registerPerson',
        {},
        {

            firstName: firstName,
            lastName: lastName,
            emailAccount: email,
            emailSubject: 'Confirme sua conta no ProdCalc',
            emailBody: 'Volte para o ProdCalc e use o código abaixo para confirmar sua conta.'

        },
        callBack

    )
}

export const personProfileByQualifiedName = (

    personQualifiedName,
    callBack

) => {

    get(

        accountsHost + '/person/personProfileByQualifiedName/' + personQualifiedName,
        {},
        callBack

    )
}

export const personSearch = (

    queryString,
    callBack

) => {

    get(

        accountsHost + '/person/personSearch' + queryString,

        {},
        callBack

    )
}

export const personDetailByAccountToken = (

    accountTokenList,
    callBack

) => {

    get(

        accountsHost + '/person/personDetailByAccountToken' +

        '?accountTokenList=' + accountTokenList,

        {},
        callBack

    )
}

export default personRegister;