import accountsHost from '../../../../config/api';
import get, { post } from '../../../rest';

const sessionDetail = (callBack) => {

    get(

        accountsHost + '/session/sessionDetail',
        {},
        callBack

    )

}

export const emailLogin = (

    token, // login token
    callBack

) => {

    post(

        accountsHost + '/session/emailLogin',
        {},
        {

            token: token

        },
        callBack

    )

}

export const sendLoginEmail = (

    accountEmail,
    callBack

) => {

    post(

        accountsHost + '/session/sendEmailLoginLink',
        {},
        {

            accountEmail: accountEmail,
            emailSubject: 'Login no ProdCalc',
            emailBody: 'Volte para o ProdCalc e use o código abaixo para entrar na sua conta.'

        },
        callBack

    )

}

export default sessionDetail;