import { useState } from 'react';

import Header from '../../../../../../components/page/Header/Component';
import Footer from '../../../../../../components/page/Footer/Component';
import Title from '../../../../../../components/page/Title/Component';

import Business from '../../../../../../components/Business/Profile/Main';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../../../lib/network';
import isValidName, { INVALID_DATA, isValidEmail } from '../../../../../../lib/validation';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import createMaterial from '../../../../../../lib/api/mrp/material/api';
import isMaterialNameValid from '../../../../../../lib/api/mrp/material/validation';
import { MATERIAL_ALREADYCREATED, MATERIAL_CREATED, MATERIAL_INVALIDNAME } from '../../../../../../lib/api/mrp/material/api';
import { buildBusinessQualifiedName, buildMaterialLinkByName } from '../../../../../../lib/util';
import { CreateMaterial } from '../../../../../../config/help';
import { PersonBusinessMaterialCreate } from '../../../../../../config/text';

const MATERIALNAME_FORM = 'MATERIALNAME_FORM';

const Component = () => {

    let { accountToken } = useParams();

    const [materialName, setMaterialName] = useState('');
    const [business, setBusiness] = useState(null);
    const [produced, setProduced] = useState('T');
    const [op, setOp] = useState(null);

    const handleMaterialNameChange = (e) => {

        setMaterialName(e.target.value);

    }

    const setProducedMaterial = (e) => {

        setProduced(e.target.value);

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (!isValidName(materialName)) {

            setOp(MATERIAL_INVALIDNAME);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        createMaterial(

            materialName,
            produced,
            accountToken,

            (status, body) => {

                if (status === 200) {

                    setOp(MATERIAL_CREATED);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === 'materialName') {

                            setOp(MATERIAL_INVALIDNAME);

                            break;

                        }

                        if (m[i] === MATERIAL_ALREADYCREATED) {

                            setOp(MATERIAL_ALREADYCREATED);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const frontPage = (props) => {

        return (

            <div>

                <Header />

                <Title title="Cadastrar Material" />

                <Business accountToken={accountToken} setBusiness={setBusiness} />

                <br />

                <div className="content">

                    <p><a href={CreateMaterial.main} target={"_blank"}>Ajuda</a></p>

                    <form onSubmit={handleSubmit}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Nome</th>
                                    <td><input type="text" name="materialName" value={materialName} onChange={handleMaterialNameChange} /></td>
                                </tr>

                                <tr>
                                    <th className="verticalHeader">Tipo</th>
                                    <td>

                                        <label><input type="radio" value="T" checked={produced === 'T'} onChange={setProducedMaterial} />Montado</label><br />
                                        <label><input type="radio" value="F" checked={produced === 'F'} onChange={setProducedMaterial} />Comprado</label><br />
                                        <label><input type="radio" value="S" checked={produced === 'S'} onChange={setProducedMaterial} />Fracionado</label>

                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </form>

                </div>

                <br />

                <Footer />

            </div>

        );

    }

    if (op === MATERIAL_INVALIDNAME) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(MATERIALNAME_FORM) }}>

                    <p style={{ color: 'black' }}>Nome inválido</p>
                    <p style={{ color: 'black' }}>{PersonBusinessMaterialCreate.invalidName}</p>

                </Alert>

            </div>
        )

    }

    if (op === MATERIAL_ALREADYCREATED) {

        return (

            <div className="content">

                <h3>Perfil de Pessoa</h3>

                <Alert severity="error" onClose={() => { setOp(MATERIALNAME_FORM) }}>

                    <p style={{ color: 'black' }}>Nome inválido</p>
                    <p style={{ color: 'black' }}>Já existe um material com esse nome nessa empresa</p>

                </Alert>

            </div>
        )

    }

    if (op === MATERIAL_CREATED) {

        return (

            <Navigate to={

                buildMaterialLinkByName(

                    buildBusinessQualifiedName(business) +
                    '/' + materialName.toLocaleLowerCase()

                )

            } />
        )

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta pessoal</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
