import { useState } from 'react';

import isConnected, { INTERNAL_ERROR, NOT_CONNECTED } from '../../../../lib/network';
import isValidName, { INVALID_DATA, isValidBusinessName, isValidEmail } from '../../../../lib/validation';
import personRegister from '../../../../lib/api/accounts/person/api';
import { resendConfirmEmail } from '../../../../lib/api/accounts/account/api';

import { Alert } from '@mui/material';
import { CIRCULAR_SPINNER } from '../../../../lib/rest';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import businessRegister from '../../../../lib/api/accounts/business/api';
import { ErrorText } from '../../../../config/text';

const ACCOUNT_CREATED = 'ACCOUNT_CREATED';
const EMAIL_ALREADYREGISTERED = 'EMAIL_ALREADYREGISTERED';
const RESENDEMAIL_FORM = 'RESEND_FORM';
const INVALID_RESENDEMAIL = 'INVALID_RESENDEMAIL';
const EMAIL_NOTREGISTERED = 'EMAIL_NOTREGISTERED';
const EMAIL_ALREADYCONFIRMED = 'EMAIL_ALREADYCONFIRMED';
const RESENDEMAIL_MSG = 'RESENDEMAIL_MSG';

const Component = () => {

    const [businessName, setBusinessName] = useState('');
    const [accountEmail, setAccountEmail] = useState('');
    const [op, setOp] = useState(null);

    const handleBusinessNameChange = (e) => {

        setBusinessName(e.target.value.trim());

    }

    const handleAccountEmailChange = (e) => {

        setAccountEmail(e.target.value.trim());

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isValidBusinessName(businessName)
                && isValidEmail(accountEmail)

            )

        ) {

            setOp(INVALID_DATA);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        businessRegister(

            businessName,
            accountEmail,

            (status, body) => {

                if (status === 200) {

                    setOp(ACCOUNT_CREATED);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === EMAIL_ALREADYREGISTERED) {

                            setOp(EMAIL_ALREADYREGISTERED);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const handleSubmitResendEmail = async (e) => {

        e.preventDefault();

        if (!isConnected()) {

            setOp(NOT_CONNECTED);

            return;

        }

        if (

            !(

                isValidEmail(accountEmail)

            )

        ) {

            setOp(INVALID_RESENDEMAIL);

            return;

        }

        setOp(CIRCULAR_SPINNER);

        resendConfirmEmail(

            accountEmail,

            (status, body) => {

                if (status === 200) {

                    setOp(RESENDEMAIL_MSG);

                } else if (status === 400) {

                    let m = body.messages;

                    for (let i = 0; i < m.length; i++) {

                        if (m[i] === EMAIL_ALREADYCONFIRMED) {

                            setOp(EMAIL_ALREADYCONFIRMED);

                            break;

                        }

                        if (m[i] === EMAIL_NOTREGISTERED) {

                            setOp(EMAIL_NOTREGISTERED);

                            break;

                        }

                    }

                } else {

                    setOp(INTERNAL_ERROR);

                }

            }

        );

    }

    const frontPage = (props) => {

        return (

            <div className="content">

                <h3>Crie sua Conta Empresarial</h3>

                <p>Coloque sua empresa no ProdCalc e consiga mais negócios!</p>

                <p>Informe os dados da sua empresa e siga as instruções do email que será enviado</p>

                <form onSubmit={handleSubmit}>

                    <table>

                        <tbody>

                            <tr>
                                <th className="verticalHeader">Nome</th>
                                <td><input type="text" value={businessName} onChange={handleBusinessNameChange} /></td>
                            </tr>

                            <tr>
                                <th className="verticalHeader">Email</th>
                                <td><input type="email" value={accountEmail} onChange={handleAccountEmailChange} /></td>
                            </tr>

                            <tr>
                                <td colSpan="2" style={{ textAlign: "right" }}>

                                    <input type="submit" value="Enviar" />

                                </td>
                            </tr>

                        </tbody>

                    </table>

                </form>

                {/*  <p>

                    Clique <button type="button" className="buttonLink" onClick={() => setOp(RESENDEMAIL_FORM)}>aqui</button> para reenviar o email de confirmação.

                </p> */}

            </div>

        );

    }

    const resendEmailForm = (props) => {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <p>Reenvio de email de confirmação</p>

                <div>

                    <p>Informe o email que você cadastrou no ProdCalc</p>

                    <form onSubmit={handleSubmitResendEmail}>

                        <table>

                            <tbody>

                                <tr>
                                    <th className="verticalHeader">Email</th>
                                    <td><input type="email" name="accountEmail" value={accountEmail} onChange={handleAccountEmailChange} /></td>
                                </tr>

                                <tr>
                                    <td colSpan="2" style={{ textAlign: "right" }}>

                                        <input type="submit" value="Enviar" />

                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </form>

                    <p>

                        <button type="button" className="buttonLink" onClick={() => setOp(null)}>Cancelar</button>

                    </p>

                </div>

            </div>

        );

    }

    if (op === INVALID_DATA) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>{ErrorText.name}</p>
                    <p style={{ color: 'black' }}>Confira o email</p>

                </Alert>

            </div>
        )

    }

    if (op === ACCOUNT_CREATED) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="success" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Conta criada</p>
                    <p style={{ color: 'black' }}>Um email de confirmação foi enviado para você</p>

                </Alert>

            </div >
        )

    }

    if (op === EMAIL_ALREADYREGISTERED) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Email já cadastrado</p>

                </Alert>

            </div >
        )

    }

    if (op === RESENDEMAIL_FORM) {

        return resendEmailForm();

    }

    if (op === INVALID_RESENDEMAIL) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Dados inválidos</p>
                    <p style={{ color: 'black' }}>Email</p>

                </Alert>

            </div>
        )

    }

    if (op === RESENDEMAIL_MSG) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="success" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email enviado</p>
                    <p style={{ color: 'black' }}>Use o link no email para confirmar sua conta.</p>

                </Alert>

            </div>
        )

    }

    if (op === EMAIL_ALREADYCONFIRMED) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email já confirmado</p>

                </Alert>

            </div>
        )

    }

    if (op === EMAIL_NOTREGISTERED) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(RESENDEMAIL_FORM) }}>

                    <p style={{ color: 'black' }}>Email não cadastrado</p>

                </Alert>

            </div>
        )

    }

    if (op === CIRCULAR_SPINNER) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Box sx={{ display: 'flex', justifyContent: 'center' }} >

                    <CircularProgress color='warning' />

                </Box>

            </div>
        );

    }

    if (op === INTERNAL_ERROR) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>

                    <p style={{ color: 'black' }}>Sistema fora do ar</p>
                    <p style={{ color: 'black' }}>Tente novamente em alguns minutos</p>

                </Alert>

            </div>
        )

    }

    if (op === NOT_CONNECTED) {

        return (

            <div className="content">

                <h3>Conta empresarial</h3>

                <Alert severity="error" onClose={() => { setOp(null) }}>Sem conexão</Alert>

            </div>
        );

    }

    return frontPage();

}

export default Component;
